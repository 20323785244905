var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import * as dayjs from 'dayjs';
import { floor, getRanges, isEmpty } from 'renderer/utils';
import { AlertComponent, AntdForm, DrawerFooter, FluidRow, Marginer, TextComponent, DrawerComponent, KRWFomatToString, StyledFormItem, NumberFormatInput, XScrollableTable, checkPermissionForOnClick, AntButton } from 'renderer/components';
import { SalesGoalModel } from 'renderer/models';
import Row from 'antd/lib/row';
import Radio from 'antd/lib/radio';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import { PaymentHistoryQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import Empty from 'antd/lib/empty';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { useWindowSize } from 'renderer/utils';
import { Col } from 'antd/lib/grid';
import { FormTitle } from './IncentiveTab';
var SALES_GOALS_TABLE_ID = 'TABLE_ID_SALES_GOALS_IN_PC';
export var SalesGoalTab = function (props) {
    var _a, _b, _c;
    var today = dayjs();
    var managers = props.workingManagers;
    var _d = React.useState({
        month: today.toDate()
    }), query = _d[0], setQuery = _d[1];
    var month = (query === null || query === void 0 ? void 0 : query.month)
        ? dayjs(query.month).startOf('month').add(2, 'day').toDate()
        : (query === null || query === void 0 ? void 0 : query.startedAt)
            ? dayjs(query.startedAt).startOf('month').add(9, 'hour').toDate()
            : dayjs().startOf('month').add(2, 'day').toDate();
    var salesGoal = PaymentHistoryQuery.useSalesGoal({ month: month, storeId: props.store.id }, {
        retryOnMount: false,
        retry: 0,
        enabled: query && !!((_a = props.store) === null || _a === void 0 ? void 0 : _a.id)
    });
    var salesStatistics = PaymentHistoryQuery.useSalesStatisticsGroupByManager(__assign(__assign({}, query), { storeId: props.store.id }), {
        retryOnMount: false,
        enabled: query && !isEmpty(managers) && !!((_b = props.store) === null || _b === void 0 ? void 0 : _b.id)
    });
    var handleChange = function (query) {
        if (query.duration) {
            var _a = query.duration, startedAt = _a[0], endedAt = _a[1];
            setQuery({
                startedAt: startedAt.startOf('day').toDate(),
                endedAt: endedAt.endOf('day').toDate()
            });
        }
        else {
            setQuery({
                month: query.month.toDate()
            });
        }
    };
    var copyGoal = PaymentHistoryQuery.useCopySalesGoal();
    var handleUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!checkPermissionForOnClick(props.hasUpdatePermission, '목표매출 설정 권한이 없습니다.')) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    if (!month) return [3 /*break*/, 3];
                    return [4 /*yield*/, copyGoal.mutateAsync({
                            month: month,
                            storeId: props.store.id
                        })];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    notification.success({ message: '가장 최근 목표 복사가 완료되었습니다.' });
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _b.sent();
                    if ((_a = error_1.message) === null || _a === void 0 ? void 0 : _a.includes('설정된 목표를 찾을 수 없습니다')) {
                        console.log('err', error_1);
                        props.onClickDrawerOpen({ month: dayjs(month) });
                    }
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getDataSource = function () {
        var dataSource = [];
        if (!salesStatistics.data || !salesGoal.data || !managers) {
            return dataSource;
        }
        var targetManagers = managers.filter(function (manager) { return (props.isAll ? true : manager.id === props.loginManager.id); });
        var salesStats = {};
        targetManagers.map(function (manager) {
            var data = salesStatistics.data[manager.id];
            if (!data) {
                salesStats[manager.id] = {
                    product: {
                        total: 0
                    },
                    service: {
                        total: 0
                    },
                    ticket: {
                        total: 0
                    }
                };
            }
            else {
                salesStats[manager.id] = data;
            }
        });
        var days = query.month ? undefined : dayjs(query.endedAt).diff(query.startedAt, 'day') + 1;
        var daysInMonth = query.month ? undefined : dayjs(query.startedAt).daysInMonth();
        var withSales = salesGoal.data.addSalesWithGoal(salesStats, days, daysInMonth);
        if (!withSales) {
            return [];
        }
        targetManagers.map(function (manager) {
            var managerId = manager.id;
            var targetKeys = ['ticket', 'service', 'product', 'total'];
            var salesPerManager = withSales[managerId];
            var label = {
                ticket: '선불액∙정기권 판매',
                service: '상품-시술 판매',
                product: '상품-제품 판매',
                total: '총 합계'
            };
            targetKeys.map(function (targetKey) {
                var goal = salesPerManager.service.goal + salesPerManager.product.goal + salesPerManager.ticket.goal;
                var total = salesPerManager.service.total + salesPerManager.product.total + salesPerManager.ticket.total;
                var values = targetKey === 'total'
                    ? {
                        goal: goal,
                        total: total,
                        completeRate: goal === 0 ? 100 : (total / goal) * 100
                    }
                    : {
                        goal: salesPerManager[targetKey].goal,
                        total: salesPerManager[targetKey].total,
                        completeRate: salesPerManager[targetKey].total === 0
                            ? 0
                            : salesPerManager[targetKey].goal !== 0
                                ? Math.round((salesPerManager[targetKey].total / salesPerManager[targetKey].goal) * 100)
                                : 0
                    };
                var row = __assign({ managerName: manager === null || manager === void 0 ? void 0 : manager.displayName, typeString: label[targetKey] }, values);
                row.typeString = label[targetKey];
                dataSource.push(row);
            });
        });
        return dataSource;
    };
    return (React.createElement("div", null,
        React.createElement(Row, { justify: "space-between", align: "middle" },
            React.createElement(Searcher, { onChange: handleChange }),
            props.isPC && (React.createElement(AntButton, { type: "primary", children: "\uC9C1\uC6D0\uBCC4 \uBAA9\uD45C\uB9E4\uCD9C \uC124\uC815", onClick: function () {
                    if (checkPermissionForOnClick(props.hasUpdatePermission, '목표매출 설정 권한이 없습니다.')) {
                        props.onClickDrawerOpen({ month: dayjs(month) });
                    }
                } }))),
        React.createElement(Marginer, { height: 20 }),
        React.createElement(Row, null, !((_c = salesGoal.data) === null || _c === void 0 ? void 0 : _c.id) ? (React.createElement(FluidRow, { justify: "center" },
            React.createElement(Marginer, { height: 20 }),
            React.createElement(Empty, { description: React.createElement("div", null,
                    React.createElement(Row, null,
                        React.createElement(TextComponent, { marginTop: 10, marginBottom: 10, children: "\uD574\uB2F9\uAE30\uAC04\uC5D0 \uBAA9\uD45C\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4. \uBAA9\uD45C\uB97C \uC124\uC815\uD574\uC8FC\uC138\uC694 :)" })),
                    React.createElement(Row, { justify: "center" },
                        React.createElement(AntButton, { type: "primary", children: "\uCD5C\uADFC \uBAA9\uD45C \uBCF5\uC0AC\uD558\uAE30", onClick: handleUpdate }))) }),
            React.createElement(Marginer, { height: 20 }))) : (React.createElement(XScrollableTable, { id: SALES_GOALS_TABLE_ID, pagination: false, resizable: {
                additionalColumnWidth: 0
            }, dataSource: getDataSource(), columns: [
                {
                    title: '직원명',
                    key: 'managerName',
                    dataIndex: 'managerName',
                    fixed: 'left',
                    ellipsis: false,
                    render: function (value, _, index) {
                        var obj = {
                            children: value || '삭제된 직원',
                            props: {
                                rowSpan: 1
                            }
                        };
                        if (index % 4 === 0) {
                            obj.props.rowSpan = 4;
                        }
                        else {
                            obj.props.rowSpan = 0;
                        }
                        return obj;
                    }
                },
                {
                    title: '분류',
                    key: 'typeString',
                    dataIndex: 'typeString'
                },
                {
                    title: '목표',
                    key: 'goal',
                    dataIndex: 'goal',
                    render: function (value) { return KRWFomatToString({ value: value }); }
                },
                {
                    title: '총영업액',
                    key: 'total',
                    dataIndex: 'total',
                    render: function (value) { return KRWFomatToString({ value: value }); }
                },
                {
                    title: '달성률',
                    key: 'completeRate',
                    dataIndex: 'completeRate',
                    render: function (value) { return (Number.isNaN(value) ? "0%" : "".concat(floor(value), "%")); }
                }
            ] }))),
        React.createElement(Marginer, { height: 200 }),
        React.createElement(AlertComponent, { type: "info", showIcon: true, message: "\uC548\uB0B4", description: React.createElement("div", null,
                React.createElement(TextComponent, { color: COLORS.gray2, fontWeight: 400, children: "- \uC77C\uD560 \uBAA9\uD45C\uB9E4\uCD9C\uC561\uC740 \uC6D4\uAC04 \uBAA9\uD45C\uB9E4\uCD9C\uC561\uC744 \uC77C\uB2E8\uC704\uB85C \uB098\uB204\uC5B4 (\uC6D4\uAC04 \uBAA9\uD45C\uB9E4\uCD9C\uC561/\uC6D4\uAC04 \uC77C\uC218) \uACC4\uC0B0\uB429\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { color: COLORS.gray2, fontWeight: 400, children: "- \uAE30\uAC04\uBCC4 \uB9E4\uCD9C\uC561\uC740 \uC870\uD68C \uAE30\uAC04 \uB3D9\uC548 \uC77C\uD560 \uBAA9\uD45C\uB9E4\uCD9C\uC561\uC758 \uD569\uACC4\uB85C \uACC4\uC0B0\uB429\uB2C8\uB2E4. (\uC6D4\uAC04 \uBAA9\uD45C\uB9E4\uCD9C\uC561/\uC6D4\uAC04 \uC77C\uC218*\uC870\uD68C\uAE30\uAC04)" }),
                React.createElement(TextComponent, { color: COLORS.gray2, fontWeight: 400, children: "- \uACC4\uC0B0\uB41C \uB9E4\uCD9C\uC561\uC740 1\uC6D0\uB2E8\uC704\uAE4C\uC9C0 \uBCF4\uC5EC\uC9C0\uBA70, \uC18C\uC218\uC810\uB2E8\uC704 \uAE08\uC561\uC740 \uC808\uC0AD\uB429\uB2C8\uB2E4." })) })));
};
export var RegisterGoals = function (props) {
    var _a, _b;
    var storeId = props.store.id;
    var open = !!props.drawer;
    var managers = props.managers;
    var _c = React.useState(dayjs((_a = props === null || props === void 0 ? void 0 : props.drawer) === null || _a === void 0 ? void 0 : _a.month)), month = _c[0], setMonth = _c[1];
    var _d = PaymentHistoryQuery.useSalesGoal({ month: month === null || month === void 0 ? void 0 : month.toDate(), storeId: storeId }, {
        retryOnMount: false,
        enabled: !!(storeId && month)
    }), data = _d.data, isError = _d.isError;
    var _e = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _e[1];
    var getInitialValues = function () {
        var initalData = {};
        managers.map(function (manager) {
            initalData[manager.id] = {
                product: 0,
                ticket: 0,
                service: 0
            };
        });
        return {
            policy: SalesGoalModel.SALES_GOAL_POLICY.ALL,
            month: month,
            goals: __assign({ all: {
                    product: 0,
                    ticket: 0,
                    service: 0
                } }, initalData)
        };
    };
    React.useEffect(function () {
        if (data) {
            form.setFieldsValue(__assign(__assign({}, data), { id: data.id || null, month: dayjs("".concat(data.month, "10")) }));
        }
        else {
            form.setFieldsValue(__assign(__assign({}, getInitialValues()), { id: null }));
        }
        forceUpdate();
    }, [data, isError]);
    React.useEffect(function () {
        var _a, _b;
        if ((_a = props.drawer) === null || _a === void 0 ? void 0 : _a.month) {
            setMonth(dayjs((_b = props.drawer) === null || _b === void 0 ? void 0 : _b.month));
        }
        forceUpdate();
    }, [(_b = props.drawer) === null || _b === void 0 ? void 0 : _b.month]);
    var form = Form.useForm()[0];
    var renderFields = function (key) {
        var _a;
        var title = key === SalesGoalModel.SALES_GOAL_POLICY.ALL
            ? '전체직원'
            : (_a = managers.find(function (manager) { return manager.id === Number(key); })) === null || _a === void 0 ? void 0 : _a.displayName;
        return (React.createElement(SettingCardWithTitle, { key: key, title: title, children: React.createElement("div", null,
                React.createElement(StyledFormItem, { labelCol: { span: 24 }, label: "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uD310\uB9E4", name: ['goals', key, 'ticket'], rules: [{ type: 'number', required: true }] },
                    React.createElement(NumberFormatInput, { placeholder: "\uAE08\uC561\uC785\uB825", suffix: "\uC6D0", style: { width: '100%' } })),
                React.createElement(StyledFormItem, { labelCol: { span: 24 }, rules: [{ type: 'number', required: true }], label: "\uC0C1\uD488-\uC2DC\uC220 \uD310\uB9E4", name: ['goals', key, 'service'] },
                    React.createElement(NumberFormatInput, { placeholder: "\uAE08\uC561\uC785\uB825", suffix: "\uC6D0", style: { width: '100%' } })),
                React.createElement(StyledFormItem, { labelCol: { span: 24 }, rules: [{ type: 'number', required: true }], label: "\uC0C1\uD488-\uC81C\uD488 \uD310\uB9E4", name: ['goals', key, 'product'] },
                    React.createElement(NumberFormatInput, { placeholder: "\uAE08\uC561\uC785\uB825", suffix: "\uC6D0", style: { width: '100%' } }))) }));
    };
    var isUpdated = form.getFieldValue('id');
    var fields = [
        { element: React.createElement(FormTitle, { title: "\uBAA9\uD45C\uB144\uC6D4", top: 24 }) },
        {
            type: 'number',
            itemProps: {
                name: 'id',
                hidden: true
            },
            inputProps: {}
        },
        {
            type: 'monthpicker',
            itemProps: {
                name: 'month',
                rules: [{ required: true }]
            },
            inputProps: {
                mode: 'month',
                placeholder: '0000.00',
                format: 'YYYY.MM',
                onChange: function (date) {
                    setMonth(date);
                    form.setFieldsValue({
                        month: date
                    });
                    forceUpdate();
                }
            }
        },
        { element: React.createElement(FormTitle, { title: "\uBAA9\uD45C\uB9E4\uCD9C \uC124\uC815" }) },
        {
            type: 'radio',
            itemProps: {
                name: 'policy',
                rules: [{ type: 'string', required: true }]
            },
            inputProps: {
                onChange: function (event) {
                    if (data) {
                        form.setFieldsValue(__assign(__assign({}, data), { policy: event.target.value, month: dayjs("".concat(data.month, "10")) }));
                    }
                    else {
                        form.setFieldsValue({ policy: event.target.value, goals: __assign({}, getInitialValues().goals) });
                    }
                    forceUpdate();
                },
                options: [
                    { label: '일괄 설정', value: SalesGoalModel.SALES_GOAL_POLICY.ALL },
                    { label: '직원별 설정', value: SalesGoalModel.SALES_GOAL_POLICY.EACH }
                ]
            }
        },
        {
            element: (React.createElement(GoalGrayBox, null, form.getFieldValue(['policy']) === SalesGoalModel.SALES_GOAL_POLICY.ALL
                ? renderFields(SalesGoalModel.SALES_GOAL_POLICY.ALL)
                : managers === null || managers === void 0 ? void 0 : managers.map(function (manager) { return renderFields("".concat(manager.id)); })))
        }
    ].filter(Boolean);
    var create = PaymentHistoryQuery.useCreateSalesGoal();
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = __assign(__assign({}, values), { storeId: storeId, id: null });
                    return [4 /*yield*/, create.mutateAsync(payload)];
                case 1:
                    _a.sent();
                    notification.success({ message: '목표 설정이 완료되었습니다.' });
                    props.onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DrawerComponent, { open: open, onClose: props.onClose, title: isUpdated ? '직원별 목표매출 수정' : '직원별 목표매출 설정', children: React.createElement("div", null,
            React.createElement(AntdForm, { fields: fields, formProps: { initialValues: getInitialValues(), form: form, onFinish: handleSubmit }, onSubmit: handleSubmit })), footer: React.createElement(DrawerFooter, { confirmText: isUpdated ? '수정' : '저장', onConfirm: form.submit, onCancel: props.onClose }) }));
};
export var Searcher = function (props) {
    var today = dayjs();
    var durationDefault = [today.subtract(1, 'month').startOf('day'), today.endOf('day')];
    var _a = React.useState('monthly'), type = _a[0], setType = _a[1];
    var isPC = useWindowSize().isPC;
    var handleTypeChange = function (e) {
        setType(e.target.value);
        var type = e.target.value;
        var query = type === 'monthly'
            ? {
                type: type,
                month: today
            }
            : {
                type: type,
                duration: durationDefault
            };
        props.onChange(query);
    };
    var handleMonthChange = function (month) {
        props.onChange({
            type: 'month',
            month: month
        });
    };
    var handleDurationChange = function (duration) {
        props.onChange({
            type: 'specifc',
            duration: duration
        });
    };
    return (React.createElement(Row, { align: "middle", style: { width: '600px' } },
        React.createElement(Col, { span: isPC ? 10 : 24 },
            React.createElement(Radio.Group, { value: type, onChange: handleTypeChange },
                React.createElement(Radio, { value: "monthly" }, "\uC6D4\uBCC4 \uC870\uD68C"),
                React.createElement(Radio, { value: "specific" }, "\uAE30\uAC04\uBCC4 \uC870\uD68C"))),
        React.createElement(Col, { span: isPC ? 14 : 24 },
            React.createElement("div", { style: !isPC ? { marginTop: '10px' } : {} }, type === 'monthly' ? (React.createElement(DatePicker.MonthPicker, { onChange: handleMonthChange, defaultValue: today, allowClear: false })) : (React.createElement(DatePicker.RangePicker, { onChange: handleDurationChange, presets: getRanges(today), allowClear: false, allowEmpty: [false, false], defaultValue: durationDefault }))))));
};
export var SettingCardWithTitle = function (props) {
    return (React.createElement(StyledSettingCardWithTitle, { hidden: props.hidden },
        React.createElement("div", null,
            React.createElement(TextComponent, { color: COLORS.gray1, type: "subtitle2" }, props.title)),
        React.createElement("div", null,
            React.createElement(TextComponent, { color: COLORS.gray1, type: "subtitle2" }, props.children))));
};
var StyledSettingCardWithTitle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: solid 1px var(--gray-6);\n  background-color: ", ";\n  margin-bottom: 10px;\n  &:last-child : {\n    margin-bottom: 0px;\n  }\n  & > div:first-child {\n    padding: 16px;\n    border-bottom: solid 1px var(--gray-6);\n  }\n  & > div:last-child {\n    padding: 16px;\n  }\n"], ["\n  border: solid 1px var(--gray-6);\n  background-color: ", ";\n  margin-bottom: 10px;\n  &:last-child : {\n    margin-bottom: 0px;\n  }\n  & > div:first-child {\n    padding: 16px;\n    border-bottom: solid 1px var(--gray-6);\n  }\n  & > div:last-child {\n    padding: 16px;\n  }\n"])), COLORS.white);
export var GoalGrayBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 12px 14px;\n  border-radius: 2px;\n  width: 100%;\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n  visibility: ", ";\n"], ["\n  padding: 12px 14px;\n  border-radius: 2px;\n  width: 100%;\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n  visibility: ", ";\n"])), function (props) { return (props.hidden ? 'hidden' : 'visible'); });
var templateObject_1, templateObject_2;
