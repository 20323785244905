var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { BodyTemplate, TableComponent, useEntireLoader } from 'renderer/components';
import Image from 'antd/lib/image';
import { BANKS } from 'renderer/constants/index';
import { RestClient } from 'renderer/utils';
import { formmatPhoneNumber } from './migrationUtils';
import Select from 'antd/lib/select';
import { useMutation, useQuery } from 'react-query';
import { QUERY_CACHE } from 'renderer/queries';
import dayjs from 'dayjs';
export var AdminReviewPrev = function (props) {
    var loader = useEntireLoader();
    var query = useQuery('admin/reviews', function () {
        return RestClient.getInstance()
            .get("/admin/review-connector/reviews")
            .then(function (res) {
            return res.data;
        });
    });
    var mutation = useMutation(function (payload) {
        return RestClient.getInstance()
            .put("/admin/review-connector/reviews", payload)
            .then(function (result) { return result.data; });
    }, {
        onSuccess: function (data) {
            var _a;
            QUERY_CACHE.setQueryData('admin/reviews', (_a = query.data) === null || _a === void 0 ? void 0 : _a.map(function (review) {
                return review.id === data.id ? data : review;
            }));
        }
    });
    var columns = [
        {
            title: '상점명',
            dataIndex: 'meta.storeName',
            key: 'meta.storeName',
            render: function (_, row) {
                var _a;
                return "".concat((_a = row === null || row === void 0 ? void 0 : row.meta) === null || _a === void 0 ? void 0 : _a.storeName);
            }
        },
        {
            title: '매출아이디',
            dataIndex: 'meta.salesHistoryId',
            key: 'meta.salesHistoryId',
            render: function (_, row) {
                var _a;
                return "".concat((_a = row === null || row === void 0 ? void 0 : row.meta) === null || _a === void 0 ? void 0 : _a.salesHistoryId);
            }
        },
        {
            title: '고객',
            dataIndex: 'customer',
            key: 'customer',
            render: function (_, row) {
                var _a, _b, _c;
                return ((_a = row === null || row === void 0 ? void 0 : row.customer) === null || _a === void 0 ? void 0 : _a.name) ? "".concat((_b = row === null || row === void 0 ? void 0 : row.customer) === null || _b === void 0 ? void 0 : _b.name, "(").concat(formmatPhoneNumber((_c = row === null || row === void 0 ? void 0 : row.customer) === null || _c === void 0 ? void 0 : _c.phone), ")") : '-';
            }
        },
        {
            title: '계좌',
            dataIndex: 'bank',
            key: 'bank',
            render: function (_, row) {
                var _a, _b, _c;
                var bankName = BANKS[(_a = row.customer) === null || _a === void 0 ? void 0 : _a.bank];
                return ((_b = row === null || row === void 0 ? void 0 : row.customer) === null || _b === void 0 ? void 0 : _b.name) ? "".concat((_c = row === null || row === void 0 ? void 0 : row.customer) === null || _c === void 0 ? void 0 : _c.accountNumber, "(").concat(bankName, ")") : '-';
            }
        },
        {
            title: '스샷',
            dataIndex: 'content.screenshot',
            key: 'content.screenshot',
            width: 250,
            render: function (_, row) {
                var _a, _b;
                // return (
                //   <img src="https://review-connector.s3.ap-northeast-2.amazonaws.com/screenshot-production/iOA7F3vdkSaa7bk9nBX-q" />
                // );
                return ((_a = row.content) === null || _a === void 0 ? void 0 : _a.screenshot) ? React.createElement(Image, { width: 50, src: (_b = row.content) === null || _b === void 0 ? void 0 : _b.screenshot }) : '-';
            }
        },
        {
            title: '요청일(리뷰작성일)',
            dataIndex: 'meta.requestedAt',
            key: 'meta.requestedAt',
            width: 250,
            render: function (_, row) {
                var _a, _b, _c, _d;
                // return (
                //   <img src="https://review-connector.s3.ap-northeast-2.amazonaws.com/screenshot-production/iOA7F3vdkSaa7bk9nBX-q" />
                // );
                return "".concat(((_a = row.meta) === null || _a === void 0 ? void 0 : _a.requestedAt) ? dayjs((_b = row.meta) === null || _b === void 0 ? void 0 : _b.requestedAt).format('YYYY-MM-DD hh:mm:ss') : '-', "(").concat(((_c = row.meta) === null || _c === void 0 ? void 0 : _c.completedAt) ? dayjs((_d = row.meta) === null || _d === void 0 ? void 0 : _d.completedAt).format('YYYY-MM-DD hh:mm:ss') : '-', ")");
            }
        },
        {
            title: '상태',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: function (_, row) {
                return (React.createElement(Select, { id: "demo-simple-select-helper", value: row.status, options: [
                        { label: '요청', value: 'requested' },
                        { label: '검수중', value: 'pending' },
                        { label: '보상완료', value: 'completed' },
                        { label: '거절', value: 'rejected' },
                        { label: '만료', value: 'expired' },
                        { label: '과금완료', value: 'paid' }
                    ], onChange: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                        var err_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    loader.show();
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, 4, 5]);
                                    return [4 /*yield*/, mutation.mutateAsync(__assign(__assign({}, row), { status: value }))];
                                case 2:
                                    _a.sent();
                                    return [3 /*break*/, 5];
                                case 3:
                                    err_1 = _a.sent();
                                    return [3 /*break*/, 5];
                                case 4:
                                    loader.hide();
                                    return [7 /*endfinally*/];
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); } }));
            }
        }
    ];
    return (React.createElement(BodyTemplate, { title: "\uB9AC\uBDF0\uAD00\uB9AC(\uC774\uC804\uBC84\uC83C)", content: React.createElement("div", null,
            React.createElement(TableComponent, { columns: columns, rowKey: function (row) { return row.id; }, scroll: { y: 800 }, dataSource: (query === null || query === void 0 ? void 0 : query.data) || [], pagination: false })) }));
};
