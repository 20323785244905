var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BodyTemplate, Marginer, TextComponent, useEntireLoader, EntireBackdrop } from 'renderer/components';
import { loadXLSXCDN, isEmpty, chunkArray, mapSeries, sleep, ceil, RestClient } from 'renderer/utils';
import Button from 'antd/lib/button';
import { HookFormInputs } from 'renderer/components/Forms/Inputs';
import { useForm, useFormState } from 'react-hook-form';
import Row from 'antd/lib/row';
import { Col } from 'antd/lib/grid';
import Divider from 'antd/lib/divider';
import styled from 'styled-components';
import { AdminQuery } from 'renderer/queries';
import modal from 'antd/lib/modal';
import { convertAhaToPlussharp, convertCustomerSheetToCustomerData, convertTemplateSheetToJSON, getSalesDataFromFile, mergeCustomerDataWithSalesFromHandSOS, preprocessAhaCustomer, convertBeautysarangToThinkofyou, mergeCustomerDataWithSalesFromYonggam } from './migrationUtils';
import Progress from 'antd/lib/progress';
import { COLORS } from 'renderer/styles';
import Card from 'antd/lib/card';
export var MigrationHome = function (props) {
    return React.createElement(BodyTemplate, { title: "\uB9C8\uC774\uADF8\uB808\uC774\uC158", content: React.createElement(MigrationComponent, __assign({}, props)) });
};
export var MigrationComponent = function (props) {
    React.useEffect(function () {
        loadXLSXCDN();
    }, []);
    var _a = React.useState(), status = _a[0], setStatus = _a[1];
    var entireLoader = useEntireLoader();
    var updateCustomerMeta = AdminQuery.useUpdateCustomerMeta();
    var handleMetaUpdate = function (storeId) { return __awaiter(void 0, void 0, void 0, function () {
        var checkPayload, result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    entireLoader.show();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    checkPayload = { storeId: storeId };
                    return [4 /*yield*/, updateCustomerMeta.mutateAsync(checkPayload)];
                case 2:
                    result = _a.sent();
                    modal.info({
                        title: '메타 데이터 최신화 결과',
                        content: "".concat(result.result, "\uBA85 \uCD5C\uC2E0\uD654 \uC131\uACF5")
                    });
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    modal.error({
                        title: 'error',
                        content: error_1.message
                    });
                    return [3 /*break*/, 5];
                case 4:
                    entireLoader.hide();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, true];
            }
        });
    }); };
    var handleValidate = function (storeId) { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    entireLoader.show();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, AdminQuery.validateStoreSales(storeId)];
                case 2:
                    result = _a.sent();
                    modal.info({
                        title: '매출 유효성 검증',
                        content: JSON.stringify(result)
                    });
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    modal.error({
                        title: 'error',
                        content: error_2.message
                    });
                    return [3 /*break*/, 5];
                case 4:
                    entireLoader.hide();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, true];
            }
        });
    }); };
    return (React.createElement("div", null,
        React.createElement(EntireBackdrop, { open: !!status },
            React.createElement("div", null,
                React.createElement(Progress, { type: "circle", percent: (status === null || status === void 0 ? void 0 : status.percent) || 0 }),
                React.createElement(Row, { justify: "center" },
                    React.createElement(TextComponent, { color: COLORS.gray6, marginTop: 10, type: "headline3", children: (status === null || status === void 0 ? void 0 : status.message) || '' })))),
        React.createElement(TextComponent, { type: "headline2", children: "- \uD305\uCEE4\uBDF0 \uC591\uC2DD \uC5C5\uB85C\uB4DC", marginBottom: 10 }),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(CustomerMigration, { onChangeStatus: function (newStatus) { return setStatus(newStatus); } }))),
        React.createElement(Divider, null),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uACE0\uAC1D \uBA54\uD0C0 \uB370\uC774\uD130 \uCD5C\uC2E0\uD654", marginBottom: 10 }),
                React.createElement(CustomerMetaUpdater, { onSubmit: handleMetaUpdate })),
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uB9E4\uCD9C \uC720\uD6A8\uC131 \uAC80\uC99D", marginBottom: 10 }),
                React.createElement(CustomerMetaUpdater, { onSubmit: handleValidate }))),
        React.createElement(Divider, null),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uB9E4\uCD9C: \uC544\uD558\uC18C\uD504\uD2B8 -> \uD305\uCEE4\uBDF0", marginBottom: 10 }),
                React.createElement(AhaConverter, null)),
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uC544\uD558\uC18C\uD504\uD2B8 \uC911\uBCF5 \uD3F0\uBC88\uD638 \uC81C\uAC70", marginBottom: 10 }),
                React.createElement(AhaPreprocessor, null))),
        React.createElement(Divider, null),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uD578\uB4DCSOS", marginBottom: 10 }),
                React.createElement(HandSOS, null)),
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uBDF0\uD2F0\uC0AC\uB791\uB137", marginBottom: 10 }),
                React.createElement(Beautysarang, null))),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(TextComponent, { type: "headline2", children: "- \uC6A9\uAC10\uD55C\uBDF0\uD2F0", marginBottom: 10 }),
                React.createElement(Yonggam, null)),
            React.createElement(Col, { span: 12 })),
        React.createElement(Divider, null)));
};
export var AhaConverter = function () {
    var _a = React.useState(), files = _a[0], setFiles = _a[1];
    return (React.createElement(StyledCard, null,
        React.createElement("input", { type: "file", name: "aha", onChange: function (ev) {
                var _a, _b;
                var file = ((_a = ev.target) === null || _a === void 0 ? void 0 : _a.files) && ((_b = ev.target) === null || _b === void 0 ? void 0 : _b.files[0]);
                setFiles(__assign(__assign({}, files), { aha: file }));
            } }),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(FluidButton, { onClick: function () { return setFiles(null); }, children: '취소' })),
            React.createElement(Col, { span: 12 },
                React.createElement(FluidButton, { type: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var error_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, convertAhaToPlussharp(files.aha)];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_3 = _a.sent();
                                    modal.error({ content: error_3.message });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, children: '변환', disabled: !(files === null || files === void 0 ? void 0 : files.aha) })))));
};
export var HandSOS = function () {
    var form = useForm({
        mode: 'onChange',
        defaultValues: {
            origin: 'http://localhost:3000',
            id: '36227288',
            companyCode: 'm12373001',
            password: 'k36220388'
        }
    });
    var resetForm = function () {
        form.reset({
            origin: 'http://localhost:3000',
            id: '36227288',
            companyCode: 'm12373001',
            password: 'k36220388'
        });
    };
    var state = useFormState({ control: form.control });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var origin, rest, error_4, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    origin = values.origin, rest = __rest(values, ["origin"]);
                    return [4 /*yield*/, mergeCustomerDataWithSalesFromHandSOS(__assign(__assign({}, rest), { customerFile: rest.customerFile[0] }), origin)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    console.log(error_4);
                    modal.error({ content: error_4.message });
                    return [3 /*break*/, 4];
                case 4:
                    resetForm();
                    return [3 /*break*/, 6];
                case 5:
                    error_5 = _a.sent();
                    modal.error({
                        title: 'error',
                        content: error_5.message
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(StyledCard, null,
        React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(HookFormInputs.Text, { label: "Origin", controlProps: {
                    control: form.control,
                    name: 'origin',
                    rules: { required: { value: true, message: 'origin 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "\uD68C\uC0AC\uCF54\uB4DC", controlProps: {
                    control: form.control,
                    name: 'companyCode',
                    rules: { required: { value: true, message: '회사코드 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "ID", controlProps: {
                    control: form.control,
                    name: 'id',
                    rules: { required: { value: true, message: 'ID 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "PW", controlProps: {
                    control: form.control,
                    name: 'password',
                    rules: { required: { value: true, message: 'PW 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(Marginer, { height: 10 }),
            React.createElement("input", __assign({ type: "file", placeholder: "\uACE0\uAC1D\uD30C\uC77C" }, form.register('customerFile', { required: true }))),
            React.createElement("input", __assign({ type: "file", placeholder: "\uB9E4\uCD9C", multiple: true }, form.register('salesFile', { required: true }))),
            React.createElement(FluidButton, { onClick: onSubmit, children: '업로드', type: "primary", disabled: !(isEmpty(state.errors) && !isEmpty(state.dirtyFields) && state.isValid) }))));
};
export var Yonggam = function () {
    var form = useForm({
        mode: 'onChange',
        defaultValues: {
            origin: 'http://localhost:3000',
            id: 'garden02',
            password: 'missa5161'
        }
    });
    var resetForm = function () {
        form.reset({
            origin: 'http://localhost:3000',
            id: 'garden02',
            password: 'missa5161'
        });
    };
    var state = useFormState({ control: form.control });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var origin, rest, error_6, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    origin = values.origin, rest = __rest(values, ["origin"]);
                    return [4 /*yield*/, mergeCustomerDataWithSalesFromYonggam(__assign(__assign({}, rest), { customerFile: rest.customerFile[0] }), origin)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_6 = _a.sent();
                    console.log(error_6);
                    modal.error({ content: error_6.message });
                    return [3 /*break*/, 4];
                case 4:
                    resetForm();
                    return [3 /*break*/, 6];
                case 5:
                    error_7 = _a.sent();
                    modal.error({
                        title: 'error',
                        content: error_7.message
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(StyledCard, null,
        React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(HookFormInputs.Text, { label: "Origin", controlProps: {
                    control: form.control,
                    name: 'origin',
                    rules: { required: { value: true, message: 'origin 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "ID", controlProps: {
                    control: form.control,
                    name: 'id',
                    rules: { required: { value: true, message: 'ID 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "PW", controlProps: {
                    control: form.control,
                    name: 'password',
                    rules: { required: { value: true, message: 'PW 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(Marginer, { height: 10 }),
            React.createElement("input", __assign({ type: "file", placeholder: "\uACE0\uAC1D\uD30C\uC77C" }, form.register('customerFile', { required: true }))),
            React.createElement(FluidButton, { onClick: onSubmit, children: '업로드', type: "primary", disabled: !(isEmpty(state.errors) && !isEmpty(state.dirtyFields) && state.isValid) }))));
};
export var Beautysarang = function () {
    var form = useForm({
        mode: 'onChange',
        defaultValues: {
            origin: 'http://localhost:3000',
            id: '',
            password: '',
            phone: ''
        }
    });
    var resetForm = function () {
        form.reset({
            origin: 'http://localhost:3000',
            id: '',
            password: '',
            phone: ''
        });
    };
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var origin, productData, rest, result, error_8, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, , 7]);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    origin = values.origin, productData = values.productData, rest = __rest(values, ["origin", "productData"]);
                    return [4 /*yield*/, RestClient.getInstance().post("".concat(origin, "/dev/spy/getBeatysarang"), rest, {
                            timeout: -1
                        })];
                case 2:
                    result = _a.sent();
                    return [4 /*yield*/, convertBeautysarangToThinkofyou(result.data)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_8 = _a.sent();
                    modal.error({ content: error_8.message });
                    return [3 /*break*/, 5];
                case 5:
                    resetForm();
                    return [3 /*break*/, 7];
                case 6:
                    error_9 = _a.sent();
                    modal.error({
                        title: 'error',
                        content: error_9.message
                    });
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(StyledCard, null,
        React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(HookFormInputs.Text, { label: "Origin", controlProps: {
                    control: form.control,
                    name: 'origin',
                    rules: { required: { value: true, message: 'origin 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "ID", controlProps: {
                    control: form.control,
                    name: 'id',
                    rules: { required: { value: true, message: 'ID 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "\uD734\uB300\uD3F0\uBC88\uD638", controlProps: {
                    control: form.control,
                    name: 'phone',
                    rules: { required: { value: true, message: '폰번호를 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "PW", controlProps: {
                    control: form.control,
                    name: 'password',
                    rules: { required: { value: true, message: 'PW 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(Marginer, { height: 10 }),
            React.createElement(FluidButton, { onClick: onSubmit, children: '데이터 가져오기', type: "primary" }))));
};
export var AhaPreprocessor = function () {
    var _a = React.useState(), files = _a[0], setFiles = _a[1];
    return (React.createElement(StyledCard, null,
        React.createElement("input", { type: "file", name: "aha", onChange: function (ev) {
                var _a, _b;
                var file = ((_a = ev.target) === null || _a === void 0 ? void 0 : _a.files) && ((_b = ev.target) === null || _b === void 0 ? void 0 : _b.files[0]);
                setFiles(__assign(__assign({}, files), { aha: file }));
            } }),
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { span: 12 },
                React.createElement(FluidButton, { onClick: function () { return setFiles(null); }, children: '취소' })),
            React.createElement(Col, { span: 12 },
                React.createElement(FluidButton, { type: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var error_10;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, preprocessAhaCustomer(files.aha)];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_10 = _a.sent();
                                    modal.error({ content: error_10.message });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, children: '변환', disabled: !(files === null || files === void 0 ? void 0 : files.aha) })))));
};
export var CustomerMigration = function (props) {
    var form = useForm({
        mode: 'onChange',
        defaultValues: {
            type: 'customer'
        }
    });
    var resetForm = function () {
        form.reset({
            type: 'customer'
        });
    };
    var state = useFormState({ control: form.control });
    var checkStore = AdminQuery.useCheckStore();
    var migrateCustomers = AdminQuery.useMigrateCustomers({
        onError: function (err) {
            throw new Error(err.message);
        }
    });
    var migrateTemplatesAndProducts = AdminQuery.useMigrateProductsAndTemplates({
        onError: function (err) {
            throw new Error(err.message);
        }
    });
    var migrateSales = AdminQuery.useMigrateSales({
        onError: function (err) {
            throw new Error(err.message);
        }
    });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var storeId_1, storeName, type, file, checkPayload, _a, store, managers, groups, templates, productGroupModels, customers, _b, validationError, productGroupPayload, templatePayload, result, _c, validationError, customerPayload, result, CHUNK_SIZE, _d, validationError, productGroups, ticketTemplates_1, ticketTemplatesFromOtherSheet, payments, paymentsArray, paymentsArrayLength_1, tickettemplatesArray, tickettemplatesArrayLength_1, productGroupTemplatesArray, productGroupTemplatesArrayLength_1, total, percentPerChunk_1, error_11;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    props.onChangeStatus({ percent: 1, message: '상점 검증 중...' });
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 18, , 19]);
                    storeId_1 = values.storeId, storeName = values.storeName, type = values.type, file = values.file;
                    checkPayload = { storeId: storeId_1, storeName: storeName };
                    return [4 /*yield*/, checkStore.mutateAsync(checkPayload)];
                case 2:
                    _a = _e.sent(), store = _a.store, managers = _a.managers, groups = _a.groups, templates = _a.templates, productGroupModels = _a.productGroupModels, customers = _a.customers;
                    if (!store) {
                        throw new Error('상점 정보가 없습니다.');
                    }
                    props.onChangeStatus({ percent: 20, message: '파일 읽는 중...' });
                    if (!(type === 'templates')) return [3 /*break*/, 5];
                    return [4 /*yield*/, convertTemplateSheetToJSON({
                            file: file[0],
                            productGroupModels: productGroupModels,
                            templates: templates,
                            storeId: Number(store.id)
                        })];
                case 3:
                    _b = _e.sent(), validationError = _b.validationError, productGroupPayload = _b.productGroupPayload, templatePayload = _b.templatePayload;
                    if (validationError) {
                        throw new Error(JSON.stringify(validationError));
                    }
                    props.onChangeStatus({ percent: 40, message: '서버에 데이터 전송 중...' });
                    return [4 /*yield*/, migrateTemplatesAndProducts.mutateAsync({
                            templatePayload: templatePayload,
                            productGroupPayload: productGroupPayload,
                            storeId: storeId_1
                        })];
                case 4:
                    result = _e.sent();
                    modal.info({
                        title: '상품,정기권 데이터 업로드 결과',
                        content: JSON.stringify(result)
                    });
                    return [3 /*break*/, 16];
                case 5:
                    if (!(type === 'customer')) return [3 /*break*/, 10];
                    return [4 /*yield*/, convertCustomerSheetToCustomerData({
                            file: file[0],
                            managers: managers,
                            groups: groups,
                            prevCustomers: customers
                        })];
                case 6:
                    _c = _e.sent(), validationError = _c.validationError, customerPayload = _c.customerPayload;
                    props.onChangeStatus({ percent: 40, message: '서버에 데이터 전송 중...' });
                    if (!validationError) return [3 /*break*/, 7];
                    throw new Error(JSON.stringify(validationError));
                case 7: return [4 /*yield*/, migrateCustomers.mutateAsync({ customerPayload: customerPayload, storeId: storeId_1 })];
                case 8:
                    result = _e.sent();
                    modal.info({
                        title: '고객 데이터 업로드 결과',
                        content: JSON.stringify(result)
                    });
                    _e.label = 9;
                case 9: return [3 /*break*/, 16];
                case 10:
                    if (!(type === 'sales')) return [3 /*break*/, 16];
                    CHUNK_SIZE = 500;
                    return [4 /*yield*/, getSalesDataFromFile({
                            storeId: storeId_1,
                            file: file[0],
                            managers: managers,
                            templates: templates,
                            productGroupModels: productGroupModels,
                            customers: customers
                        })];
                case 11:
                    _d = _e.sent(), validationError = _d.validationError, productGroups = _d.productGroups, ticketTemplates_1 = _d.ticketTemplates, ticketTemplatesFromOtherSheet = _d.ticketTemplatesFromOtherSheet, payments = _d.payments;
                    if (!validationError) return [3 /*break*/, 12];
                    throw new Error(JSON.stringify(validationError));
                case 12:
                    paymentsArray = chunkArray(payments, 100);
                    paymentsArrayLength_1 = paymentsArray.length;
                    tickettemplatesArray = chunkArray(ticketTemplatesFromOtherSheet, CHUNK_SIZE) || [];
                    tickettemplatesArrayLength_1 = tickettemplatesArray.length;
                    productGroupTemplatesArray = chunkArray(productGroups, CHUNK_SIZE) || [];
                    productGroupTemplatesArrayLength_1 = productGroupTemplatesArray.length;
                    total = paymentsArrayLength_1 + tickettemplatesArrayLength_1 + productGroupTemplatesArrayLength_1;
                    percentPerChunk_1 = ceil(50 / total, -1);
                    return [4 /*yield*/, mapSeries(productGroupTemplatesArray, function (groups, index) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        props.onChangeStatus({
                                            message: "\uC0C1\uD488 \uC5C5\uB85C\uB4DC \uC911 ".concat(index + 1, "/").concat(productGroupTemplatesArrayLength_1, "..."),
                                            percent: 50 + percentPerChunk_1 * (tickettemplatesArrayLength_1 + index + 1)
                                        });
                                        return [4 /*yield*/, migrateSales.mutateAsync({
                                                productGroups: groups,
                                                storeId: storeId_1,
                                                payments: []
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 13:
                    _e.sent();
                    // 티켓 템플릿 업데이트
                    return [4 /*yield*/, mapSeries(tickettemplatesArray, function (templates, index) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        props.onChangeStatus({
                                            message: "\uC815\uAE30\uAD8C \uD15C\uD50C\uB9BF \uC5C5\uB85C\uB4DC \uC911 ".concat(index + 1, "/").concat(tickettemplatesArrayLength_1, "..."),
                                            percent: 50 + percentPerChunk_1 * (index + 1)
                                        });
                                        return [4 /*yield*/, migrateSales.mutateAsync({
                                                ticketTemplates: ticketTemplates_1,
                                                ticketTemplatesFromOtherSheet: templates,
                                                storeId: storeId_1
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 14:
                    // 티켓 템플릿 업데이트
                    _e.sent();
                    return [4 /*yield*/, mapSeries(paymentsArray, function (payments, index) { return __awaiter(void 0, void 0, void 0, function () {
                            var error_12;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        props.onChangeStatus({
                                            message: "\uB9E4\uCD9C \uB370\uC774\uD130 \uC5C5\uB85C\uB4DC \uC911 \uC911 ".concat(index + 1, "/").concat(paymentsArrayLength_1, "..."),
                                            percent: 50 + percentPerChunk_1 * (tickettemplatesArrayLength_1 + productGroupTemplatesArrayLength_1 + index + 1)
                                        });
                                        return [4 /*yield*/, migrateSales.mutateAsync({
                                                payments: payments,
                                                storeId: storeId_1
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        error_12 = _a.sent();
                                        throw new Error(payments
                                            ? "\uB9E4\uCD9C \uB4F1\uB85D \uC911 \uC5D0\uB7EC \uBC1C\uC0DD ".concat(JSON.stringify(payments[0]), " ").concat(error_12.message)
                                            : "\uB9E4\uCD9C \uB4F1\uB85D \uC911 \uC5D0\uB7EC \uBC1C\uC0DD ".concat(error_12.message));
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })];
                case 15:
                    _e.sent();
                    modal.info({
                        title: '매출 데이터 업로드 결과',
                        content: '매출 데이터 업데이트 완료'
                    });
                    _e.label = 16;
                case 16:
                    props.onChangeStatus({ percent: 100, message: '업로드 완료' });
                    return [4 /*yield*/, sleep(1000)];
                case 17:
                    _e.sent();
                    props.onChangeStatus(null);
                    resetForm();
                    return [3 /*break*/, 19];
                case 18:
                    error_11 = _e.sent();
                    modal.error({
                        title: 'error',
                        content: error_11.message
                    });
                    props.onChangeStatus(null);
                    return [3 /*break*/, 19];
                case 19: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(StyledCard, null,
        React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(HookFormInputs.SelectInput, { label: "\uD0C0\uC785", controlProps: { control: form.control, name: 'type', rules: { required: true } }, inputProps: {
                    options: [
                        { label: '매출', value: 'sales' },
                        { label: '고객데이터', value: 'customer' },
                        { label: '상품,정기권', value: 'templates' }
                    ]
                } }),
            React.createElement(HookFormInputs.Text, { label: "\uC0C1\uC810\uBA85", controlProps: {
                    control: form.control,
                    name: 'storeName',
                    rules: { required: { value: true, message: '상점명 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: "\uC0C1\uC810\uC544\uC774\uB514", controlProps: {
                    control: form.control,
                    name: 'storeId',
                    rules: { required: { value: true, message: '상점아이디 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(Marginer, { height: 10 }),
            React.createElement("input", __assign({ type: "file" }, form.register('file', { required: true }))),
            React.createElement(FluidButton, { onClick: onSubmit, children: '업로드', type: "primary", disabled: !(isEmpty(state.errors) && !isEmpty(state.dirtyFields) && state.isValid) }))));
};
export var CustomerMetaUpdater = function (props) {
    var form = useForm({
        mode: 'onChange'
    });
    var resetForm = function () {
        form.reset();
    };
    var state = useFormState({ control: form.control });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onSubmit(values.storeId)];
                case 1:
                    _a.sent();
                    resetForm();
                    return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(StyledCard, null,
        React.createElement("form", { onSubmit: form.handleSubmit(onSubmit) },
            React.createElement(HookFormInputs.Text, { label: "\uC0C1\uC810\uC544\uC774\uB514", controlProps: {
                    control: form.control,
                    name: 'storeId',
                    rules: { required: { value: true, message: '상점아이디 입력해주세요.' } }
                }, inputProps: {} }),
            React.createElement(Marginer, { height: 10 }),
            React.createElement(FluidButton, { onClick: onSubmit, children: '시작', type: "primary", disabled: !(isEmpty(state.errors) && !isEmpty(state.dirtyFields) && state.isValid) }))));
};
var FluidButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 10px;\n"], ["\n  width: 100%;\n  margin-top: 10px;\n"])));
var StyledCard = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
