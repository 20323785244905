import { IMAGES } from 'renderer/assets';
var parseBoolean = function (val) { return /^true$/i.test(val); };
// export const API_URL = 'https://api.hatchling.kr';
// export const API_URL = 'https://dev-api.hatchling.kr';
// export const API_URL = 'http://localhost:8800';
export var API_URL = process.env.API_URL;
export var WS_URL = process.env.WS_URL;
export var IS_ELECTRON = parseBoolean(process.env.IS_ELECTRON);
export var APP_VERSION = process.env.APP_VERSION;
export var BUILD_NUMBER = process.env.BUILD_NUMBER;
export var NODE_ENV = process.env.NODE_ENV;
export var AWS_ACCESS_KEY_ID = process.env.AWS_ACCESS_KEY_ID;
export var AWS_SCRET_ACCESS_KEY = process.env.AWS_SCRET_ACCESS_KEY;
export var IAMPORT_STORE_ID = process.env.IAMPORT_STORE_ID;
// export const AWS_LAMBDA_BASE_URL = 'http://localhost:3000/dev';
// export const AWS_LAMBDA_BASE_URL = 'https://dev-functions.thinkofyou.kr';
// export const AWS_LAMBDA_BASE_URL = 'https://functions.thinkofyou.kr';
export var AWS_LAMBDA_BASE_URL = process.env.AWS_LAMBDA_BASE_URL;
export var API_STAGE = API_URL.includes('http://') ? 'local' : API_URL.includes('dev') ? 'development' : 'production';
export var MULTI_LINK_SERVICE_URL = process.env.MULTI_LINK_SERVICE_URL;
export var ATS_FEE = 15;
export var FTS_FEE = 30;
export var FMS_FEE = 75;
export var SMS_FEE = 18;
export var LMS_FEE = 39;
export var MMS_FEE = 150;
export var DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23];
export var MIXPANEL_TRACK_ID = process.env.MIXPANEL_TRACK_ID;
export var VBAF1231AAA = 'vbabzxcq111';
export var DRITN_LOGO = 'https://blockdeal-static-resources.s3.ap-northeast-2.amazonaws.com/franchise_logos/1.png';
export var getLogoImage = function (franchiseId) {
    return window.location.origin === 'https://crmp.kr'
        ? DRITN_LOGO
        : franchiseId
            ? "https://blockdeal-static-resources.s3.ap-northeast-2.amazonaws.com/franchise_logos/".concat(franchiseId, ".png")
            : IMAGES.logo;
};
export var DEFAULT_KAKAO_ALIMTALK_PROFILE_KEY = process.env.DEFAULT_KAKAO_ALIMTALK_PROFILE_KEY;
export var DEFAULT_KAKAO_ALIMTALK_PROFILE_NAME = process.env.DEFAULT_KAKAO_ALIMTALK_PROFILE_NAME;
export var THINKOFYOU_080_INBOUND_NUMBER = process.env.THINKOFYOU_080_INBOUND_NUMBER;
