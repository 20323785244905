export var PATH_ROOT = "/";
export var PATH_SIGNIN = "/login";
export var PATH_SIGNIN_FORGOT_ID = "/login/find-id";
export var PATH_SIGNIN_FORGOT_PASSWORD = "/login/find-password";
export var PATH_SIGNUP = "/signup";
export var PATH_SIGNUP_SERVICE_TERMS = "".concat(PATH_SIGNUP, "/service");
export var PATH_SIGNUP_PRIVACY_TERMS = "".concat(PATH_SIGNUP, "/privacy");
export var PATH_SIGNUP_TICKET_SEND_TERMS = "".concat(PATH_SIGNUP, "/ticket-terms");
export var PATH_CUSTOMER = "/customers";
export var PATH_CUSTOMER_TICKET = "/customers/ticket";
export var PATH_TICKET_CHECK_MODAL = "/ticket-check";
export var PATH_AUTH = "/authorization";
export var PATH_SIGNOUT = "/signout";
export var PATH_APP = "/app";
export var PATH_TICKET_WITH_QR = '/ticket';
export var PATH_APP_DASHBOARD_MANAGEMENT = "".concat(PATH_APP, "/dashboard");
export var PATH_APP_TICKET_MANAGEMENT = "".concat(PATH_APP, "/sales1");
export var PATH_APP_TICKET_MANAGEMENT_SALES = "".concat(PATH_APP_TICKET_MANAGEMENT, "/list");
export var PATH_APP_TICKET_MANAGEMENT_USED = "".concat(PATH_APP_TICKET_MANAGEMENT, "/used");
export var PATH_APP_TICKET_MANAGEMENT_ASSIGNMENT = "".concat(PATH_APP_TICKET_MANAGEMENT, "/assignment");
export var PATH_APP_RESERVATION = "".concat(PATH_APP, "/reservations");
export var PATH_APP_TRANSFERS = "".concat(PATH_APP, "/deposit");
export var PATH_APP_CUSTOMER = "".concat(PATH_APP, "/customers");
export var PATH_APP_PREPAID = "".concat(PATH_APP, "/customer-prepaid");
export var PATH_APP_CUSTOMER_DETAIL = "".concat(PATH_APP_CUSTOMER, "/:id");
export var PATH_APP_CUSTOMER_DETAIL_HISTORY = "".concat(PATH_APP_CUSTOMER_DETAIL, "/history");
export var PATH_APP_CUSTOMER_SMS_TEMPLATES = "".concat(PATH_APP, "/customers/templates");
export var PATH_APP_CUSTOMER_SMS = "".concat(PATH_APP, "/customers/sms");
export var PATH_APP_SALES = "".concat(PATH_APP, "/sales");
export var PATH_APP_STOCKS = "".concat(PATH_APP, "/stocks");
export var PATH_APP_EXPENSES = "".concat(PATH_APP, "/expenses");
export var PATH_APP_SALES_EMPLOYEE = "".concat(PATH_APP, "/employee/sales");
export var PATH_APP_SALES_STATISTICS = "".concat(PATH_APP, "/statistics");
export var PATH_APP_USINGS_STATISTICS = "".concat(PATH_APP, "/usings");
export var PATH_APP_MARKETING = "".concat(PATH_APP, "/marketing");
export var PATH_APP_MESSAGES = "".concat(PATH_APP, "/messages/home");
export var PATH_APP_MESSAGES_SEND_MODAL = "".concat(PATH_APP, "/messages/home/send");
export var PATH_APP_MESSAGES_HISTORY = "".concat(PATH_APP, "/messages/history");
export var PATH_APP_MESSAGES_TEMPLTES = "".concat(PATH_APP, "/messages/templates");
export var PATH_APP_MULTILINK = "".concat(PATH_APP, "/multilink");
export var PATH_APP_VERIFICATION = "".concat(PATH_APP, "/verification");
export var PATH_APP_MY_STORE = "".concat(PATH_APP, "/mystore");
export var PATH_APP_MY_EMPLOYEES = "".concat(PATH_APP, "/employees");
export var PATH_APP_STORE_SUBSCRIPTIONS = "".concat(PATH_APP_MY_STORE, "/subscriptions");
export var PATH_APP_STORE_POINTS = "".concat(PATH_APP_MY_STORE, "/points");
export var PATH_APP_STORE_PAYMENT_METHODS = "".concat(PATH_APP_MY_STORE, "/paymentmethods");
export var PATH_APP_STORE_INVOICES = "".concat(PATH_APP_MY_STORE, "/invoices");
export var PATH_APP_MY = "".concat(PATH_APP, "/my");
export var PATH_APP_MY_ACCOUNT = "".concat(PATH_APP_MY, "/account");
export var PATH_APP_MY_PROFILE = "".concat(PATH_APP_MY, "/profile");
export var PATH_ADMIN = "/admin";
export var PATH_ADMIN_FRACHISES = "".concat(PATH_ADMIN, "/franchises");
export var PATH_ADMIN_DASHBOARD = "".concat(PATH_ADMIN, "/dashboard");
export var PATH_ADMIN_INVOICES = "".concat(PATH_ADMIN, "/invoices");
export var PATH_ADMIN_PAYMENTS = "".concat(PATH_ADMIN, "/payments");
export var PATH_ADMIN_MIGRATION = "".concat(PATH_ADMIN, "/migration");
export var PATH_FRANCHISE_ADMIN = "/franchises";
export var PATH_FRANCHISE_ADMIN_STORES = "".concat(PATH_FRANCHISE_ADMIN, "/stores");
export var PATH_FRANCHISE_ADMIN_INVOICES = "".concat(PATH_FRANCHISE_ADMIN, "/invoices");
export var PATH_REVIEWS = "/reviews";
export var PATH_REVIEWS_REVIEW = "/reviews/:id";
export var PATH_REVIEWS_RECEIPT = "".concat(PATH_REVIEWS_REVIEW, "/receipt");
