var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { ClickableIcon, BodyTemplate, ResponsiveWithPlusIcon, useGlobalModal, CustomDropdown, useEntireLoader } from 'renderer/components';
import styled from 'styled-components';
import Row from 'antd/lib/row';
import { uniqBy, useWindowSize, WebviewInterface, isEmpty, isPlussharpMobileApp, chunkArray, mapSeries, sum } from 'renderer/utils';
import { Col } from 'antd/lib/grid';
import { CustomerTab } from './CustomerTab';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Atoms } from 'renderer/stores';
import { CustomerQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
export var CustomerList = function (props) {
    var store = props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var updateModal = useGlobalModal();
    var handleClick = function (customer) {
        updateModal.open({ type: 'customerDetail', data: { customerId: Number(customer.id) } });
    };
    var _a = useRecoilState(Atoms.globalSettingModalState), _ = _a[0], setModal = _a[1];
    var hadleOpenSetting = function (type) {
        setModal({ type: type });
    };
    var _b = useWindowSize(), isPC = _b.isPC, height = _b.height;
    return (React.createElement(BodyTemplate, { title: "\uACE0\uAC1D\uB9AC\uC2A4\uD2B8", extra: isPC && (React.createElement(CustomerActions, { isPC: isPC, onClickDrawer: hadleOpenSetting, onClickRegister: props.onClickRegister, storeId: storeId })), content: React.createElement(Wrapper, null,
            React.createElement(CustomerTab, { store: store, onRowClick: handleClick, onClickDrawer: hadleOpenSetting, onClickRegister: props.onClickRegister })) }));
};
export var CustomerActions = function (props) {
    var isPC = props.isPC, storeId = props.storeId, onClickDrawer = props.onClickDrawer;
    var token = useRecoilValue(Atoms.accessTokenState);
    var bulkCreate = CustomerQuery.useBulkCreateCustomerMutation();
    var loader = useEntireLoader();
    var handleGetContacts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var customers, items, payloadArray, results_1, result_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    return [4 /*yield*/, WebviewInterface.getInstance().requestRegisterContracts({
                            storeId: props.storeId,
                            token: token
                        })];
                case 1:
                    customers = _a.sent();
                    if (!(Array.isArray(customers) && !isEmpty(customers))) return [3 /*break*/, 3];
                    loader.show();
                    items = uniqBy(customers || [], 'phone').map(function (customer) {
                        return {
                            name: customer.displayName,
                            phone: customer.phone,
                            memo: customer.memo,
                            storeId: props.storeId,
                            data: {
                                email: customer.emailAddresses || '',
                                rejectReceive: false,
                                phone: {
                                    dialCode: 82,
                                    countryCode: 'kr'
                                }
                            }
                        };
                    });
                    payloadArray = chunkArray(items, 100);
                    return [4 /*yield*/, mapSeries(payloadArray, function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                            var result_2, err_2;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, bulkCreate.mutateAsync({ storeId: props.storeId, payload: payload })];
                                    case 1:
                                        result_2 = _a.sent();
                                        return [2 /*return*/, result_2];
                                    case 2:
                                        err_2 = _a.sent();
                                        return [2 /*return*/, { success: 0, duplicated: 0, failure: 0 }];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })];
                case 2:
                    results_1 = _a.sent();
                    result_1 = { success: 0, duplicated: 0, failure: 0 };
                    Object.keys(result_1).map(function (key) {
                        result_1[key] = sum(results_1.map(function (resultItem) { return resultItem[key] || 0; }));
                    });
                    notification.success({
                        message: "\uC131\uACF5: ".concat(result_1.success, " / \uC911\uBCF5: ").concat(result_1.duplicated, " / \uC2E4\uD328: ").concat(result_1.failure)
                    });
                    loader.hide();
                    _a.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    err_1 = _a.sent();
                    notification.error({
                        message: '연락처 동기화 중 에러 발생'
                    });
                    loader.hide();
                    return [3 /*break*/, 6];
                case 5:
                    loader.hide();
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Row, { align: "middle", gutter: isPC ? 8 : 0 },
        React.createElement(Col, null, isPC ? (React.createElement(ResponsiveWithPlusIcon, { children: "\uC2E0\uADDC \uACE0\uAC1D \uB4F1\uB85D", onClick: function () { return props.onClickRegister({ storeId: storeId }); } })) : (React.createElement(ClickableIcon, { strokeWidth: 2, name: "plus", size: 28, style: { marginRight: '10px' }, onClick: function () { return props.onClickRegister({ storeId: storeId }); } }))),
        React.createElement(Col, null,
            React.createElement(CustomDropdown, { items: [
                    { label: '등급 설정', onClick: function () { return onClickDrawer('customerGroup'); }, name: 'group' },
                    { label: '태그 설정', onClick: function () { return onClickDrawer('customerTag'); }, name: 'tag' },
                    isPlussharpMobileApp() && { label: '연락처 가져오기', onClick: handleGetContacts, name: 'contacts' }
                ].filter(Boolean) }, isPC ? (React.createElement(ResponsiveWithPlusIcon, { children: "\uB4F1\uAE09\u2219\uD0DC\uADF8 \uC124\uC815" })) : (React.createElement(ClickableIcon, { style: { marginRight: '10px' }, size: 24, name: "settings", strokeWidth: 2 }))))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
var templateObject_1;
