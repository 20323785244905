var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { BodyTemplate, TableComponent, TextComponent } from 'renderer/components';
import { KRWFomatToString } from 'renderer/components/Intl';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'renderer/constants';
import { FranchiseInvoiceModel } from 'renderer/models';
import Select from 'antd/lib/select';
import { AdminQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
export var FranchiseManagement = function (props) {
    return (React.createElement(BodyTemplate, { title: "\uD504\uB79C\uCC28\uC774\uC988 \uAD00\uB9AC", content: React.createElement("div", null,
            React.createElement(FranchiseTable, null),
            React.createElement(InvoiceTable, null)) }));
};
export var InvoiceTable = function (props) {
    var _a = AdminQuery.useInvoices({
        page: 1,
        limit: 10
    }), pagerResult = _a.pagerResult, isLoading = _a.isLoading, getPaginateObject = _a.getPaginateObject;
    var handleUpdate = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, AdminQuery.updateFranchiseInvoice(values)];
                case 1:
                    _a.sent();
                    notification.info({ message: '변경 완료' });
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    notification.error({ message: err_1.message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, AdminQuery.deleteFranchiseInvoice(id)];
                case 1:
                    _a.sent();
                    notification.info({ message: '삭제 완료' });
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    notification.error({ message: err_2.message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            id: 'title',
            title: '업체명',
            width: 200,
            render: function (_, originalRow) {
                var _a;
                return (_a = originalRow.franchise) === null || _a === void 0 ? void 0 : _a.name;
            }
        },
        {
            id: 'title',
            title: '제목',
            width: 250,
            render: function (_, originalRow) {
                return originalRow.title;
            }
        },
        {
            id: 'status',
            title: '입금상태',
            render: function (_, originalRow) {
                return (React.createElement(Select, { id: "demo-simple-select-helper", value: originalRow.status, options: [
                        { label: '발행', value: FranchiseInvoiceModel.INVOICE_STATUS.OPENED },
                        { label: '완료', value: FranchiseInvoiceModel.INVOICE_STATUS.PAID }
                    ], onChange: function (value) {
                        handleUpdate({ id: originalRow.id, status: value });
                    } }));
            }
        },
        {
            id: 'createdAt',
            title: '발행일',
            width: 100,
            render: function (_, originalRow) {
                return dayjs(originalRow.createdAt).format(DATE_FORMAT);
            }
        },
        {
            id: 'ceoName',
            title: '납입일',
            width: 100,
            render: function (_, originalRow) {
                return originalRow.paidAt ? dayjs(originalRow.paidAt).format(DATE_FORMAT) : '-';
            }
        },
        {
            id: 'ceoName',
            title: '납입총액(A-B+C+D)',
            width: 150,
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.finalPrice });
            }
        },
        {
            id: 'total',
            title: '총금액(A)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.totalPrice });
            }
        },
        {
            id: 'discount',
            title: '할인(B)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.totalDiscount });
            }
        },
        {
            id: 'vat',
            title: 'VAT(C)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.totalVat });
            }
        },
        {
            id: 'adjust',
            title: '조정금액(D)',
            render: function (_, originalRow) {
                return (React.createElement(InputNumber, { key: originalRow.id, defaultValue: originalRow.adjustPrice, onBlur: function (ev) {
                        handleUpdate({ id: originalRow.id, adjustPrice: Number(ev.target.value || 0) });
                    } }));
            }
        },
        {
            id: 'adjust',
            title: '삭제',
            render: function (_, originalRow) {
                return (React.createElement(Button, { children: "\uC0AD\uC81C", onClick: function () {
                        handleDelete(originalRow.id);
                    } }));
            }
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(TextComponent, { children: "\uCCAD\uAD6C\uC11C", type: "headline3", marginBottom: 10 }),
        React.createElement(TableComponent, { loading: isLoading, columns: columns, pagination: getPaginateObject(), dataSource: pagerResult.items })));
};
export var FranchiseTable = function (props) {
    var _a = AdminQuery.useFranchises({
        page: 1,
        limit: 10
    }), pagerResult = _a.pagerResult, isLoading = _a.isLoading, getPaginateObject = _a.getPaginateObject;
    var columns = [
        {
            id: 'title',
            title: '업체명',
            render: function (_, originalRow) {
                return originalRow === null || originalRow === void 0 ? void 0 : originalRow.name;
            }
        },
        {
            id: 'title',
            title: '가입상점 수',
            render: function (_, originalRow) {
                var _a;
                return ((_a = originalRow.stores) === null || _a === void 0 ? void 0 : _a.length) || 0;
            }
        },
        {
            id: 'ceoName',
            title: '담당자',
            render: function (_, originalRow) {
                var _a, _b, _c;
                return "".concat((_a = originalRow.manager) === null || _a === void 0 ? void 0 : _a.displayName, " ").concat((_c = (_b = originalRow === null || originalRow === void 0 ? void 0 : originalRow.manager) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.phone);
            }
        },
        {
            id: 'create',
            title: '청구서생성',
            render: function (_, originalRow) {
                return (React.createElement(Button, { children: "\uCCAD\uAD6C\uC11C\uC0DD\uC131", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var err_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, AdminQuery.createFranchiseInvoice({ franchiseId: originalRow.id })];
                                case 1:
                                    _a.sent();
                                    notification.info({ message: '성성완료' });
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_3 = _a.sent();
                                    notification.error({ message: '에러발생' });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); } }));
            }
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(TextComponent, { children: "\uC5C5\uCCB4 \uBAA9\uB85D", type: "headline3", marginBottom: 10 }),
        React.createElement(TableComponent, { loading: isLoading, columns: columns, pagination: getPaginateObject(), dataSource: pagerResult.items })));
};
