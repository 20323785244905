import React from 'react';
import { useEntireLoader } from 'renderer/components';
import { StoreQuery } from 'renderer/queries';
import { PATH_APP_RESERVATION } from 'renderer/constants';
import { getSearchParams } from 'renderer/utils';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Atoms } from 'renderer/stores';
export var VerificationPhone = function (props) {
    var store = StoreQuery.getMyStoreInCache();
    var location = useLocation();
    var navigate = useNavigate();
    var loader = useEntireLoader();
    var _a = useRecoilState(Atoms.globalSettingModalState), modal = _a[0], setModal = _a[1];
    React.useEffect(function () {
        var params = getSearchParams(location.search);
        var redirect = params.get('redirect') || PATH_APP_RESERVATION;
        var storeId = params.get('storeId') || store.id;
        var imp_uid = params.get('imp_uid');
        var phone = params.get('phone');
        var success = params.get('success') === 'true';
        if (storeId && imp_uid && success && phone) {
            loader.show();
            StoreQuery.verificationPhoneNumber({ imp_uid: imp_uid, storeId: Number(storeId), phone: phone })
                .then(function () {
                alert('발신 번호 등록이 완료되었습니다.');
            })
                .catch(function (err) {
                console.log('error', err);
                alert('발신 번호 등록 중 에러가 발생했습니다.');
            })
                .finally(function () {
                loader.hide();
                navigate(redirect, { replace: true });
                setModal({ type: 'senderInfo' });
            });
        }
        else {
            navigate(redirect, { replace: true });
            setModal({ type: 'senderInfo' });
        }
    }, []);
    return React.createElement("div", null);
};
