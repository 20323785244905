import React from 'react';
import { BodyTemplate, TableComponent } from 'renderer/components';
import { FranchisesQuery, ManagerQuery } from 'renderer/queries';
import { DATE_FORMAT } from 'renderer/constants';
import dayjs from 'dayjs';
import { KRWFomatToString } from 'renderer/components/Intl';
export var FranchiseInvoices = function (props) {
    return React.createElement(BodyTemplate, { title: "\uC774\uC6A9\uB8CC \uC815\uC0B0", content: React.createElement(FranchiseStoreTable, null) });
};
var FranchiseStoreTable = function (props) {
    var _a, _b;
    var result = ManagerQuery.getMeWithoutStoreIdInCache();
    var _c = FranchisesQuery.useInvoices({
        page: 1,
        limit: 10,
        franchiseId: (_a = result === null || result === void 0 ? void 0 : result.manager) === null || _a === void 0 ? void 0 : _a.franchiseId
    }, {
        enabled: !!((_b = result === null || result === void 0 ? void 0 : result.manager) === null || _b === void 0 ? void 0 : _b.franchiseId)
    }), pagerResult = _c.pagerResult, isLoading = _c.isLoading, getPaginateObject = _c.getPaginateObject;
    var columns = [
        {
            id: 'title',
            title: '제목',
            render: function (_, originalRow) {
                return originalRow.title;
            }
        },
        {
            id: 'status',
            title: '상태',
            render: function (_, originalRow) {
                return originalRow.invoiceString;
            }
        },
        {
            id: 'createdAt',
            title: '발행일',
            render: function (_, originalRow) {
                return dayjs(originalRow.createdAt).format(DATE_FORMAT);
            }
        },
        {
            id: 'ceoName',
            title: '납입일',
            render: function (_, originalRow) {
                return originalRow.paidAt ? dayjs(originalRow.paidAt).format(DATE_FORMAT) : '-';
            }
        },
        {
            id: 'ceoName',
            title: '납입총액(A-B+C+D)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.finalPrice });
            }
        },
        {
            id: 'total',
            title: '총액(A)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.totalPrice });
            }
        },
        {
            id: 'discount',
            title: '할인(B)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.totalDiscount });
            }
        },
        {
            id: 'vat',
            title: '부가세(C)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.totalVat });
            }
        },
        {
            id: 'adjust',
            title: '조정금액(D)',
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.adjustPrice });
            }
        }
    ];
    return (React.createElement(TableComponent, { loading: isLoading, columns: columns, pagination: getPaginateObject(), dataSource: pagerResult.items }));
};
