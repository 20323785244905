export var REGEX_URL = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export var REGEX_TEL = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
export var REGEX_PHONE = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/g;
export var REGEX_BIRTH = /^([0-9]{4}?)-([0-9]{1,2})-([0-9]{1,2})$/i;
export var REGEX_ACCOUNT = /^\d+[\d\-]+\d$/g;
export var REGEX_ID = /^[A-Za-z0-9_]{4,20}$/g;
export var REGEX_NUMBER_FORMMATER = /\B(?=(\d{3})+(?!\d))/g;
export var REGEX_NUMBER_PARSER = /\-\s?|(,)|\s|\원|\개|\회|\시간|\점|\%/g;
export var REGEX_NUMBER_PARSER_ALLOW_NEGATIVE = /(,)|\s|\원|\회|\시간|\개|\점|\%/g;
export var REGEX_TIME_MINUTES_STRING = /\d{2}:\d{2}/g;
export var REGEX_BIX_NUM = /^\d{3}-\d{2}-\d{5}$/g;
export var REGEX_SPECIAL_CHARACTER = /([^\d|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣])/g;
