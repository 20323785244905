var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import * as dayjs from 'dayjs';
import * as Styles from './styles.css';
import { Lodash, sum } from 'renderer/utils';
import { AntdForm, DrawerFooter, Marginer, DrawerComponent, StyledFormItem, NumberFormatInput, TextComponent, AlertComponent, XScrollableTable, AntButton } from 'renderer/components';
import { SalesGoalModel, EXTENDED_SALES_METHODS, SalesHistoryModel } from 'renderer/models';
import Row from 'antd/lib/row';
import Form from 'antd/lib/form';
import { PaymentHistoryQuery, StoreQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { Searcher, SettingCardWithTitle } from './SalesGoalTab';
import Col from 'antd/lib/col';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { getIncentiveSetting, renderTotalWithIncen } from './IncentiveDetailTab';
import clsx from 'clsx';
import Switch from 'antd/lib/switch';
export var IncentiveTab = function (props) {
    var _a;
    var today = dayjs();
    var _b = React.useState({
        month: today.toDate()
    }), query = _b[0], setQuery = _b[1];
    var salesStatistics = PaymentHistoryQuery.useSalesStatisticsGroupByManager(__assign(__assign({}, query), { storeId: props.store.id }), {
        retryOnMount: false,
        enabled: query && !!((_a = props.store) === null || _a === void 0 ? void 0 : _a.id)
    });
    var handleChange = function (data) {
        if (data.duration) {
            var _a = data.duration, startedAt = _a[0], endedAt = _a[1];
            setQuery({
                startedAt: startedAt.startOf('day').toDate(),
                endedAt: endedAt.endOf('day').toDate()
            });
        }
        else {
            setQuery({
                month: data.month.toDate()
            });
        }
    };
    var managers = props.workingManagers;
    var incentiveSetting = props.store.incentives;
    var dataSource = getDataSource(incentiveSetting, salesStatistics.data, managers === null || managers === void 0 ? void 0 : managers.filter(function (manager) { var _a; return (props.isAll ? true : (manager === null || manager === void 0 ? void 0 : manager.id) === ((_a = props.loginManager) === null || _a === void 0 ? void 0 : _a.id)); }));
    var enabledIncentive = !!(incentiveSetting === null || incentiveSetting === void 0 ? void 0 : incentiveSetting.enabled);
    var columns = getIncentiveTableColumns(dataSource, props.store, enabledIncentive);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { justify: "space-between", align: "middle" },
            React.createElement(Searcher, { onChange: handleChange }),
            props.isPC && React.createElement(AntButton, { type: "primary", children: "\uC9C1\uC6D0\uBCC4 \uC778\uC13C\uD2F0\uBE0C \uC124\uC815", onClick: props.onClickDrawerOpen })),
        React.createElement(Marginer, { height: 20 }),
        React.createElement(Row, null,
            React.createElement(XScrollableTable, { id: "TABLE_ID_ENABLE_INCETIVE", resizable: {
                    additionalColumnWidth: 0
                }, key: "".concat(enabledIncentive, "_incentive_table_").concat(columns.length), pagination: false, scroll: { x: 800 }, rowKey: function (row, index) { return "".concat(index); }, rowClassName: function (record, index) {
                    return clsx([
                        (record.managerName === '총계' || record.typeString === '총계') && Styles.totalRow
                        // (!!record.managerName || record.isLast) && Styles.tableLastRow
                    ]);
                }, dataSource: dataSource, columns: __spreadArray([
                    {
                        title: '직원명',
                        dataIndex: 'managerName',
                        key: 'managerName',
                        fixed: 'left',
                        width: 100,
                        render: function (value, _, index) {
                            var obj = {
                                children: value,
                                props: {
                                    rowSpan: 1
                                }
                            };
                            var managerId = _.managerId;
                            var rowsPerManager = _.rowSpan;
                            if (index % rowsPerManager === 0) {
                                obj.props.rowSpan = rowsPerManager;
                            }
                            else if (index % rowsPerManager != 0) {
                            }
                            obj.props.rowSpan = rowsPerManager;
                            if (!managerId) {
                                obj.children = '총계';
                                obj.props.colSpan = 2;
                                obj.props.rowSpan = 1;
                                obj.props.style = { backgroundColor: COLORS.primary5 };
                            }
                            return obj;
                        }
                    },
                    {
                        title: '분류',
                        dataIndex: 'typeString',
                        key: 'typeString',
                        width: 100,
                        render: function (value, _, index) {
                            var obj = {
                                children: value,
                                props: {
                                    colSpan: 1
                                }
                            };
                            if (_.managerName === '총계') {
                                obj.props.colSpan = 0;
                            }
                            return obj;
                        }
                    }
                ], columns, true) })),
        React.createElement(IncentiveNotification, null)));
};
export var RegisterIncentive = function (props) {
    var defaultData = {
        service: {
            cash: 0,
            customerPoint: 0,
            card: 0,
            deductionPriceByTicket: 0,
            unpaid: 0,
            prepaidPoint: 0,
            transfer: 0,
            naverPay: 0,
            etc1: 0,
            etc2: 0,
            etc3: 0,
            etc4: 0,
            etc5: 0,
            discount: 0
        },
        ticket: {
            cash: 0,
            card: 0,
            customerPoint: 0,
            transfer: 0,
            unpaid: 0,
            naverPay: 0,
            etc1: 0,
            etc2: 0,
            etc3: 0,
            etc4: 0,
            etc5: 0,
            discount: 0
        },
        product: {
            cash: 0,
            customerPoint: 0,
            card: 0,
            transfer: 0,
            deductionPriceByTicket: 0,
            unpaid: 0,
            prepaidPoint: 0,
            naverPay: 0,
            etc1: 0,
            etc2: 0,
            etc3: 0,
            etc4: 0,
            etc5: 0,
            discount: 0
        }
    };
    var storeId = props.store.id;
    var visible = !!props.visible;
    var _a = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _a[1];
    var managers = props.managers;
    var getInitialValue = function () {
        var incentives = props.store.incentives;
        var isAll = !!incentives.all;
        if (isAll) {
            return incentives;
        }
        else {
            managers.map(function (manager) {
                var managerId = manager.id;
                var hasSetting = !!incentives[managerId];
                if (!hasSetting) {
                    incentives[managerId] = defaultData;
                }
            });
        }
        return incentives;
    };
    React.useEffect(function () {
        var isAll = !!getInitialValue().all;
        form.setFieldsValue(__assign({ policy: isAll ? SalesGoalModel.SALES_GOAL_POLICY.ALL : SalesGoalModel.SALES_GOAL_POLICY.EACH }, getInitialValue()));
        forceUpdate();
    }, [props.visible]);
    var form = Form.useForm()[0];
    var isAll = form.getFieldValue('policy') === SalesGoalModel.SALES_GOAL_POLICY.ALL;
    var types = [
        EXTENDED_SALES_METHODS.CARD,
        EXTENDED_SALES_METHODS.TRANSFER,
        EXTENDED_SALES_METHODS.CASH,
        EXTENDED_SALES_METHODS.NAVER_PAY,
        EXTENDED_SALES_METHODS.ETC1,
        EXTENDED_SALES_METHODS.ETC2,
        EXTENDED_SALES_METHODS.ETC3,
        EXTENDED_SALES_METHODS.ETC4,
        EXTENDED_SALES_METHODS.ETC5,
        EXTENDED_SALES_METHODS.UNPAID,
        EXTENDED_SALES_METHODS.PREPAID_POINT,
        EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
        EXTENDED_SALES_METHODS.CUSTOMER_POINT,
        EXTENDED_SALES_METHODS.DISCOUNT
    ];
    var DEFAULT_TYPES = [
        EXTENDED_SALES_METHODS.CARD,
        EXTENDED_SALES_METHODS.TRANSFER,
        EXTENDED_SALES_METHODS.CASH,
        EXTENDED_SALES_METHODS.NAVER_PAY,
        EXTENDED_SALES_METHODS.UNPAID,
        EXTENDED_SALES_METHODS.PREPAID_POINT,
        EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
        EXTENDED_SALES_METHODS.CUSTOMER_POINT,
        EXTENDED_SALES_METHODS.DISCOUNT
    ];
    var filteredKeys = types
        .map(function (key) {
        var _a, _b;
        if (((_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.paymentMethods[key]) === null || _b === void 0 ? void 0 : _b.active) || DEFAULT_TYPES.includes(key)) {
            return {
                label: SalesHistoryModel.getSalesMethodLabel(key, props.store),
                value: key,
                isOnlyProduct: [
                    EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
                    EXTENDED_SALES_METHODS.PREPAID_POINT
                ].includes(key)
            };
        }
        else {
            return;
        }
    })
        .filter(Boolean);
    var renderField = function (key, group, field) {
        return (React.createElement(Col, { span: 12, key: "".concat(key, "_").concat(field.value) },
            React.createElement(IncenInput, { labelCol: {
                    span: 12,
                    offset: 0
                }, label: React.createElement(TextComponent, { style: {
                        textOverflow: '..'
                    }, children: field.label, type: "caption1", ellipsis: true }), colon: false, name: [key, group, field.value], rules: [
                    {
                        type: 'number',
                        required: true,
                        min: 0,
                        max: 100,
                        message: "0~100\uC0AC\uC774 \uC22B\uC790\uB85C \uC785\uB825\uD574\uC8FC\uC138\uC694."
                    }
                ] },
                React.createElement(StyledNumberFormatInput
                // placeholder="금액입력"
                , { 
                    // placeholder="금액입력"
                    suffix: "%" }))));
    };
    var renderFields = function (key) {
        var _a;
        var title = isAll ? '전체직원' : (_a = managers === null || managers === void 0 ? void 0 : managers.find(function (manager) { return manager.id === Number(key); })) === null || _a === void 0 ? void 0 : _a.displayName;
        return (React.createElement(SettingCardWithTitle, { key: key, title: title, children: React.createElement("div", null,
                React.createElement(TextComponent, { children: "\uC2DC\uC220 \uD310\uB9E4 \uC778\uC13C\uD2F0\uBE0C", color: COLORS.gray1, type: "subtitle2", marginBottom: 20, marginTop: 10 }),
                React.createElement(Row, { gutter: 32 }, filteredKeys.map(function (field) {
                    return renderField(key, 'service', field);
                })),
                React.createElement(TextComponent, { children: "\uC81C\uD488 \uD310\uB9E4 \uC778\uC13C\uD2F0\uBE0C", color: COLORS.gray1, type: "subtitle2", marginBottom: 20, marginTop: 10 }),
                React.createElement(Row, { gutter: 32 }, filteredKeys.map(function (field) {
                    return renderField(key, 'product', field);
                })),
                React.createElement(TextComponent, { children: "\uC120\uBD88\uC561\u2219\uC815\uAE30\uAD8C \uD310\uB9E4 \uC778\uC13C\uD2F0\uBE0C", color: COLORS.gray1, type: "subtitle2", marginBottom: 20 }),
                React.createElement(Row, { gutter: 32 }, filteredKeys
                    .filter(function (field) { return !field.isOnlyProduct; })
                    .map(function (field) {
                    return renderField(key, 'ticket', field);
                }))) }));
    };
    var enabled = form.getFieldValue(['enabled']);
    var fields = [
        { element: React.createElement(FormTitle, { title: "\uC778\uC13C\uD2F0\uBE0C \uC124\uC815" }) },
        {
            element: (React.createElement(Form.Item, { labelCol: { span: 8 }, wrapperCol: { span: 4 }, name: "enabled", label: "\uC778\uC13C\uD2F0\uBE0C \uC124\uC815" },
                React.createElement(Switch, { checked: form.getFieldValue(['enabled']), onChange: function (checked) {
                        form.setFieldsValue({ disabled: !checked });
                        form.validateFields();
                        forceUpdate();
                    } })))
        },
        {
            type: 'radio',
            itemProps: {
                name: 'policy',
                rules: [{ type: 'string', required: true }],
                hidden: !enabled
            },
            inputProps: {
                onChange: function (event) {
                    var goals = { all: defaultData };
                    managers.map(function (manager) {
                        goals[manager.id] = defaultData;
                    });
                    form.setFieldsValue(__assign(__assign(__assign({}, goals), getInitialValue()), { policy: event.target.value, enabled: form.getFieldValue('enabled') }));
                    forceUpdate();
                },
                options: [
                    { label: '일괄 설정', value: SalesGoalModel.SALES_GOAL_POLICY.ALL },
                    { label: '직원별 설정', value: SalesGoalModel.SALES_GOAL_POLICY.EACH }
                ]
            }
        },
        {
            element: (React.createElement("div", { style: { display: enabled ? 'block' : 'none' } }, form.getFieldValue(['policy']) === SalesGoalModel.SALES_GOAL_POLICY.ALL
                ? renderFields(SalesGoalModel.SALES_GOAL_POLICY.ALL)
                : managers === null || managers === void 0 ? void 0 : managers.map(function (manager) { return renderFields("".concat(manager.id)); })))
        }
    ].filter(Boolean);
    var updateStore = StoreQuery.useUpdateStore();
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var prevPreference, policy, incentives, all, policy_1, rest, payload, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    prevPreference = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference;
                    policy = values.policy;
                    incentives = { policy: policy };
                    if (policy === SalesGoalModel.SALES_GOAL_POLICY.ALL) {
                        incentives = { enabled: values.enabled, all: values.all };
                    }
                    else {
                        all = values.all, policy_1 = values.policy, rest = __rest(values, ["all", "policy"]);
                        incentives = rest;
                    }
                    payload = {
                        id: storeId,
                        preference: __assign(__assign({}, prevPreference), { incentives: incentives })
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, updateStore.mutateAsync(payload)];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, StoreQuery.refreshMyStoreInCache()];
                case 3:
                    _b.sent();
                    notification.success({ message: '인센티브 설정이 완료되었습니다.' });
                    props.onClose();
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DrawerComponent, { visible: visible, onClose: props.onClose, title: "\uC9C1\uC6D0\uBCC4 \uC778\uC13C\uD2F0\uBE0C \uC124\uC815", children: React.createElement("div", null,
            React.createElement(AntdForm, { fields: fields, formProps: { initialValues: getInitialValue(), form: form, onFinish: handleSubmit, colon: false }, onSubmit: handleSubmit })), footer: React.createElement(DrawerFooter, { confirmText: '저장', onConfirm: form.submit, onCancel: props.onClose }) }));
};
var getDataSource = function (incentives, data, managers) {
    var dataSource = [];
    if (!incentives || !data || !managers) {
        return [];
    }
    var store = StoreQuery.getMyStoreInCache();
    var types = [
        EXTENDED_SALES_METHODS.CARD,
        EXTENDED_SALES_METHODS.TRANSFER,
        EXTENDED_SALES_METHODS.CASH,
        EXTENDED_SALES_METHODS.NAVER_PAY,
        EXTENDED_SALES_METHODS.ETC1,
        EXTENDED_SALES_METHODS.ETC2,
        EXTENDED_SALES_METHODS.ETC3,
        EXTENDED_SALES_METHODS.ETC4,
        EXTENDED_SALES_METHODS.ETC5,
        EXTENDED_SALES_METHODS.UNPAID,
        EXTENDED_SALES_METHODS.PREPAID_POINT,
        EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
        EXTENDED_SALES_METHODS.CUSTOMER_POINT,
        EXTENDED_SALES_METHODS.DISCOUNT
    ];
    managers.map(function (manager, managerIndex) {
        var managerId = manager.id;
        var targetKeys = ['service', 'product', 'ticket', 'refund'];
        var defaultData = function () {
            var result = {
                service: {},
                product: {},
                ticket: {},
                refund: {}
            };
            Object.keys(types).map(function (key) {
                if (store.paymentMethods[key]) {
                    result.service[key] = 0;
                    result.product[key] = 0;
                    result.ticket[key] = 0;
                    result.refund[key] = 0;
                }
            });
        };
        var salesPerManager = data[managerId] || defaultData();
        var label = {
            ticket: '선불액∙정기권',
            service: '상품-시술',
            product: '상품-제품',
            incentive: '인센티브',
            refund: '환불'
        };
        var managerTotal = {
            managerId: managerId,
            typeString: '총계'
        };
        var needRow = 0;
        targetKeys.map(function (targetKey, targetKeyIndex) {
            var _a, _b;
            var row = {
                managerId: manager === null || manager === void 0 ? void 0 : manager.id,
                managerName: manager === null || manager === void 0 ? void 0 : manager.displayName,
                typeString: label[targetKey],
                total: {}
            };
            var rowTotal = 0;
            var rowIncentiveTotal = 0;
            types.map(function (type) {
                var _a, _b;
                var value = 0;
                var incentiveSetting = getIncentiveSetting(incentives, managerId, targetKey, type);
                value = salesPerManager && salesPerManager[targetKey] && salesPerManager[targetKey][type];
                var safeValue = getSafeValue(value);
                var incentiveValue = safeValue * (incentiveSetting / 100);
                rowTotal = rowTotal + safeValue;
                rowIncentiveTotal = rowIncentiveTotal + incentiveValue;
                row[type] = { value: safeValue, incentiveValue: incentiveValue };
                managerTotal[type] = {
                    value: (((_a = managerTotal[type]) === null || _a === void 0 ? void 0 : _a.value) || 0) + safeValue,
                    incentiveValue: (((_b = managerTotal[type]) === null || _b === void 0 ? void 0 : _b.incentiveValue) || 0) + incentiveValue
                };
            });
            row.total = { value: rowTotal, incentiveValue: rowIncentiveTotal };
            managerTotal.total = {
                value: (((_a = managerTotal === null || managerTotal === void 0 ? void 0 : managerTotal.total) === null || _a === void 0 ? void 0 : _a.value) || 0) + rowTotal,
                incentiveValue: (((_b = managerTotal.total) === null || _b === void 0 ? void 0 : _b.incentiveValue) || 0) + rowIncentiveTotal
            };
            if (targetKey === 'service') {
                dataSource.push(row);
            }
            else if (['product', 'ticket', 'refund'].includes(targetKey) && rowTotal !== 0) {
                dataSource.push(row);
                needRow = needRow + 1;
            }
        });
        if (needRow === 1) {
            dataSource[dataSource.length - 2].rowSpan = 3;
            dataSource[dataSource.length - 1].rowSpan = 0;
            dataSource.push(__assign(__assign({}, managerTotal), { isLast: true, rowSpan: 0 }));
        }
        else if (needRow === 2) {
            dataSource[dataSource.length - 3].rowSpan = 4;
            dataSource[dataSource.length - 2].rowSpan = 0;
            dataSource[dataSource.length - 1].rowSpan = 0;
            dataSource.push(__assign(__assign({}, managerTotal), { isLast: true, rowSpan: 0 }));
        }
        else if (needRow === 3) {
            dataSource[dataSource.length - 4].rowSpan = 5;
            dataSource[dataSource.length - 3].rowSpan = 0;
            dataSource[dataSource.length - 2].rowSpan = 0;
            dataSource[dataSource.length - 1].rowSpan = 0;
            dataSource.push(__assign(__assign({}, managerTotal), { isLast: true, rowSpan: 0 }));
        }
        else {
            dataSource[dataSource.length - 1].rowSpan = 1;
            dataSource[dataSource.length - 1].isLast = true;
        }
    });
    var lastRow = { managerName: '총계' };
    dataSource.map(function (row) {
        if (row.typeString !== '총계') {
            __spreadArray(__spreadArray([], types, true), ['total'], false).map(function (type) {
                var _a, _b, _c, _d;
                lastRow[type] = {
                    value: (((_a = lastRow[type]) === null || _a === void 0 ? void 0 : _a.value) || 0) + ((_b = row[type]) === null || _b === void 0 ? void 0 : _b.value) || 0,
                    incentiveValue: (((_c = lastRow[type]) === null || _c === void 0 ? void 0 : _c.incentiveValue) || 0) + ((_d = row[type]) === null || _d === void 0 ? void 0 : _d.incentiveValue) || 0
                };
            });
        }
    });
    dataSource.push(lastRow);
    return dataSource;
};
var getSafeValue = function (value) {
    return !isNaN(value) ? value : 0;
};
var StyledNumberFormatInput = styled(NumberFormatInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 46px;\n"], ["\n  width: 46px;\n"])));
var IncenInput = styled(StyledFormItem)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  & label {\n    width: 120px;\n    font-weight: 400;\n    font-size: 14px;\n  }\n  & input {\n    padding-right: 3px;\n    padding-left: 2px;\n  }\n  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {\n    content: '';\n  }\n"], ["\n  & label {\n    width: 120px;\n    font-weight: 400;\n    font-size: 14px;\n  }\n  & input {\n    padding-right: 3px;\n    padding-left: 2px;\n  }\n  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {\n    content: '';\n  }\n"])));
export var IncentiveNotification = function () {
    return (React.createElement(StyledAlert, { type: "info", showIcon: true, message: "\uC548\uB0B4", description: React.createElement("div", null,
            React.createElement(TextComponent, { color: COLORS.gray2, fontWeight: 400, children: "- \uD45C\uC548\uC758 \uAC80\uC815\uC0C9 \uAE00\uC528\uB85C \uD45C\uAE30\uB41C \uAE08\uC561\uC740 \uD310\uB9E4 \uC561\uC218\uB97C \uB73B\uD558\uBA70, \uADF8 \uC544\uB798\uC758 \uD30C\uB780\uC0C9 \uAE00\uC790\uB294 \uC124\uC815\uC5D0 \uB530\uB77C \uACC4\uC0B0\uB41C \uC778\uC13C\uD2F0\uBE0C \uC561\uC218\uC785\uB2C8\uB2E4." }),
            React.createElement(TextComponent, { color: COLORS.gray2, fontWeight: 400, children: "- \uC778\uC13C\uD2F0\uBE0C \uC124\uC815\uC740 \uC6B0\uCE21 \uC0C1\uB2E8 \uC9C1\uC6D0\uBCC4 \uC778\uC13C\uD2F0\uBE0C \uC124\uC815\uC5D0\uC11C \uAC00\uB2A5\uD569\uB2C8\uB2E4." }),
            React.createElement(TextComponent, { color: COLORS.gray2, fontWeight: 400, children: "- \uACC4\uC0B0\uB41C \uAE08\uC561 1\uC6D0\uB2E8\uC704\uAE4C\uC9C0 \uBCF4\uC5EC\uC9C0\uBA70, \uC18C\uC218\uC810\uB2E8\uC704 \uAE08\uC561\uC740 \uC808\uC0AD\uB429\uB2C8\uB2E4." })) }));
};
var StyledAlert = styled(AlertComponent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
export var FormTitle = function (props) {
    return (React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, children: props.title, marginTop: Lodash.isNumber(props.top) ? props.top : 40, marginBottom: Lodash.isNumber(props.bottom) ? props.bottom : 30 }));
};
export var getIncentiveTableColumns = function (dataSource, store, enabledIncentive) {
    var dataKeys = [
        EXTENDED_SALES_METHODS.CARD,
        EXTENDED_SALES_METHODS.TRANSFER,
        EXTENDED_SALES_METHODS.CASH,
        EXTENDED_SALES_METHODS.NAVER_PAY,
        EXTENDED_SALES_METHODS.ETC1,
        EXTENDED_SALES_METHODS.ETC2,
        EXTENDED_SALES_METHODS.ETC3,
        EXTENDED_SALES_METHODS.ETC4,
        EXTENDED_SALES_METHODS.ETC5,
        EXTENDED_SALES_METHODS.UNPAID,
        'paidPrice',
        EXTENDED_SALES_METHODS.PREPAID_POINT,
        EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
        EXTENDED_SALES_METHODS.CUSTOMER_POINT,
        EXTENDED_SALES_METHODS.DISCOUNT,
        'total'
    ];
    var lastRow = dataSource[dataSource.length - 1];
    var valueableKeys = [];
    if (lastRow) {
        Object.keys(lastRow).map(function (key) {
            if (lastRow[key] && lastRow[key].value) {
                valueableKeys.push(key);
            }
        });
    }
    var filteredKeys = dataKeys.filter(function (item) {
        var _a;
        return ((_a = store === null || store === void 0 ? void 0 : store.paymentMethods[item]) === null || _a === void 0 ? void 0 : _a.active) || valueableKeys.includes(item) || ['paidPrice', 'total'].includes(item);
    });
    var result = filteredKeys.map(function (key) { return ({
        title: key === 'paidPrice'
            ? '실매출액'
            : key === 'total'
                ? '총영업액'
                : SalesHistoryModel.getSalesMethodLabel(key, store),
        dataIndex: key,
        width: 100,
        key: key,
        render: function (value, row) {
            if (key === 'paidPrice') {
                var types = filteredKeys.filter(function (key) { return !['paidPrice', 'total'].includes(key) && SalesHistoryModel.PAID_ELEMENTS.includes(key); });
                var value_1 = sum(types.map(function (type) { var _a; return ((_a = row[type]) === null || _a === void 0 ? void 0 : _a.value) || 0; }));
                var incentiveValue = sum(types.map(function (type) { var _a; return ((_a = row[type]) === null || _a === void 0 ? void 0 : _a.incentiveValue) || 0; }));
                return renderTotalWithIncen({ incentiveValue: incentiveValue, value: value_1 }, enabledIncentive);
            }
            else {
                return renderTotalWithIncen(value, enabledIncentive);
            }
        }
    }); });
    return result;
};
var templateObject_1, templateObject_2, templateObject_3;
