// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1DedC0Va15KlZ-VkOaQ_SN {\n  background-color: var(--primary-5);\n}\n\n._2yk8R_1DIO7fDlVo1GPaqY > td {\n    border-bottom: 1px solid var(--gray-3) !important;\n  }\n", ""]);
// Exports
exports.locals = {
	"totalRow": "_1DedC0Va15KlZ-VkOaQ_SN",
	"tableLastRow": "_2yk8R_1DIO7fDlVo1GPaqY"
};
module.exports = exports;
