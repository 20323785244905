import React from 'react';
import { AppLayout, EntireLoader } from 'renderer/components';
import { PATH_APP, PATH_SIGNIN, PATH_FRANCHISE_ADMIN_STORES, PATH_FRANCHISE_ADMIN_INVOICES } from 'renderer/constants';
import { Navigate, Outlet } from 'react-router';
import { STRING_KEY } from 'renderer/locale';
import { ManagerQuery } from 'renderer/queries';
var FranchiseHome = function () {
    var _a = ManagerQuery.useGetMe({ retry: false }), isLoading = _a.isLoading, isError = _a.isError, data = _a.data, error = _a.error;
    if (isLoading) {
        return React.createElement(EntireLoader, { open: true });
    }
    if (isError) {
        return React.createElement(Navigate, { to: PATH_SIGNIN });
    }
    var manager = data === null || data === void 0 ? void 0 : data.manager;
    var franchiseId = manager.franchiseId;
    return !manager.isFranchiseAdmin ? (React.createElement(Navigate, { to: PATH_APP })) : (React.createElement(AppLayout, { franchiseId: franchiseId, manager: manager, menus: [
            {
                id: 'SUPER_ADMIN_SIDE_MENU_DASHBOARD',
                path: PATH_FRANCHISE_ADMIN_STORES,
                label: STRING_KEY.FRANCHISE_ADMIN_SIDE_MENU_LIST,
                icon: 'file-text'
            },
            {
                id: 'SUPER_ADMIN_SIDE_MENU_STORE',
                path: PATH_FRANCHISE_ADMIN_INVOICES,
                label: STRING_KEY.FRANCHISE_ADMIN_SIDE_MENU_INVOICES,
                icon: 'file-text'
            }
        ], 
        // isAdmin
        body: React.createElement(Outlet, null) }));
};
export default FranchiseHome;
