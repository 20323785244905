var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import * as Styles from './styles.css';
import * as dayjs from 'dayjs';
import clsx from 'clsx';
import { TextComponent, CALENDER_GRID_TYPE, CheckboxComponent, CollapsibleCardComponent, ReservationStatusChanger, useEntireLoader } from 'renderer/components';
import Row from 'antd/lib/row';
import { ManagerQuery, ScheduleQuery, StoreQuery } from 'renderer/queries';
import { Lodash, omit, uniq, every, getSearchParams, RestClient } from 'renderer/utils';
import { ScheduleModel } from 'renderer/models';
import { IMAGES } from 'renderer/assets';
import { LOCAL_STORAGE_KEY_MANAGER_FILTER_CARD } from 'renderer/constants';
import { COLORS } from 'renderer/styles';
import { useRecoilValue } from 'recoil';
import { Atoms, useScheduleModal } from 'renderer/stores';
import { notification } from 'antd/lib';
import { useLocation } from 'react-router';
import loadable from '@loadable/component';
var MobileCalendarContainer = loadable(function () { return import('./mobile/MobileCalendarContainer'); });
var CalendarContainer = loadable(function () { return import('./PCCalendar'); });
export var ReservationCalendar = function (props) {
    var add = useScheduleModal().add;
    var _a = React.useState(new Date()), currentDate = _a[0], setCurrentDate = _a[1];
    var _b = React.useState(getCalendarRange(currentDate)), searchParams = _b[0], setSearchParams = _b[1];
    var entireLoader = useEntireLoader();
    var calendarDisplay = useRecoilValue(Atoms.calendarDisplaySelector);
    var location = useLocation();
    var store = StoreQuery.useStore().data;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    React.useEffect(function () {
        if (location === null || location === void 0 ? void 0 : location.search) {
            var params_1 = getSearchParams(location === null || location === void 0 ? void 0 : location.search);
            var id = params_1.get('id');
            if (id) {
                entireLoader.show();
                RestClient.getInstance()
                    .get("/stores/".concat(storeId, "/schedules/").concat(id))
                    .then(function (result) {
                    var _a;
                    if (result.data) {
                        add({ id: (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.id, type: 'details', schedule: new ScheduleModel(result.data) });
                    }
                    else {
                        notification.error({ message: '잘못된 접근입니다.' });
                    }
                })
                    .catch(function (err) {
                    notification.error({ message: '잘못된 접근입니다.' });
                })
                    .finally(function () {
                    entireLoader.hide();
                });
            }
        }
    }, [location]);
    // 끝
    ManagerQuery.useMe(storeId, { enabled: !!storeId });
    var onlyWorkingManager = ManagerQuery.getWorkingManagersInCache();
    var params = calendarDisplay.view === CALENDER_GRID_TYPE.TABLE
        ? omit(__assign(__assign({}, searchParams), { storeId: storeId }), ['keyword'])
        : {
            startedAt: searchParams.startedAt,
            endedAt: searchParams.endedAt,
            storeId: storeId
        };
    var scheduleQuery = ScheduleQuery.useSchedules(params, {
        enabled: !!storeId,
        refetchOnWindowFocus: true
    });
    ScheduleQuery.useGetSalesStat(params, {
        enabled: !!(store === null || store === void 0 ? void 0 : store.id)
    });
    var yyyy = dayjs(currentDate).format('YYYY');
    ScheduleQuery.useHoliday(yyyy);
    var getSchedules = React.useMemo(function () {
        var _a;
        if (!((_a = scheduleQuery === null || scheduleQuery === void 0 ? void 0 : scheduleQuery.data) === null || _a === void 0 ? void 0 : _a.length) || scheduleQuery.isLoading) {
            return {};
        }
        var hasSubscription = store.hasSubscription;
        var keyword = searchParams.keyword;
        var _b = getParamsWithViewMode(calendarDisplay.view, currentDate), startedAt = _b.startedAt, endedAt = _b.endedAt;
        var days = dayjs(endedAt).diff(dayjs(startedAt), 'day') + 1;
        var filterData = __assign(__assign({}, calendarDisplay), { keyword: keyword });
        var schedules = [];
        var repeatSchedules = [];
        scheduleQuery.data.forEach(function (item) {
            var _a;
            if (!hasSubscription && item.from === 'naver') {
                return;
            }
            (((_a = item.dateOption) === null || _a === void 0 ? void 0 : _a.repeat) ? repeatSchedules : schedules).push(item);
        });
        var allSchedules = schedules.slice(); // Avoid mutating the original schedules array
        var _loop_1 = function (i) {
            var targetDay = dayjs(startedAt).add(i, 'day');
            repeatSchedules.forEach(function (item) {
                var newSchedule = ScheduleModel.createRepeatScheduleByTargetDay(targetDay.toDate(), item);
                if (newSchedule) {
                    allSchedules.push(newSchedule);
                }
            });
        };
        for (var i = 0; i < days; i++) {
            _loop_1(i);
        }
        var _c = allSchedules.reduce(function (acc, schedule) {
            if (!schedule.isFiltered(filterData)) {
                acc[schedule.type === ScheduleModel.RESERVATION_TYPE.OFF ? 0 : 1].push(schedule);
            }
            return acc;
        }, [[], []]), filteredOffList = _c[0], filteredSchedules = _c[1];
        return {
            schedules: Lodash.orderBy(filteredSchedules, ['type', 'dateOption.startedAt', 'managerId'], ['asc']),
            offList: Lodash.orderBy(filteredOffList, ['type', 'dateOption.startedAt', 'managerId'], ['asc'])
        };
    }, [calendarDisplay, currentDate, searchParams, scheduleQuery.data]);
    var handleCurrentDateChange = React.useCallback(function (date) {
        var newParams = __assign(__assign({}, searchParams), { startedAt: dayjs(date).startOf('month').subtract(2, 'week').toDate(), endedAt: dayjs(date).endOf('month').add(2, 'week').toDate() });
        setCurrentDate(date);
        setSearchParams(newParams);
    }, [setCurrentDate, setSearchParams, searchParams]);
    var handleSearchParamChange = React.useCallback(function (params) {
        var newParams = __assign(__assign({}, searchParams), params);
        setSearchParams(newParams);
    }, [setSearchParams, searchParams]);
    // FIXME: 임시방편
    React.useEffect(function () {
        var now = dayjs();
        if (calendarDisplay.view !== CALENDER_GRID_TYPE.TABLE) {
            handleSearchParamChange({
                startedAt: dayjs(currentDate).startOf('month').subtract(2, 'week').toDate(),
                endedAt: dayjs(currentDate).endOf('month').add(2, 'week').toDate()
            });
        }
        else {
            handleSearchParamChange({
                startedAt: now.startOf('day').toDate(),
                endedAt: now.add(1, 'month').endOf('day').toDate()
            });
        }
    }, [calendarDisplay.view]);
    var naverStatusChangerValue = useRecoilValue(Atoms.reservationStatusChangerModalState);
    var createdSchedule = getSchedules;
    return (React.createElement(React.Fragment, null,
        React.createElement(React.Fragment, null,
            props.isPC ? (React.createElement(CalendarContainer, { store: store, currentDate: currentDate, onCurrentDateChange: handleCurrentDateChange, schedules: (createdSchedule === null || createdSchedule === void 0 ? void 0 : createdSchedule.schedules) || [], offList: (createdSchedule === null || createdSchedule === void 0 ? void 0 : createdSchedule.offList) || [], managers: onlyWorkingManager, searchParams: searchParams, onSearchParamChange: handleSearchParamChange })) : (React.createElement(MobileCalendarContainer, { store: store, currentDate: currentDate, onCurrentDateChange: handleCurrentDateChange, schedules: (createdSchedule === null || createdSchedule === void 0 ? void 0 : createdSchedule.schedules) || [], offList: (createdSchedule === null || createdSchedule === void 0 ? void 0 : createdSchedule.offList) || [], managers: onlyWorkingManager, searchParams: searchParams, onSearchParamChange: handleSearchParamChange, updatedAt: scheduleQuery.dataUpdatedAt })),
            naverStatusChangerValue && (React.createElement(ReservationStatusChanger, { store: store, type: naverStatusChangerValue === null || naverStatusChangerValue === void 0 ? void 0 : naverStatusChangerValue.type, status: naverStatusChangerValue === null || naverStatusChangerValue === void 0 ? void 0 : naverStatusChangerValue.status, schedule: naverStatusChangerValue === null || naverStatusChangerValue === void 0 ? void 0 : naverStatusChangerValue.schedule })))));
};
export var ScheduleSettingButtons = function (props) {
    var menus = [
        { title: '상점 영업시간 설정', src: IMAGES.fill_time, className: Styles.settngStoreTime, key: 'store' },
        { title: '직원 업무시간 설정', src: IMAGES.fill_time, className: Styles.settingEmployeeTime, key: 'employee' }
    ];
    return (React.createElement(CollapsibleCardComponent, { className: Styles.settingButtonWrapper }, menus.map(function (menu) {
        return (React.createElement(Row, { key: menu.key, className: clsx(Styles.pointer, Styles.row), align: "middle", onClick: function () { return props.onClick(menu.key); } },
            React.createElement("div", { className: clsx(Styles.icon, menu.className) },
                React.createElement("img", { src: menu.src })),
            React.createElement(TextComponent, { type: "body1" }, menu.title)));
    })));
};
export var ManagerFilter = function (props) {
    var handleChange = function (managerId, checked) {
        if (checked) {
            return props.onChange(props.shownManagerIds.concat(managerId));
        }
        else {
            return props.onChange(props.shownManagerIds.filter(function (id) { return id !== managerId; }));
        }
    };
    var title = '담당자';
    var children = props.managers
        .concat({ id: -1, profile: { name: '담당자없음', color: COLORS.staff1 } })
        .map(function (manager, index) {
        var _a, _b;
        var isChecked = props.shownManagerIds.includes(manager.id);
        return (React.createElement(Row, { key: "".concat(manager.id, "_").concat(index), className: clsx(Styles.pointer, Styles.row) },
            React.createElement(CheckboxComponent, { onChange: function (event) {
                    handleChange(manager.id, event.target.checked);
                }, backgroundColor: (_a = manager === null || manager === void 0 ? void 0 : manager.profile) === null || _a === void 0 ? void 0 : _a.color, children: (_b = manager === null || manager === void 0 ? void 0 : manager.profile) === null || _b === void 0 ? void 0 : _b.name, checked: isChecked })));
    });
    return props.isMobile ? (React.createElement("div", null,
        React.createElement(TextComponent, { marginBottom: 20, type: "subtitle3", color: COLORS.gray4, children: title }),
        children)) : (React.createElement(CollapsibleCardComponent, { className: Styles.managerFiler, title: title, isCollapisble: true, localStorageKey: LOCAL_STORAGE_KEY_MANAGER_FILTER_CARD }, children));
};
export var MobileCalendarFilter = function (props) {
    var title = props.title, filters = props.filters;
    var renderItem = function (filter, isSub) {
        var _a;
        var isChecked = (_a = props.data) === null || _a === void 0 ? void 0 : _a.includes(filter.value);
        return (React.createElement(Row, { key: filter.value, style: isSub ? { paddingLeft: '10px' } : {} },
            React.createElement(CheckboxComponent, { onChange: function (event) {
                    var hasGroup = filter.items;
                    // const isAll = hasGroup &&
                    var newData = event.target.checked
                        ? uniq(props.data || []).concat(hasGroup ? __spreadArray([filter.value], filter.items.map(function (item) { return item.value; }), true) : filter.value)
                        : uniq((props.data || []).filter(function (item) {
                            return hasGroup
                                ? item !== filter.value && !filter.items.map(function (item) { return item.value; }).includes(item)
                                : item !== filter.value;
                        }));
                    filters.map(function (filter) {
                        var isAll = filter.items && every(filter.items.map(function (item) { return newData.includes(item.value); }));
                        if (filter.items) {
                            if (isAll) {
                                newData = uniq(newData.concat(filter.value));
                            }
                            else {
                                newData = uniq(newData.filter(function (item) { return filter.value !== item; }));
                            }
                        }
                    });
                    props.onChange(newData);
                }, children: filter.icon ? (React.createElement(React.Fragment, null,
                    React.createElement("img", { src: filter.icon, style: { marginRight: '5px' } }),
                    filter.label)) : (filter.label), checked: isChecked })));
    };
    return (React.createElement("div", null,
        React.createElement(TextComponent, { marginBottom: 10, type: "subtitle3", color: COLORS.gray4, children: title }),
        filters.map(function (filter) {
            var _a;
            return (React.createElement(React.Fragment, null,
                renderItem(filter), (_a = filter.items) === null || _a === void 0 ? void 0 :
                _a.map(function (item) { return renderItem(item, true); })));
        })));
};
var getCalendarRange = function (currentDate) {
    var startOfMonth = dayjs(currentDate).startOf('month');
    var endOfMonth = dayjs(currentDate).add(1, 'month').endOf('month');
    var startedAt = startOfMonth.startOf('week').toDate(); // 월의 첫 번째 일요일
    var endedAt = endOfMonth.endOf('week').toDate(); // 월의 마지막 토요일
    return { startedAt: startedAt, endedAt: endedAt };
};
var getParamsWithViewMode = function (calendarMode, currentDate) {
    if (calendarMode === CALENDER_GRID_TYPE.MONTH) {
        var _a = getCalendarRange(currentDate), startedAt = _a.startedAt, endedAt = _a.endedAt;
        return {
            startedAt: dayjs(startedAt).toDate(),
            endedAt: dayjs(endedAt).toDate()
        };
    }
    else if (calendarMode === CALENDER_GRID_TYPE.WEEK) {
        return {
            startedAt: dayjs(currentDate).startOf('week').toDate(),
            endedAt: dayjs(currentDate).endOf('week').toDate()
        };
    }
    else if (calendarMode === CALENDER_GRID_TYPE.DAY) {
        return {
            startedAt: dayjs(currentDate).startOf('day').toDate(),
            endedAt: dayjs(currentDate).endOf('day').toDate()
        };
    }
    else if (calendarMode === CALENDER_GRID_TYPE.TABLE) {
        return {
            startedAt: dayjs(currentDate).startOf('week').toDate(),
            endedAt: dayjs(currentDate).endOf('week').toDate()
        };
    }
};
