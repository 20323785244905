var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { EntireLoader, Link, TextComponent, LogoImage, StyledDivider, Marginer, ProfileItem, useAccessToken, useEntireLoader } from 'renderer/components';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ID_LENGTH_MIN, ID_LENGTH_MAX, PW_LENGTH_MAX, PW_LENGTH_MIN, PATH_SIGNUP, PATH_SIGNIN_FORGOT_PASSWORD, PATH_SIGNIN_FORGOT_ID, REMOTE_SUPPORT_URL, REGEX_ID, PATH_AUTH, VBAF1231AAA, getLogoImage, SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN, APP_VERSION, BUILD_NUMBER, LOCAL_STORAGE_KEY_UNCHECKED_AUTO_LOGIN } from 'renderer/constants';
import { LocalStorage, SessionStorage, getSearchParams } from 'renderer/utils';
import { AntdForm } from 'renderer/components';
import Col from 'antd/lib/col';
import Layout from 'antd/lib/layout';
import Row from 'antd/lib/row';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { ManagerModel } from 'renderer/models';
import notification from 'antd/lib/notification';
import { QUERY_CACHE, ManagerQuery } from 'renderer/queries';
var styles = {
    paper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    form: {
        width: '100%',
        marginTop: '16px'
    }
};
var Signin = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var updateAccessToken = useAccessToken().updateAccessToken;
    var loader = useEntireLoader();
    React.useEffect(function () {
        // FIXME: 관리자 페이지 새로고침 시 문제발생
        var key = getSearchParams(location.search).get(VBAF1231AAA);
        if (key) {
            updateAccessToken(key);
            SessionStorage.set(SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN, true);
            loader.show();
            setTimeout(function () {
                loader.hide();
                navigate(PATH_AUTH);
            }, 500);
        }
    }, []);
    return (React.createElement(StyledLayout, null,
        React.createElement(LogoHeader, null),
        React.createElement(Layout.Content, { style: { height: '100%', padding: '20px', display: 'flex', alignItems: 'center' } },
            React.createElement(Outlet, null))));
};
export var SignupForm = function () {
    var unchecked = LocalStorage.get(LOCAL_STORAGE_KEY_UNCHECKED_AUTO_LOGIN);
    var signin = ManagerQuery.useSignin();
    var _a = React.useState(false), isPending = _a[0], setIsPending = _a[1];
    // TODO: autoLogin 처리, getMe에서 토큰 최신화!
    var _b = useAccessToken(), updateToken = _b.updateToken, signOut = _b.signOut;
    var navigate = useNavigate();
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var result, manager, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsPending(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    QUERY_CACHE.clear();
                    return [4 /*yield*/, signin.mutateAsync(data)];
                case 2:
                    result = _b.sent();
                    manager = result.manager;
                    updateToken(result.accessToken, result.refreshToken, data.autoLogin ? 'local' : 'session');
                    if (((_a = manager.meta) === null || _a === void 0 ? void 0 : _a.status) === ManagerModel.MANAGER_STATUS.WORKING) {
                        navigate(PATH_AUTH, { replace: true });
                    }
                    else {
                        signOut(true);
                        notification.error({ message: '퇴사한 직원은 로그인할 수 없습니다.' });
                        setIsPending(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    notification.error({ message: error_1.message });
                    setIsPending(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Row, { style: styles.paper },
        React.createElement(EntireLoader, { open: isPending }),
        React.createElement(Row, { justify: "center", style: { width: '350px' } },
            React.createElement(Col, { span: 24 },
                React.createElement(Row, { justify: "center" },
                    React.createElement(TextComponent, { children: "\uB85C\uADF8\uC778", type: "headline1", marginBottom: 50 })),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 24 },
                        React.createElement(StyledForm, { submitButton: "\uB85C\uADF8\uC778", formProps: {
                                layout: 'vertical',
                                onFinish: onSubmit,
                                initialValues: {
                                    autoLogin: unchecked !== 'true'
                                },
                                wrapperCol: { span: 24 }
                            }, onSubmit: onSubmit, fields: [
                                {
                                    type: 'text',
                                    itemProps: {
                                        name: 'loginId',
                                        label: '아이디',
                                        rules: [
                                            {
                                                type: 'string',
                                                min: ID_LENGTH_MIN,
                                                max: ID_LENGTH_MAX,
                                                pattern: REGEX_ID,
                                                required: true
                                            }
                                        ]
                                    },
                                    inputProps: { placeholder: 'ID', autoFocus: true }
                                },
                                {
                                    itemProps: {
                                        name: 'password',
                                        label: '비밀번호',
                                        rules: [{ type: 'string', min: PW_LENGTH_MIN, max: PW_LENGTH_MAX, required: true }]
                                    },
                                    type: 'password',
                                    inputProps: {
                                        placeholder: '비밀번호'
                                    }
                                },
                                {
                                    itemProps: {
                                        name: 'autoLogin',
                                        valuePropName: 'checked',
                                        rules: [{ type: 'boolean', required: true }]
                                    },
                                    type: 'checkbox',
                                    inputProps: {
                                        children: '자동 로그인',
                                        name: 'autoLogin',
                                        onChange: function (ev) {
                                            LocalStorage.set(LOCAL_STORAGE_KEY_UNCHECKED_AUTO_LOGIN, !ev.target.checked ? 'true' : 'false');
                                        }
                                    }
                                }
                            ] }))),
                React.createElement(Row, { justify: "space-between", style: { marginTop: '20px', marginBottom: '20px' } },
                    React.createElement(Col, { flex: 1 },
                        React.createElement(Row, { justify: "center" },
                            React.createElement(Link, { to: { pathname: PATH_SIGNIN_FORGOT_ID } }, "\uC544\uC774\uB514 \uCC3E\uAE30"),
                            "\u2219",
                            React.createElement(Link, { to: { pathname: PATH_SIGNIN_FORGOT_PASSWORD } }, "\uBE44\uBC00\uBC88\uD638 \uCC3E\uAE30"))),
                    React.createElement(Col, null,
                        React.createElement(StyledDivider, { type: "vertical" })),
                    React.createElement(Col, { flex: 1 },
                        React.createElement(Row, { justify: "center" },
                            React.createElement(Link, { to: PATH_SIGNUP }, "\uD68C\uC6D0\uAC00\uC785 \uD558\uAE30")))))),
        React.createElement(Marginer, { height: 50 }),
        React.createElement(Row, { align: "middle", gutter: 16 },
            React.createElement(Col, null,
                React.createElement(TextComponent, { children: "\uB3C4\uC6C0\uC774 \uD544\uC694\uD558\uC2E0\uAC00\uC694?", color: COLORS.gray3 })),
            React.createElement(Col, null,
                React.createElement(GrayLink, { to: REMOTE_SUPPORT_URL }, "\uC6D0\uACA9\uC9C0\uC6D0 \uD558\uAE30"))),
        React.createElement(Row, { style: { margin: '5px', width: '100%', position: 'absolute', bottom: '10px', right: '20px' }, justify: "end" },
            React.createElement(TextComponent, { children: "V.".concat(APP_VERSION, " (").concat(BUILD_NUMBER, ")"), type: "subtitle3" }))));
};
export var GrayLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > div {\n    color: ", " !important;\n    text-decoration: underline !important;\n    font-size: 14px;\n    font-weight: 500 !important;\n  }\n"], ["\n  & > div {\n    color: ", " !important;\n    text-decoration: underline !important;\n    font-size: 14px;\n    font-weight: 500 !important;\n  }\n"])), COLORS.gray1);
export var LogoHeader = function (props) {
    return (React.createElement(HeaderRow, { children: React.createElement(Row, { align: "middle", justify: "space-between" },
            React.createElement("a", { href: "https://thinkofyou.kr" },
                React.createElement(LogoImage, { src: getLogoImage() })),
            props.manager && React.createElement(ProfileItem, { manager: props.manager, circleButton: true })) }));
};
export var HeaderRow = styled(Layout.Header)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 62px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white) !important;\n  padding: 0px;\n\n  & .ant-row {\n    width: 100%;\n    height: 100%;\n    padding-left: 28px;\n  }\n"], ["\n  width: 100%;\n  height: 62px;\n  border: solid 1px var(--gray-6);\n  background-color: var(--white) !important;\n  padding: 0px;\n\n  & .ant-row {\n    width: 100%;\n    height: 100%;\n    padding-left: 28px;\n  }\n"])));
export var StyledLayout = styled(Layout)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", " !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  max-height: 100vh;\n"], ["\n  background: ", " !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  max-height: 100vh;\n"])), COLORS.white);
export var StyledForm = styled(AntdForm)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  & .ant-form-item-label {\n    padding: 0 0 5px;\n  }\n\n  & label {\n    color: ", ";\n  }\n\n  & .detailAddress {\n    margin-top: -10px;\n    & > ant-form-item-label {\n      display: none;\n    }\n    & .ant-form-item-no-colon {\n      display: none;\n    }\n  }\n"], ["\n  width: 100%;\n  & .ant-form-item-label {\n    padding: 0 0 5px;\n  }\n\n  & label {\n    color: ", ";\n  }\n\n  & .detailAddress {\n    margin-top: -10px;\n    & > ant-form-item-label {\n      display: none;\n    }\n    & .ant-form-item-no-colon {\n      display: none;\n    }\n  }\n"])), COLORS.gray1);
export default Signin;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
