var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Col from 'antd/lib/col';
import Row from 'antd/lib/grid/row';
import React from 'react';
import { Icon } from 'renderer/components';
import { COLORS, MediaQuery } from 'renderer/styles';
import styled from 'styled-components';
export var WrapperRow = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n"])));
export var DrawerLeft = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  & > div {\n    width: 100%;\n    max-width: 650px;\n  }\n  flex: 1;\n  padding-top: 36px;\n  overflow-y: overlay;\n"], ["\n  display: flex;\n  justify-content: center;\n  & > div {\n    width: 100%;\n    max-width: 650px;\n  }\n  flex: 1;\n  padding-top: 36px;\n  overflow-y: overlay;\n"])));
export var DrawerRight = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  min-width: 360px;\n  display: flex;\n  flex-direction: column;\n\n  width: 360px;\n  ", "\n\n  border-left: 1px solid ", ";\n  & > .customerSearcher {\n    padding: 20px;\n    border-bottom: 1px solid ", ";\n  }\n\n  & > .payment {\n    padding: 20px;\n    flex: 1;\n    border-bottom: 1px solid ", ";\n    overflow: overlay;\n  }\n  & > .footer {\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 15px;\n    padding-right: 15px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n"], ["\n  min-width: 360px;\n  display: flex;\n  flex-direction: column;\n\n  width: 360px;\n  ", "\n\n  border-left: 1px solid ", ";\n  & > .customerSearcher {\n    padding: 20px;\n    border-bottom: 1px solid ", ";\n  }\n\n  & > .payment {\n    padding: 20px;\n    flex: 1;\n    border-bottom: 1px solid ", ";\n    overflow: overlay;\n  }\n  & > .footer {\n    display: flex;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 15px;\n    padding-right: 15px;\n    & > button {\n      flex: 1;\n      &:last-child {\n        margin-left: 4px;\n      }\n      &:first-child {\n        margin-right: 4px;\n      }\n    }\n  }\n"])), MediaQuery.tabletWithMobile(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"]))), COLORS.gray6, COLORS.gray6, COLORS.gray6);
export var Inner = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: space-between;\n  max-width: 640px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 10px;\n  padding: ", "px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: space-between;\n  max-width: 640px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 10px;\n  padding: ", "px;\n"])), function (props) { return props.padding || 0; });
export var RightTop = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n  overflow: scroll;\n"], ["\n  flex: 1;\n  overflow: scroll;\n"])));
export var FieldWithIcon = function (props) {
    return (React.createElement(Row, { align: "top", style: { paddingTop: '2px', marginBottom: props.noMarginBottom ? '3px' : '13px' }, wrap: false, gutter: 4 },
        React.createElement(Col, { style: { marginRight: '10px' } },
            React.createElement(Icon, { name: props.icon || 'x', color: props.icon ? COLORS.gray2 : COLORS.white, size: 20 })),
        React.createElement(Col, { style: { display: 'flex', alignItems: 'start', flexDirection: 'column', height: '100%' }, flex: props.fluid ? 1 : 0 }, props.field)));
};
export var ScheduleDrawerFooterButtons = function (props) {
    return (React.createElement(StyledRow, { justify: "center", gutter: 8 },
        React.createElement(StyledCol, { span: 12 }, props.left),
        React.createElement(StyledCol, { span: 12 }, props.right)));
};
var StyledRow = styled(Row)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  padding: 20px;\n  border-top: 1px solid ", ";\n"], ["\n  width: 100%;\n  padding: 20px;\n  border-top: 1px solid ", ";\n"])), COLORS.gray6);
var StyledCol = styled(Col)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  & > button {\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  & > button {\n    flex: 1;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
