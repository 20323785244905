var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { BodyTemplate, TextComponent, BizNumberNormalizer, CardWithTabs, GUIDE_NAMES } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import { StorePaymentMethodQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { BizNumberValidator } from 'renderer/constants';
import { Lodash } from 'renderer/utils';
import Modal from 'antd/lib/modal';
import StorePointCenter from './StorePointCenter';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import { NewStoreSubscription } from './StoreSubscription';
import { useLocation } from 'react-router-dom';
import StorePaymentMethod from './PaymentMethod';
var StorePayments = function (props) {
    var _a;
    var search = useLocation().search;
    var queryString = new URLSearchParams(search);
    var type = queryString.get('tab') || 'subscription';
    var _b = React.useState(queryString.get('tab') || 'subscription'), acitveTab = _b[0], setActiveTab = _b[1];
    React.useEffect(function () {
        if (type !== acitveTab) {
            setActiveTab(type);
        }
    }, [type]);
    var storeId = (_a = props.store) === null || _a === void 0 ? void 0 : _a.id;
    var query = StorePaymentMethodQuery.usePaymentMethods(storeId, { enabled: !!storeId });
    return (React.createElement(BodyTemplate, { title: "\uAD6C\uB3C5 \uBC0F \uACB0\uC81C", guideName: GUIDE_NAMES['서비스요금제관리'], content: React.createElement(React.Fragment, null,
            React.createElement(CardWithTabs, { tabList: [
                    { key: 'subscription', tab: '구독 관리' },
                    { key: 'point', tab: '포인트 관리' },
                    { key: 'paymentMethod', tab: '결제수단관리' }
                ], activeTabKey: acitveTab, children: {
                    subscription: React.createElement(NewStoreSubscription, __assign({}, props)),
                    point: React.createElement(StorePointCenter, { store: props.store, permission: props.permission }),
                    paymentMethod: React.createElement(StorePaymentMethod, { store: props.store })
                }, onTabChange: function (tab) {
                    setActiveTab(tab);
                } })) }));
};
export var checkBizNoAndUpdate = function (store, form, updateStore, message) { return __awaiter(void 0, void 0, void 0, function () {
    var hasBizNo, storeId;
    return __generator(this, function (_a) {
        hasBizNo = !!store.businessLicenseNumber;
        storeId = store.id;
        if (hasBizNo) {
            return [2 /*return*/, true];
        }
        return [2 /*return*/, new Promise(function (resolve, reject) {
                Modal.confirm({
                    zIndex: 100000,
                    centered: true,
                    icon: null,
                    title: React.createElement(TextComponent, { children: "\uC0AC\uC5C5\uC790 \uB4F1\uB85D\uBC88\uD638 \uB4F1\uB85D", type: "headline2" }),
                    cancelButtonProps: {
                        ghost: true,
                        type: 'text',
                    },
                    onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var errors, businessLicenseNumber, error_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: 
                                // throw new Error('avasdv');
                                return [4 /*yield*/, form.validateFields()];
                                case 1:
                                    // throw new Error('avasdv');
                                    _a.sent();
                                    errors = form.getFieldError(['businessLicenseNumber']);
                                    businessLicenseNumber = form.getFieldValue(['businessLicenseNumber']);
                                    _a.label = 2;
                                case 2:
                                    _a.trys.push([2, 5, , 6]);
                                    if (!(Lodash.isEmpty(errors) && businessLicenseNumber)) return [3 /*break*/, 4];
                                    return [4 /*yield*/, updateStore.mutateAsync({ id: storeId, businessLicenseNumber: businessLicenseNumber })];
                                case 3:
                                    _a.sent();
                                    form.resetFields();
                                    return [2 /*return*/, resolve(true)];
                                case 4: return [3 /*break*/, 6];
                                case 5:
                                    error_1 = _a.sent();
                                    notification.error({ message: error_1.message });
                                    return [3 /*break*/, 6];
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); },
                    onCancel: function () {
                        form.resetFields();
                        return resolve(false);
                    },
                    content: (React.createElement("div", null,
                        React.createElement(TextComponent, { type: "16m", color: COLORS.gray1, children: message || '유료체험을 이용하시려면 사업자등록번호 등록이 필요합니다' }),
                        React.createElement(TextComponent, { type: "16m", color: COLORS.gray1, children: "\uC0AC\uC5C5\uC790 \uB4F1\uB85D\uBC88\uD638\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.", marginBottom: 24 }),
                        React.createElement(Form, { form: form },
                            React.createElement(Form.Item, { name: "businessLicenseNumber", normalize: BizNumberNormalizer, rules: [
                                    { required: true, message: '사업자 등록번호를 입력해주세요.' },
                                    { validator: function (_, value) { return BizNumberValidator(value); } }
                                ] },
                                React.createElement(Input, { placeholder: "\uC608\uC2DC) 000-00-00000" }))),
                        React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, children: "\uC0AC\uC5C5\uC790\uBC88\uD638\uAC00 \uC5C6\uC73C\uC2E0\uAC00\uC694? \uD558\uB2E8 \uCC44\uD305\uC0C1\uB2F4\uC744 \uC694\uCCAD\uD574\uC8FC\uC138\uC694! \uAD00\uB9AC\uC790\uAC00 \uD655\uC778 \uD6C4 \uCC98\uB9AC\uB97C \uB3C4\uC640\uB4DC\uB9AC\uACA0\uC2B5\uB2C8\uB2E4 \uD83D\uDC4D" })))
                });
            })];
    });
}); };
export default StorePayments;
