import React from 'react';
import { BodyTemplate } from 'renderer/components';
import { TableComponent } from '../../components/antd/Table';
import { FranchisesQuery, ManagerQuery } from 'renderer/queries';
import dayjs from 'dayjs';
export var FranchiseStores = function (props) {
    return React.createElement(BodyTemplate, { title: "\uAC00\uB9F9\uC810 \uBAA9\uB85D", content: React.createElement(FranchiseStoreTable, null) });
};
var FranchiseStoreTable = function (props) {
    var _a, _b;
    var result = ManagerQuery.getMeWithoutStoreIdInCache();
    var _c = FranchisesQuery.useStores({
        page: 1,
        limit: 10,
        franchiseId: (_a = result === null || result === void 0 ? void 0 : result.manager) === null || _a === void 0 ? void 0 : _a.franchiseId
    }, {
        enabled: !!((_b = result === null || result === void 0 ? void 0 : result.manager) === null || _b === void 0 ? void 0 : _b.franchiseId)
    }), pagerResult = _c.pagerResult, isLoading = _c.isLoading, getPaginateObject = _c.getPaginateObject;
    var _d = React.useState(), store = _d[0], setStore = _d[1];
    var columns = [
        {
            id: 'name',
            title: '상점명',
            render: function (_, originalRow) {
                return "".concat(originalRow.name);
            }
        },
        {
            id: 'ceoName',
            title: '대표명',
            render: function (_, originalRow) {
                return "".concat(originalRow.ceoName);
            }
        },
        {
            id: 'managerLoginId',
            title: 'ID',
            render: function (_, originalRow) {
                var _a;
                return (_a = originalRow.managers[0]) === null || _a === void 0 ? void 0 : _a.loginId;
            }
        },
        {
            id: 'tel',
            title: '전화번호',
            render: function (_, originalRow) {
                return "".concat(originalRow.phone);
            }
        },
        {
            id: 'address.address',
            title: '주소',
            render: function (_, originalRow) {
                var _a;
                return (_a = originalRow.address) === null || _a === void 0 ? void 0 : _a.address;
            }
        },
        {
            id: 'createdAt',
            title: '가입일',
            render: function (_, originalRow) {
                return dayjs(originalRow.createdAt).format('YYYY-MM-DD');
            }
        }
    ];
    return (React.createElement(TableComponent, { onRow: function (data) {
            return {
                onClick: function () { return setStore(data); }
            };
        }, loading: isLoading, columns: columns, pagination: getPaginateObject(), dataSource: pagerResult.items }));
};
