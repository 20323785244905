var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import * as dayjs from 'dayjs';
import * as Styles from './styles.css';
import { floor, sortBy, isEmpty, sum, cloneDeep } from 'renderer/utils';
import { Marginer, KRWFomatToString, TextComponent, XScrollableTable, AntButton } from 'renderer/components';
import { TicketTemplateModel, EXTENDED_SALES_METHODS } from 'renderer/models';
import Row from 'antd/lib/row';
import { PaymentHistoryQuery } from 'renderer/queries';
import { Searcher } from './SalesGoalTab';
import { COLORS } from 'renderer/styles';
import { getIncentiveTableColumns, IncentiveNotification } from './IncentiveTab';
export var IncentiveDetailTab = function (props) {
    var _a;
    var today = dayjs();
    var _b = React.useState({
        month: today.toDate()
    }), query = _b[0], setQuery = _b[1];
    var salesStatistics = PaymentHistoryQuery.useSalesStatisticsDetailGroupByManager(__assign(__assign({}, query), { storeId: props.store.id }), {
        retryOnMount: false,
        enabled: query && !!((_a = props.store) === null || _a === void 0 ? void 0 : _a.id),
        refetchOnMount: true
    });
    var handleChange = function (data) {
        if (data.duration) {
            var _a = data.duration, startedAt = _a[0], endedAt = _a[1];
            setQuery({
                startedAt: startedAt.startOf('day').toDate(),
                endedAt: endedAt.endOf('day').toDate()
            });
        }
        else {
            setQuery({
                month: data.month.toDate()
            });
        }
    };
    var managers = props.workingManagers;
    var incentiveSetting = props.store.incentives;
    var dataSource = getDataSource(incentiveSetting, salesStatistics.data, managers === null || managers === void 0 ? void 0 : managers.filter(function (manager) { var _a; return (props.isAll ? true : (manager === null || manager === void 0 ? void 0 : manager.id) === ((_a = props.loginManager) === null || _a === void 0 ? void 0 : _a.id)); }));
    var enabledIncentive = !!(incentiveSetting === null || incentiveSetting === void 0 ? void 0 : incentiveSetting.enabled);
    return (React.createElement("div", null,
        React.createElement(Row, { justify: "space-between", align: "middle" },
            React.createElement(Searcher, { onChange: handleChange }),
            props.isPC && React.createElement(AntButton, { type: "primary", children: "\uC9C1\uC6D0\uBCC4 \uC778\uC13C\uD2F0\uBE0C \uC124\uC815", onClick: props.onClickDrawerOpen })),
        React.createElement(Marginer, { height: 20 }),
        React.createElement(Row, null,
            React.createElement(XScrollableTable, { id: "TABLE_ID_ENABLE_INCETIVE_DETAILS", resizable: {
                    additionalColumnWidth: 0
                }, key: "".concat(enabledIncentive, "_incentive_detail_table"), rowClassName: function (record, index) { return (record.colSpan || record.isLast) && Styles.totalRow; }, pagination: false, rowKey: function (row, index) { return "".concat(index); }, dataSource: dataSource, columns: __spreadArray([
                    {
                        title: '직원명',
                        dataIndex: 'managerName',
                        width: 100,
                        fixed: 'left',
                        key: 'managerName',
                        render: function (value, _) {
                            var obj = {
                                children: value,
                                props: {
                                    rowSpan: _.rowSpan ? _.rowSpan + 1 : _.isLast ? 1 : 0,
                                    colSpan: _.isLast ? 4 : 1
                                }
                            };
                            if (_.isLast) {
                                obj.props.style = { backgroundColor: COLORS.primary5 };
                            }
                            return obj;
                        }
                    },
                    {
                        title: '분류',
                        dataIndex: 'typeString',
                        key: 'typeString',
                        width: 100,
                        render: function (_, row) {
                            var obj = {
                                children: row.colSpan
                                    ? '총계'
                                    : !row.type
                                        ? ''
                                        : row.type === 'ticket'
                                            ? '선불액∙정기권'
                                            : row.type === 'service'
                                                ? '상품-시술'
                                                : row.type === 'refund'
                                                    ? '환불'
                                                    : '상품-제품',
                                props: {
                                    colSpan: row.colSpan ? row.colSpan : row.isLast ? 0 : 1
                                }
                            };
                            return obj;
                        }
                    },
                    {
                        title: '1차',
                        dataIndex: 'group1',
                        key: 'group1',
                        width: 100,
                        render: function (_, row) {
                            var obj = {
                                children: TicketTemplateModel === null || TicketTemplateModel === void 0 ? void 0 : TicketTemplateModel.getTypeToString(row.group1),
                                props: {
                                    colSpan: row.colSpan ? 0 : row.isLast ? 0 : 1
                                }
                            };
                            return obj;
                        }
                    },
                    {
                        title: '2차',
                        dataIndex: 'group2',
                        key: 'group1',
                        width: 100,
                        render: function (value, row) {
                            var obj = {
                                children: row.group2,
                                props: {
                                    colSpan: row.colSpan ? 0 : row.isLast ? 0 : 1
                                }
                            };
                            return obj;
                        }
                    }
                ], getIncentiveTableColumns(dataSource, props.store, enabledIncentive), true) })),
        React.createElement(IncentiveNotification, null)));
};
var getDataSource = function (incentives, data, managers) {
    var types = [
        EXTENDED_SALES_METHODS.CARD,
        EXTENDED_SALES_METHODS.TRANSFER,
        EXTENDED_SALES_METHODS.CASH,
        EXTENDED_SALES_METHODS.NAVER_PAY,
        EXTENDED_SALES_METHODS.ETC1,
        EXTENDED_SALES_METHODS.ETC2,
        EXTENDED_SALES_METHODS.ETC3,
        EXTENDED_SALES_METHODS.ETC4,
        EXTENDED_SALES_METHODS.ETC5,
        EXTENDED_SALES_METHODS.UNPAID,
        EXTENDED_SALES_METHODS.PREPAID_POINT,
        EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
        EXTENDED_SALES_METHODS.CUSTOMER_POINT,
        EXTENDED_SALES_METHODS.DISCOUNT
    ];
    var originalDataSource = [];
    if (managers) {
        managers.map(function (manager) {
            var targetArray = data && data[manager.id] && cloneDeep(data[manager.id]);
            if (targetArray) {
                var forOrder_1 = {
                    service: 1,
                    product: 2,
                    ticket: 3,
                    refund: 4
                };
                originalDataSource.push.apply(originalDataSource, sortBy(targetArray, [function (a) { return forOrder_1[a.type]; }, 'group1', 'group2']));
            }
            else {
                originalDataSource.push({ managerId: manager.id });
            }
        });
    }
    var dataSource = [];
    if (!isEmpty(originalDataSource)) {
        var currentManagerId_1;
        var currentMangerNumberOfManagerRow_1;
        var totalByManager_1;
        originalDataSource.map(function (item, index) {
            var isFirstManagerId = item.managerId !== currentManagerId_1;
            if (isFirstManagerId) {
                totalByManager_1 = {};
                var manager = managers && managers.find(function (manager) { return manager.id === item.managerId; });
                item.managerName = (manager === null || manager === void 0 ? void 0 : manager.displayName) || '-';
                currentManagerId_1 = item.managerId;
                currentMangerNumberOfManagerRow_1 = originalDataSource.filter(function (item) { return item.managerId === currentManagerId_1; }).length;
                item.rowSpan = currentMangerNumberOfManagerRow_1;
            }
            var isManagerLastRow = originalDataSource.length === index + 1 ||
                (originalDataSource[index + 1] && originalDataSource[index + 1].managerId !== currentManagerId_1);
            types.map(function (type) {
                var safeValue = getSafeValue(item[type]);
                var incentiveSetting = getIncentiveSetting(incentives, item.managerId, item.type, type);
                var incentiveValue = (safeValue * incentiveSetting) / 100;
                item[type] = {
                    value: safeValue,
                    incentiveValue: incentiveValue
                };
                if (totalByManager_1) {
                    totalByManager_1[type] = totalByManager_1[type]
                        ? {
                            value: totalByManager_1[type].value + safeValue,
                            incentiveValue: totalByManager_1[type].incentiveValue + incentiveValue
                        }
                        : {
                            value: safeValue,
                            incentiveValue: incentiveValue
                        };
                }
            });
            var totalValue = sum(types.map(function (type) { var _a; return getSafeValue((_a = item[type]) === null || _a === void 0 ? void 0 : _a.value); }));
            var incentiveTotal = sum(types.map(function (type) { var _a; return getSafeValue((_a = item[type]) === null || _a === void 0 ? void 0 : _a.incentiveValue); }));
            dataSource.push(__assign(__assign({}, item), { total: { value: totalValue, incentiveValue: incentiveTotal } }));
            if (isManagerLastRow) {
                dataSource.push(__assign(__assign({ colSpan: 3 }, totalByManager_1), { total: {
                        value: sum(types.map(function (type) { return totalByManager_1[type].value; })),
                        incentiveValue: sum(types.map(function (type) { return totalByManager_1[type].incentiveValue; }))
                    } }));
            }
        });
    }
    var lastRow = { managerName: '총계', isLast: true };
    dataSource.map(function (row) {
        if (!row.type) {
            __spreadArray(__spreadArray([], types, true), ['total'], false).map(function (type) {
                var _a, _b, _c, _d;
                lastRow[type] = {
                    value: (((_a = lastRow[type]) === null || _a === void 0 ? void 0 : _a.value) || 0) + ((_b = row[type]) === null || _b === void 0 ? void 0 : _b.value) || 0,
                    incentiveValue: (((_c = lastRow[type]) === null || _c === void 0 ? void 0 : _c.incentiveValue) || 0) + ((_d = row[type]) === null || _d === void 0 ? void 0 : _d.incentiveValue) || 0
                };
            });
        }
    });
    dataSource.push(lastRow);
    return dataSource;
};
var getSafeValue = function (value) {
    return !isNaN(value) ? value : 0;
};
export var renderTotalWithIncen = function (data, enabledIncentive) {
    return (data && (React.createElement("div", null,
        React.createElement(TextComponent, { type: "caption1", color: COLORS.gray1, children: KRWFomatToString({ value: floor(data.value) }) }),
        enabledIncentive && (React.createElement(TextComponent, { type: "caption1", color: COLORS.info, children: "(".concat(KRWFomatToString({ value: floor(data.incentiveValue) }), ")") })))));
};
export var getIncentiveSetting = function (incentives, managerId, targetKey, incentiveType) {
    var settings = incentives[managerId] || incentives.all;
    return (settings && settings[targetKey] && settings[targetKey][incentiveType]) || 0;
};
