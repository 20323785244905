var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { XScrollableTable, TextComponent, DeletePopconfirm, Icon, CreditCardRegister, AlertComponent, AntButton } from 'renderer/components';
import { StorePaymentMethodQuery } from 'renderer/queries';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import { COLORS } from 'renderer/styles';
import Row from 'antd/lib/row';
import { isEmpty } from 'renderer/utils';
var StorePaymentMethod = function (props) {
    var _a, _b;
    var storeId = (_a = props.store) === null || _a === void 0 ? void 0 : _a.id;
    var query = StorePaymentMethodQuery.usePaymentMethods(storeId, { enabled: !!storeId });
    var updateMethod = StorePaymentMethodQuery.useUpdatPaymentMethod();
    var updatePrimaryMethod = StorePaymentMethodQuery.useUpdatePrimary();
    var deleteMethod = StorePaymentMethodQuery.useDeletePaymentMethod();
    var handleUpdatePrimary = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updatePrimaryMethod.mutateAsync(value)];
                case 1:
                    _a.sent();
                    notification.success({ message: '결제 수단 정보가 수정되었습니다.' });
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUpdate = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateMethod.mutateAsync(value)];
                case 1:
                    _a.sent();
                    notification.success({ message: '결제 수단 정보가 수정되었습니다.' });
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    notification.error({ message: err_1.message || '결제수단 등록 중 오류발생' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteMethod.mutateAsync({
                            storeId: storeId,
                            id: id
                        })];
                case 1:
                    _a.sent();
                    notification.success({ message: '결제수단이 삭제되었습니다.' });
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    notification.error({ message: err_2.message || '결제수단 삭제 중 오류발생' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _c = React.useState(), visible = _c[0], setVisible = _c[1];
    return (React.createElement("div", null,
        React.createElement(CreditCardRegister, { storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id, visible: visible, onClose: function () { return setVisible(false); } }),
        React.createElement(Row, { justify: "end", style: { marginBottom: '10px' } }, isEmpty(query.data) && React.createElement(AntButton, { type: "primary", children: "\uC0C8 \uCE74\uB4DC \uB4F1\uB85D", onClick: function () { return setVisible(true); } })),
        React.createElement(XScrollableTable, { pagination: false, dataSource: query.data, resizable: {
                additionalColumnWidth: 0
            }, columns: [
                {
                    title: '카드별명',
                    dataIndex: 'nickname',
                    key: 'nickname',
                    editable: true,
                    onSave: handleUpdate,
                    render: function (_, data) {
                        return data.nickname;
                    }
                },
                {
                    title: '카드사',
                    dataIndex: ['data', 'card_name'],
                    key: 'data.card_name'
                },
                {
                    title: '주 결제카드 설정',
                    dataIndex: 'isPrimary',
                    key: 'isPrimary',
                    render: function (_, data) {
                        return data.isPrimary ? (React.createElement(Primary, null,
                            React.createElement(Icon, { name: "check", color: COLORS.primary1 }),
                            React.createElement(TextComponent, { children: "\uC8FC \uACB0\uC81C \uCE74\uB4DC", color: COLORS.primary1 }))) : (React.createElement(UpdatePrimary, { onClick: function () { return handleUpdatePrimary(data); } },
                            React.createElement(TextComponent, { children: "\uC8FC \uACB0\uC81C\uCE74\uB4DC\uB85C \uC124\uC815", color: COLORS.gray1 })));
                    }
                },
                {
                    title: '관리',
                    dataIndex: 'management',
                    key: 'management',
                    render: function (_, data) {
                        return (React.createElement(DeletePopconfirm, { title: "\uCE74\uB4DC\uB97C \uC0AD\uC81C\uD558\uBA74 \uBB38\uC790\uD3EC\uC778\uD2B8 \uC790\uB3D9 \uCDA9\uC804\uC744 \uC774\uC6A9\uD560 \uC218 \uC5C6\uC73C\uBA70, \uAD6C\uB3C5\uB9CC\uB8CC\uC77C\uC5D0 \uAD6C\uB3C5\uC774 \uD574\uC9C0\uB429\uB2C8\uB2E4. \uACB0\uC81C \uCE74\uB4DC\uB97C \uC0AD\uC81C\uD558\uC2E4 \uAC74\uAC00\uC694?", onConfirm: function () { return handleDelete(data.id); } }));
                    }
                }
            ] }),
        React.createElement(AlertComponent, { style: { marginTop: '20px', marginBottom: '20px' }, showIcon: true, message: "\uC8FC\uC758", description: React.createElement("div", null,
                React.createElement(TextComponent, { children: "- \uD305\uCEE4\uBDF0\uC758 \uD50C\uB79C \uAD6C\uB3C5 \uBC0F \uBB38\uC790\uD3EC\uC778\uD2B8 \uCDA9\uC804\uC744 \uC704\uD574\uC11C\uB294 \uCE74\uB4DC\uB4F1\uB85D\uC774 \uD544\uC694\uD569\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "- \uBB34\uD1B5\uC7A5\uC785\uAE08\uC73C\uB85C \uACB0\uC81C\uB97C \uC6D0\uD558\uC2DC\uB294 \uBD84\uC740 \uCC44\uD305\uC0C1\uB2F4\uC744 \uD1B5\uD574 \uBB38\uC758\uD574\uC8FC\uC138\uC694." }),
                React.createElement(TextComponent, { children: "- \uCE74\uB4DC\uB294 1\uC7A5\uB9CC \uB4F1\uB85D\uD560 \uC218 \uC788\uC73C\uBA70 \uD50C\uB79C\uACB0\uC81C, \uD3EC\uC778\uD2B8 \uCDA9\uC804 \uB4F1 \uACB0\uC81C\uC694\uCCAD \uC2DC \uB4F1\uB85D\uB41C \uCE74\uB4DC\uB85C \uC790\uB3D9\uACB0\uC81C\uB429\uB2C8\uB2E4." }),
                React.createElement(TextComponent, { children: "- 30\uC77C \uBB34\uB8CC\uCCB4\uD5D8 \uAE30\uAC04 \uB9CC\uB8CC \uD6C4 \uC720\uB8CC\uD50C\uB79C \uAD6C\uB3C5\uC744 \uC2DC\uC791\uD558\uC9C0 \uC54A\uC73C\uBA74 \uC720\uB8CC\uD50C\uB79C \uC694\uAE08\uC774 \uC790\uB3D9\uC73C\uB85C \uACB0\uC81C\uB418\uC9C0 \uC54A\uC73C\uB2C8 \uC548\uC2EC\uD558\uC138\uC694." })), type: "warning" })));
};
export default StorePaymentMethod;
var Primary = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  & svg {\n    margin-right: 5px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  & svg {\n    margin-right: 5px;\n  }\n"])));
var UpdatePrimary = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2;
