var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { FieldWithIcon, DrawerLeft, DrawerRight, RightTop, ScheduleDrawerFooterButtons, WrapperRow } from '.';
import { EXTENDED_SALES_METHODS, RESERVATION_STATUS, SalesHistoryModel, ScheduleModel } from 'renderer/models';
import { CustomDropdownButton, CustomerSearchInput, FluidRow, KRWFomatToString, ListWithBadge, Rectangle, ScheduleStatusTag, showDeleteReservationPopup, AntPrimaryTextButton, TextComponent, PaymentInputs, UnderlineClickableText, Icon, HookFormInputs, CustomDropdownMenu, BigTitleModal, ColorTag, TableComponent, AlertSentenceWithIcon, DepositStatusLabel, ScheduleDepositWithLabel, EntireLoader, AdditionalCover, handleSubmitHairTag } from 'renderer/components';
import { Row } from 'antd/lib/grid';
import { COLORS } from 'renderer/styles';
import Button from 'antd/lib/button';
import { useSaleModal, useScheduleModal } from 'renderer/stores';
import { ManagerQuery, ScheduleQuery, StoreQuery, StoreAccountQuery, NaverLambdaQuery } from 'renderer/queries';
import { useForm, useFormState } from 'react-hook-form';
import styled from 'styled-components';
import { createNanoId, isEmpty, mapValues, omitEmptyValues, sum, uniqBy } from 'renderer/utils';
import notification from 'antd/lib/notification';
import Empty from 'antd/lib/empty';
import Dropdown from 'antd/lib/dropdown';
import { DATE_FORMAT_INCLUDE_TIME } from 'renderer/constants';
import dayjs from 'dayjs';
import Radio from 'antd/lib/radio';
export var ScheduleActions = function (props) {
    var schedule = props.schedule, type = props.type, isPC = props.isPC;
    var _a = useScheduleModal(), replace = _a.replace, removeLastItem = _a.removeLastItem, replaceFromLastItem = _a.replaceFromLastItem;
    var saleModal = useSaleModal();
    var handleClick = function (key) { return __awaiter(void 0, void 0, void 0, function () {
        var sales;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(key === 'sales')) return [3 /*break*/, 2];
                    return [4 /*yield*/, ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.getSalesModalDataFromThis())];
                case 1:
                    sales = _b.sent();
                    return [2 /*return*/, saleModal.add(sales)];
                case 2:
                    setCurrentType(key);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _b = React.useState(type), currentType = _b[0], setCurrentType = _b[1];
    var scheduleLabel = schedule === null || schedule === void 0 ? void 0 : schedule.typeString;
    var store = StoreQuery.getMyStoreInCache();
    React.useEffect(function () {
        if (type !== currentType) {
            setCurrentType(type);
        }
    }, [type]);
    var renderType = function () {
        var _a, _b;
        if (schedule.isReservation) {
            switch (currentType) {
                case 'list':
                    return (React.createElement(ListWithBadge, { items: [
                            { label: '예약상세', value: 'details', onClick: handleClick },
                            { label: '예약금관리', value: 'deposit', onClick: handleClick },
                            // { label: '동의서 등록', value: 'agreement', onClick: handleClick },
                            { label: '예약문자 발송', value: 'message', onClick: handleClick },
                            ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.isOfficialNaverReservation) && {
                                label: '네이버 헤어태그 수정',
                                value: 'har-tags',
                                onClick: handleClick
                            },
                            [(ScheduleModel.RESERVATION_STATUS.CONFIRMED, ScheduleModel.RESERVATION_STATUS.COMPLETED)].includes(schedule.status) && {
                                label: (schedule === null || schedule === void 0 ? void 0 : schedule.salesHistoryId) ? '매출확인' : '매출등록',
                                value: 'sales',
                                onClick: handleClick
                            }
                        ].filter(Boolean) }));
                case 'details':
                    return isPC ? (React.createElement(ListWithBadge, { items: [
                            { label: '예약금관리', value: 'deposit', onClick: handleClick },
                            // { label: '동의서 등록', value: 'agreement', onClick: handleClick },
                            { label: '예약문자 발송', value: 'message', onClick: handleClick },
                            ((_b = props.schedule) === null || _b === void 0 ? void 0 : _b.isOfficialNaverReservation) && {
                                label: '네이버 헤어태그 수정',
                                value: 'har-tags',
                                onClick: handleClick
                            },
                            [ScheduleModel.RESERVATION_STATUS.CONFIRMED, ScheduleModel.RESERVATION_STATUS.CONFIRMED].includes(schedule.status) && { label: (schedule === null || schedule === void 0 ? void 0 : schedule.salesHistoryId) ? '매출확인' : '매출등록', value: 'sales', onClick: handleClick }
                        ].filter(Boolean) })) : (React.createElement(ScheduleDetail, { schedule: schedule, onClick: handleClick }));
                case 'deposit':
                    return (React.createElement(DepositForm, { schedule: schedule, onClickBack: function () {
                            setCurrentType('details');
                        } }));
                case 'agreement':
                    return (React.createElement(AgreementForm, { schedule: schedule, onClickBack: function () {
                            setCurrentType('details');
                        } }));
                case 'har-tags':
                    return (React.createElement(NaverHairTag, { schedule: schedule, onClickBack: function () {
                            setCurrentType('details');
                        } }));
                case 'message':
                    return store.hasSubscription ? (React.createElement(MessageSender, { schedule: schedule, onClickBack: function () {
                            setCurrentType('details');
                        } })) : (React.createElement("div", { style: { position: 'relative', minHeight: '1200px' } },
                        React.createElement(Icon, { name: "left-chevron", onClick: function () {
                                setCurrentType('details');
                            }, size: 24, style: {
                                position: 'absolute',
                                top: '15px',
                                left: '15px',
                                zIndex: 99999,
                                cursor: 'pointer'
                            }, color: COLORS.black }),
                        React.createElement(AdditionalCover, { store: store, type: "autoMessage", isNarrow: true })));
            }
        }
        else {
            return React.createElement("div", null);
        }
    };
    var customerInput = (React.createElement("div", { className: "customerSearcher" },
        React.createElement(CustomerSearchInput, { disabled: true, value: schedule === null || schedule === void 0 ? void 0 : schedule.customerId, isDeletedCustomer: (schedule === null || schedule === void 0 ? void 0 : schedule.customerId) && !(schedule === null || schedule === void 0 ? void 0 : schedule.customer) })));
    var isSaled = !!(schedule === null || schedule === void 0 ? void 0 : schedule.salesHistoryId);
    var footer = (React.createElement(ScheduleDrawerFooterButtons, { right: React.createElement(Button, { children: "\uB2EB\uAE30", type: "primary", onClick: function () {
                removeLastItem();
            } }), left: React.createElement(CustomDropdownButton, { items: [
                {
                    name: 'modify',
                    label: "".concat(scheduleLabel, " \uC218\uC815"),
                    icon: 'edit3',
                    onClick: function () {
                        replaceFromLastItem({
                            id: props.schedule.id,
                            type: 'update',
                            schedule: props.schedule
                        });
                    }
                },
                {
                    name: 'delete',
                    label: "".concat(scheduleLabel, " \uC0AD\uC81C"),
                    icon: 'trash',
                    disabled: isSaled,
                    onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, showDeleteReservationPopup(props.schedule, store)];
                                case 1:
                                    _a.sent();
                                    removeLastItem();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    color: COLORS.calendarRedActive
                }
            ], buttonProps: { children: "".concat(scheduleLabel, "\uC218\uC815\u2219\uCDE8\uC18C/\uC0AD\uC81C") } }) }));
    return isPC ? (React.createElement(WrapperRow, { wrap: false },
        React.createElement(DrawerLeft, null,
            React.createElement(ScheduleDetail, { schedule: schedule, onClick: handleClick })),
        React.createElement(DrawerRight, null,
            customerInput,
            React.createElement(RightTop, null, renderType()),
            footer))) : (React.createElement(WrapperRow, { wrap: false },
        React.createElement(DrawerRight, null,
            customerInput,
            React.createElement(RightTop, null, renderType()),
            footer)));
};
var DepositForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var customer = props.schedule.customer;
    var store = StoreQuery.getMyStoreInCache();
    var prevMethods = SalesHistoryModel.getMethodsForForm(((_b = (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.methods) || null);
    var defaultValue = { methods: prevMethods, customerId: (_c = props.schedule) === null || _c === void 0 ? void 0 : _c.customerId, status: (_d = props.schedule) === null || _d === void 0 ? void 0 : _d.status };
    var methods = useForm({
        defaultValues: defaultValue
    });
    var values = methods.watch();
    var state = methods.formState;
    var finalPrice = ((_f = (_e = props.schedule) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.deposit) || 0;
    var newMethods = omitEmptyValues(mapValues(values.methods, function (o) { return (o.active ? o.deduction || null : isFinite(o) ? o : null); }));
    // const paidPrice = newMethods ? sum(Object.keys(newMethods).map((key) => newMethods[key] || 0)) : 0;
    var invalid = (!isEmpty(state.errors) && isEmpty(state.dirtyFields)) || !finalPrice;
    var updateSchedule = ScheduleQuery.useUpdateSchedule();
    var replaceFromLastItem = useScheduleModal().replaceFromLastItem;
    var scheduleId = (_g = props.schedule) === null || _g === void 0 ? void 0 : _g.id;
    var transfers = StoreAccountQuery.useStoreTransfers({ page: 1, lmit: 100, scheduleId: scheduleId, storeId: store === null || store === void 0 ? void 0 : store.id }, { enabled: !!(scheduleId && (store === null || store === void 0 ? void 0 : store.id) && (store === null || store === void 0 ? void 0 : store.hasSubplan('deposit'))) });
    var total = sum(Object.keys(values.methods).map(function (key) { var _a; return ((_a = values.methods[key]) === null || _a === void 0 ? void 0 : _a.deduction) || 0; })) || 0;
    var isWarning = finalPrice !== total;
    var _k = React.useState(false), open = _k[0], setOpen = _k[1];
    return (React.createElement(ActionWrapper, { title: "\uC608\uC57D\uAE08 \uAD00\uB9AC", back: { onClick: props.onClickBack }, confirm: {
            label: '입력완료',
            onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!invalid) {
                        setOpen(true);
                    }
                    return [2 /*return*/];
                });
            }); },
            disabled: invalid
        }, content: React.createElement(React.Fragment, null,
            React.createElement(EntireLoader, { open: updateSchedule.isLoading }),
            React.createElement(BigTitleModal, { centered: true, open: open, title: "\uC608\uC57D\uAE08 \uAE30\uB85D", okText: "\uC800\uC7A5", cancelText: "\uB2EB\uAE30", onCancel: function () { return setOpen(false); }, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var newSchedule, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, updateSchedule.mutateAsync(__assign(__assign({}, props.schedule), { status: values.status, data: __assign(__assign({}, props.schedule.data), { depositUpdatedAt: new Date(), methods: newMethods }) }))];
                            case 1:
                                newSchedule = _a.sent();
                                replaceFromLastItem({ id: newSchedule.id, type: 'details', schedule: newSchedule });
                                notification.success({ message: '예약금 입금정보 저장이 완료되었습니다.' });
                                setOpen(false);
                                props.onClickBack();
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _a.sent();
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, children: React.createElement("div", { style: { padding: '20px' } },
                    React.createElement(TextComponent, { children: "\uC608\uC57D\uAE08\uC561 : ".concat(KRWFomatToString({ value: finalPrice })), marginBottom: 5 }),
                    React.createElement(Row, null,
                        React.createElement(TextComponent, { children: "\uC2E4\uC785\uAE08\uC561 : ".concat(KRWFomatToString({ value: total })) }),
                        React.createElement(ScheduleDepositWithLabel, { type: !total && finalPrice
                                ? 'pending'
                                : finalPrice === total
                                    ? 'completed'
                                    : finalPrice > total
                                        ? 'under'
                                        : 'over' })),
                    React.createElement(TextComponent, { children: "\uC608\uC57D\uC0C1\uD0DC", marginTop: 20, marginBottom: 5 }),
                    React.createElement(Radio.Group, { value: values.status, onChange: function (ev) {
                            console.log('ev', ev);
                            methods.setValue('status', ev.target.value);
                        } },
                        React.createElement(Radio, { value: RESERVATION_STATUS.PENDING }, "\uC608\uC57D \uB300\uAE30"),
                        React.createElement(Radio, { value: RESERVATION_STATUS.CONFIRMED }, "\uC608\uC57D \uD655\uC815"))) }),
            !isEmpty((_h = transfers === null || transfers === void 0 ? void 0 : transfers.pagerResult) === null || _h === void 0 ? void 0 : _h.items) && (React.createElement("div", null,
                React.createElement(TextComponent, { children: "\uC608\uC57D\uAE08 \uC785\uAE08\uB0B4\uC5ED", type: "headline3", marginBottom: 5 }),
                React.createElement(TableComponent, { dataSource: ((_j = transfers.pagerResult) === null || _j === void 0 ? void 0 : _j.items) || [], columns: [
                        {
                            title: '입금시간',
                            dataIndex: ['transferedAt'],
                            key: 'transferedAt',
                            render: function (value) { return dayjs(value).format(DATE_FORMAT_INCLUDE_TIME); },
                            width: 120
                        },
                        {
                            title: '입금자명',
                            dataIndex: ['participantName'],
                            key: 'participantName',
                            render: function (value) { return value; },
                            width: 80
                        },
                        {
                            title: '실입금액',
                            dataIndex: ['transactionAmount'],
                            key: 'transactionAmount',
                            render: function (value) { return KRWFomatToString({ value: value }); },
                            width: 100
                        }
                    ] }))),
            finalPrice ? (React.createElement("div", null,
                React.createElement(TextComponent, { children: "\uC608\uC57D\uAE08 \uAE30\uB85D", type: "headline3", marginBottom: 5, marginTop: 20 }),
                isWarning && (React.createElement(AlertSentenceWithIcon, { descriptions: ['예약금액과 입력한 금액의 합이 동일하지 않습니다.'], color: COLORS.warning })),
                React.createElement(PaymentInputs, { parentType: "deposit", hideTitle: true, hideCreateCustomerPoint: true, hideMethods: [
                        EXTENDED_SALES_METHODS.CUSTOMER_POINT,
                        EXTENDED_SALES_METHODS.DISCOUNT,
                        EXTENDED_SALES_METHODS.UNPAID
                    ], ignoreLeftPayment: true, finalPrice: finalPrice, methods: methods, customer: customer, store: store, data: { type: 'product', data: defaultValue }, onSubmit: function (e) { }, onCancel: function () { } }))) : (React.createElement(Empty, { description: "\uC608\uC57D\uAE08\uC774 \uC5C6\uC2B5\uB2C8\uB2E4." }))) }));
};
var AgreementForm = function (props) {
    var _a, _b, _c, _d;
    var _e = React.useState('request'), mode = _e[0], setMode = _e[1];
    var isRequest = mode === 'request';
    var store = StoreQuery.getMyStoreInCache();
    var templates = ((_a = store === null || store === void 0 ? void 0 : store.preference) === null || _a === void 0 ? void 0 : _a.preagreementTemplates) || [];
    var updateStore = StoreQuery.useUpdateStore();
    var _f = React.useState(), modal = _f[0], setModal = _f[1];
    var handleDelete = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var newTemplates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newTemplates = templates.filter(function (template) {
                        return template.id !== data.id;
                    });
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { preagreementTemplates: newTemplates })
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var isUpdated, newTemplates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!store) {
                        return [2 /*return*/];
                    }
                    if (!(modal.type === 'form')) return [3 /*break*/, 2];
                    isUpdated = !!data.id;
                    newTemplates = isUpdated
                        ? templates.map(function (template) {
                            if (template.id === data.id) {
                                return __assign(__assign({}, template), data);
                            }
                            else {
                                return template;
                            }
                        })
                        : templates.concat(__assign(__assign({}, data), { id: createNanoId() }));
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { preagreementTemplates: newTemplates })
                        })];
                case 1:
                    _a.sent();
                    setModal(null);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var isEmptyTemplates = isEmpty(templates);
    return (React.createElement(ActionWrapper, { title: React.createElement(Row, { align: "middle", justify: "space-between" },
            React.createElement(TextComponent, { marginBottom: 20, textAlign: "center", type: "headline3", children: isRequest ? '동의서 등록' : '동의서 템플릿 설정' }),
            React.createElement(Icon, { name: isRequest ? 'settings' : 'plus', size: 24, onClick: function () { return (isRequest ? setMode('setting') : setModal({ type: 'form' })); } })), confirm: isRequest
            ? {
                disabled: !((_b = props.schedule) === null || _b === void 0 ? void 0 : _b.customer) || !((_d = (_c = props.schedule) === null || _c === void 0 ? void 0 : _c.customer) === null || _d === void 0 ? void 0 : _d.availableReceive),
                label: '전자서명 요청',
                onClick: function () { return alert('요청!'); }
            }
            : null, back: { onClick: isRequest ? props.onClickBack : function () { return setMode('request'); } }, content: React.createElement(React.Fragment, null,
            React.createElement(PreagreementModal, { modal: modal, onClose: function () { return setModal(null); }, onSubmit: handleSubmit }),
            isEmptyTemplates ? (React.createElement(Empty, { description: "\uB4F1\uB85D\uB41C \uB3D9\uC758\uC11C \uD15C\uD50C\uB9BF\uC774 \uC5C6\uC2B5\uB2C8\uB2E4. + \uBC84\uD2BC\uC744 \uD074\uB9AD\uD558\uC5EC \uC804\uC790\uC11C\uBA85 \uD15C\uD50C\uB9BF\uC744 \uB9CC\uB4E4\uC5B4\uBCF4\uC138\uC694." })) : (templates.map(function (template) {
                return (React.createElement(SMSRow, { align: "middle", key: template.id, justify: "space-between" },
                    React.createElement(TextComponent, { type: "headline3", children: template.title }),
                    React.createElement(Dropdown, { trigger: ['click'], overlay: React.createElement(CustomDropdownMenu, { items: [
                                {
                                    label: '동의서 템플릿 수정',
                                    name: 'update',
                                    onClick: function () {
                                        setModal({ type: 'form', data: template });
                                    }
                                },
                                {
                                    label: '동의서 템플릿 삭제',
                                    name: 'delete',
                                    onClick: function () {
                                        handleDelete(template);
                                    }
                                }
                            ] }), placement: "bottomRight" },
                        React.createElement(Icon, { name: "more-horizontal", size: 24 }))));
            }))) }));
};
var NaverHairTag = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var tags = NaverLambdaQuery.useGetNaverHairTags();
    var bookingId = (_b = (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.idFromProvider;
    var tagsFromSchedule = NaverLambdaQuery.useGetNaverHairTagsById({ bookingId: (_d = (_c = props.schedule) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.idFromProvider }, { enabled: !!((_f = (_e = props.schedule) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.idFromProvider) });
    React.useEffect(function () {
        var _a;
        var values = [];
        (_a = tagsFromSchedule === null || tagsFromSchedule === void 0 ? void 0 : tagsFromSchedule.data) === null || _a === void 0 ? void 0 : _a.map(function (category) {
            category.hairTags.map(function (item) {
                values.push("".concat(item.sex, "_").concat(category.hairTagCategoryCode, "_").concat(item.hairTagPlaceCode, "_").concat(item.hairTagName));
            });
        });
        methods.reset({ hairTags: values || [] });
    }, [tagsFromSchedule.data]);
    var shouldUpdate = isEmpty(tagsFromSchedule === null || tagsFromSchedule === void 0 ? void 0 : tagsFromSchedule.data);
    var methods = useForm({ mode: 'onChange' });
    var create = NaverLambdaQuery.useCreateHairTag();
    var update = NaverLambdaQuery.useUpdateTag();
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleSubmitHairTag(values, shouldUpdate, create, update, bookingId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var options = [];
    (_g = tags === null || tags === void 0 ? void 0 : tags.data) === null || _g === void 0 ? void 0 : _g.map(function (item) {
        var _a;
        (_a = item.hairTags) === null || _a === void 0 ? void 0 : _a.map(function (tag) {
            options.push({
                value: "".concat(tag.sex, "_").concat(item.hairTagCategoryCode, "_").concat(tag.hairTagPlaceCode, "_").concat(tag.hairTagName),
                label: "".concat(item.hairTagCategoryCodeName, "_").concat(tag.sex === 'MALE' ? '남성' : '여성', " ").concat(tag.hairTagName)
            });
        });
    });
    return (React.createElement(ActionWrapper, { title: "\uB124\uC774\uBC84 \uD5E4\uC5B4\uD0DC\uADF8", confirm: {
            disabled: !methods.formState.isValid,
            label: '헤어태그 입력',
            onClick: onSubmit
        }, back: { onClick: props.onClickBack }, content: tags.isLoading ? (React.createElement(EntireLoader, { open: true })) : (React.createElement("form", { style: { padding: '10px' } },
            React.createElement(HookFormInputs.SelectInput, { controlProps: {
                    name: 'hairTags',
                    control: methods.control,
                    rules: {
                        validate: function (values) {
                            var styles = {};
                            values === null || values === void 0 ? void 0 : values.map(function (tagItem) {
                                var _a = tagItem.split('_'), sex = _a[0], hairTagCategoryCode = _a[1], hairTagPlaceCode = _a[2], hairTagName = _a[3];
                                if (styles[hairTagCategoryCode]) {
                                    styles[hairTagCategoryCode] = styles[hairTagCategoryCode].concat(hairTagName);
                                }
                                else {
                                    styles[hairTagCategoryCode] = [hairTagName];
                                }
                            });
                            var tagitems = tags.data;
                            var error;
                            values === null || values === void 0 ? void 0 : values.map(function (tagItem) {
                                var _a = tagItem.split('_'), sex = _a[0], hairTagCategoryCode = _a[1], hairTagPlaceCode = _a[2], hairTagName = _a[3];
                                var tagCategory = tagitems.find(function (tag) {
                                    return tag.hairTagCategoryCode === hairTagCategoryCode;
                                });
                                if (!hairTagCategoryCode || !tagCategory) {
                                    error = '유효하지 않은 옵션입니다.';
                                    return;
                                }
                                if (styles[hairTagCategoryCode]) {
                                    if (styles[hairTagCategoryCode].length > tagCategory.maxSelectionCount) {
                                        error = "".concat(tagCategory.hairTagCategoryCodeName, "\uB294 ").concat(tagCategory.maxSelectionCount, "\uAC1C \uC774\uD558\uB85C \uC120\uD0DD\uAC00\uB2A5\uD569\uB2C8\uB2E4.");
                                        return;
                                    }
                                }
                            });
                            return error || true;
                        }
                    }
                }, inputProps: {
                    mode: 'multiple',
                    style: { width: '100%' },
                    loading: tags.isLoading,
                    options: uniqBy(options, 'value')
                } }))) }));
};
var PreagreementModal = function (props) {
    var _a, _b;
    var isForm = ((_a = props === null || props === void 0 ? void 0 : props.modal) === null || _a === void 0 ? void 0 : _a.type) === 'form';
    var title = isForm ? "\uB3D9\uC758\uC11C \uC218\uC815" : '전자서명 요청';
    var methods = useForm({
        defaultValues: (_b = props.modal) === null || _b === void 0 ? void 0 : _b.data
    });
    var formState = useFormState({ control: methods.control });
    React.useEffect(function () {
        if (props.modal) {
            methods.reset(props.modal.data);
        }
    }, [props.modal]);
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            props.onSubmit(__assign(__assign({}, values), { id: (_b = (_a = props.modal) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id }));
            return [2 /*return*/];
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    return (React.createElement(BigTitleModal, { okButtonProps: {
            disabled: !formState.isValid && !formState.isDirty,
            children: '입력완료',
            onClick: onSubmit
        }, onCancel: props.onClose, open: !!props.modal, title: title, children: React.createElement("form", { style: { padding: '10px' } },
            React.createElement(HookFormInputs.Text, { controlProps: {
                    name: "title",
                    control: methods.control
                }, label: "\uC81C\uBAA9", inputProps: {
                    size: 'large',
                    placeholder: '동의서 제목을 입력해주세요.'
                } }),
            React.createElement(HookFormInputs.Textarea, { controlProps: {
                    name: "content",
                    control: methods.control
                }, inputProps: {
                    size: 'large',
                    placeholder: '동의서 내용을 입력해주세요.'
                } })) }));
};
var MessageSender = function (props) {
    var smsList = [
        { label: '예약 대기 문자', value: 'pending' },
        { label: '예약 알림 문자', value: 'remind' },
        { label: '예약 확정 문자', value: 'confirm' },
        { label: '예약 취소 문자', value: 'storeCancel' },
        { label: '노쇼 알림 문자', value: 'noshow' }
        // {label: '예약 거절 문자', value: 'reject'},
    ];
    var sendSMS = ScheduleQuery.useSendScheduleSMS();
    var store = StoreQuery.getMyStoreInCache();
    var handleSend = function (key) { return __awaiter(void 0, void 0, void 0, function () {
        var label;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, sendSMS.mutateAsync({
                        key: key,
                        id: (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.id,
                        storeId: props.schedule.storeId
                    })];
                case 1:
                    _b.sent();
                    label = smsList.find(function (item) { return item.value === key; });
                    notification.success({ message: "".concat(label.label, " \uC804\uC1A1\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
                    return [2 /*return*/];
            }
        });
    }); };
    var renderBody = function () {
        switch (true) {
            case !store.messageSendInfo:
                return React.createElement(Empty, { description: "\uBB38\uC790 \uBC1C\uC1A1 \uC124\uC815\uC774 \uD544\uC694\uD569\uB2C8\uB2E4." });
            case !props.schedule.customerId:
                return React.createElement(Empty, { description: "\uACE0\uAC1D\uC774 \uB4F1\uB85D\uB418\uC9C0 \uC54A\uC740 \uC608\uC57D\uC785\uB2C8\uB2E4." });
            default:
                return (React.createElement(React.Fragment, null, smsList.map(function (sms) {
                    return (React.createElement(SMSRow, { align: "middle", key: sms.value, justify: "space-between" },
                        React.createElement(TextComponent, { type: "headline3", children: sms.label }),
                        React.createElement(UnderlineClickableText, { onClick: function () { return handleSend(sms.value); }, type: "headline3", children: "\uBC1C\uC1A1", color: COLORS.primary1 })));
                })));
        }
    };
    return React.createElement(ActionWrapper, { title: "\uC608\uC57D\uBB38\uC790 \uBC1C\uC1A1", back: { onClick: props.onClickBack }, content: renderBody() });
};
var SMSRow = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid var(--gray-7);\n  height: 50px;\n"], ["\n  border-bottom: 1px solid var(--gray-7);\n  height: 50px;\n"])));
var ActionWrapper = function (props) {
    return (React.createElement(ActionWrapperDiv, null,
        React.createElement("div", { className: "title" }, typeof props.title === 'string' ? (React.createElement(TextComponent, { marginBottom: 20, textAlign: "center", type: "headline3", children: props.title })) : (props.title)),
        React.createElement("div", { className: "content" }, props.content),
        React.createElement("div", { className: "footer" },
            props.confirm && (React.createElement(FluidRow, { style: { marginTop: '15px' }, justify: "center", children: React.createElement(Button, { disabled: props.confirm.disabled, type: "primary", style: { width: '100%' }, children: props.confirm.label, onClick: props.confirm.onClick }) })),
            props.back && (React.createElement(FluidRow, { justify: "center", children: React.createElement(AntPrimaryTextButton, { children: '< 이전으로 돌아가기' }), onClick: props.back.onClick })))));
};
var ActionWrapperDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 25px;\n\n  & > .title {\n    margin-top: 5px;\n  }\n"], ["\n  padding: 25px;\n\n  & > .title {\n    margin-top: 5px;\n  }\n"])));
var ScheduleDetail = function (props) {
    var _a, _b, _c, _d;
    var schedule = props.schedule;
    var manager = ManagerQuery.getManagerByIdInCache(schedule.managerId);
    var methods = ((_a = schedule.data) === null || _a === void 0 ? void 0 : _a.methods) || {};
    var total = sum(Object.keys(methods).map(function (key) { return methods[key] || 0; })) || 0;
    var fields = {
        time: {
            icon: 'clock',
            field: (React.createElement("div", null,
                React.createElement(TextComponent, { children: schedule === null || schedule === void 0 ? void 0 : schedule.datesToString }),
                schedule.isRepeatSchedule ? (React.createElement(TextComponent, { children: schedule.repeatString, type: "caption1", color: COLORS.gray3 })) : (React.createElement("div", null))))
        },
        usings: {
            icon: 'gift',
            field: (React.createElement("div", null,
                React.createElement(TextComponent, { children: schedule === null || schedule === void 0 ? void 0 : schedule.displayContent })))
        },
        manager: {
            icon: 'users',
            field: (React.createElement(Row, { align: "middle", style: { height: '100%' } }, schedule.type === ScheduleModel.RESERVATION_TYPE.OFF && !schedule.managerId ? (React.createElement(ColorTag, { children: "\uC0C1\uC810\uD734\uBB34" })) : (React.createElement(React.Fragment, null,
                React.createElement(Rectangle, { width: 12, color: ((_b = manager === null || manager === void 0 ? void 0 : manager.profile) === null || _b === void 0 ? void 0 : _b.color) || COLORS.staff1 }),
                React.createElement(TextComponent, { children: (manager === null || manager === void 0 ? void 0 : manager.displayName)
                        ? manager === null || manager === void 0 ? void 0 : manager.displayName
                        : schedule.type === ScheduleModel.RESERVATION_TYPE.OFF
                            ? '상점휴무'
                            : '담당자 없음', marginLeft: 5 })))))
        },
        title: {
            icon: 'file-text',
            field: React.createElement(TextComponent, { children: (schedule === null || schedule === void 0 ? void 0 : schedule.name) || '제목 없음', marginLeft: 5 })
        },
        description: {
            icon: 'file-text',
            field: React.createElement(TextComponent, { children: (schedule === null || schedule === void 0 ? void 0 : schedule.description) || '메모 없음', style: { whiteSpace: 'pre-line' } })
        },
        deposit: {
            icon: 'dollar',
            field: (React.createElement(React.Fragment, null,
                React.createElement(Row, { align: "middle", style: { height: '100%' } },
                    React.createElement(TextComponent, { children: ((_c = schedule === null || schedule === void 0 ? void 0 : schedule.data) === null || _c === void 0 ? void 0 : _c.deposit)
                            ? "\uC608\uC57D\uAE08\uC561 ".concat(KRWFomatToString({ value: (_d = schedule.data) === null || _d === void 0 ? void 0 : _d.deposit }))
                            : '예약금액 없음' })),
                React.createElement(Row, { align: "middle", style: { height: '100%' } },
                    React.createElement(TextComponent, { children: total ? "\uC2E4\uC785\uAE08\uC561 ".concat(KRWFomatToString({ value: total })) : '실입금액 없음' }),
                    React.createElement(Row, { style: { marginLeft: '10px' } },
                        React.createElement(StyledDepositLabel, { status: schedule === null || schedule === void 0 ? void 0 : schedule.depositStatus, onClick: function () { return props.onClick('deposit'); } })))))
        },
        status: {
            icon: 'award',
            field: (React.createElement(Row, { align: "middle", style: { height: '100%' } },
                React.createElement(ScheduleStatusTag, { schedule: schedule })))
        }
    };
    var getItems = function () {
        switch (schedule.type) {
            case ScheduleModel.RESERVATION_TYPE.RESERVATION:
                return [fields.time, fields.usings, fields.manager, fields.description, fields.deposit, fields.status];
            case ScheduleModel.RESERVATION_TYPE.OFF:
            case ScheduleModel.RESERVATION_TYPE.SCHEDULE:
                return [fields.time, fields.manager, fields.title, fields.description];
            default:
                return [];
        }
    };
    var items = getItems();
    return (React.createElement("div", { style: { padding: '15px' } }, items.map(function (item, index) {
        return React.createElement(FieldWithIcon, { key: index, field: item.field, icon: item.icon });
    })));
};
var StyledDepositLabel = styled(function (props) { return React.createElement(DepositStatusLabel, __assign({}, props)); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & > div {\n    text-decoration: underline;\n    cursor: pointer;\n  }\n  & > img {\n    margin-right: -2px;\n  }\n"], ["\n  & > div {\n    text-decoration: underline;\n    cursor: pointer;\n  }\n  & > img {\n    margin-right: -2px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
