var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Row from 'antd/lib/grid/row';
import React from 'react';
import { BigTitleModal, TextComponent, typographyByName, Icon, Marginer, ButtonWithPlusCircle, HookFormInputs, Rectangle, ProductOrTicketSelector, ScheduleStatusTag, AntPrimaryGhostButton, ScrollBar, RegisterButtonWithOptions, useEntireLoader, syncNaverCalendar, ColorTag, SubRegisterDrawer, AlertComponent, TempDescriptionArea } from 'renderer/components';
import { RESERVATION_STATUS, ScheduleModel, EXTENDED_SALES_METHODS } from 'renderer/models';
import Button from 'antd/lib/button';
import { FieldWithIcon, Inner, DrawerLeft, DrawerRight, RightTop, ScheduleDrawerFooterButtons, WrapperRow } from '.';
import { BOX_SHADOW, COLORS } from 'renderer/styles';
import { useController, useForm } from 'react-hook-form';
import Dropdown from 'antd/lib/dropdown/dropdown';
import Menu from 'antd/lib/menu';
import { addOrRemove, floor, isEmpty, isEqual, isNumber, isSameArray, set, sum, times } from 'renderer/utils';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import dayjs from 'dayjs';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Col } from 'antd/lib/grid';
import orderBy from 'lodash-es/orderBy';
import { CustomerQuery, ManagerQuery, PaymentHistoryQuery, ProductQuery, QUERY_CACHE, ScheduleQuery } from 'renderer/queries';
import Switch from 'antd/lib/switch';
import styled, { css } from 'styled-components';
import notification from 'antd/lib/notification';
import Modal from 'antd/lib/modal';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import { useRecoilState } from 'recoil';
import { Atoms, useScheduleModal } from 'renderer/stores';
import DatePicker from 'antd/lib/date-picker';
export var ScheduleFormComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var loader = useEntireLoader();
    var HEADER_AND_TAB = 80 + 55 + 80;
    var isPC = props.isPC;
    var loginedManager = (_a = ManagerQuery.getLoginManagerInCache()) === null || _a === void 0 ? void 0 : _a.id;
    var store = props.store;
    var timestep = store === null || store === void 0 ? void 0 : store.timeStep;
    var defaultStatus = (_b = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _b === void 0 ? void 0 : _b.defaultStatus;
    var startedAt = (_c = props.store) === null || _c === void 0 ? void 0 : _c.getDefaultStartTime();
    var DEFAULT_VALUES = {
        status: defaultStatus || RESERVATION_STATUS.CONFIRMED,
        type: ScheduleModel.RESERVATION_TYPE.RESERVATION,
        managerId: loginedManager || -1,
        dateOption: {
            startedAt: startedAt,
            endedAt: startedAt.add(timestep, 'minute')
            // isAllDay: true
        }
    };
    var methods = useForm({
        defaultValues: DEFAULT_VALUES
    });
    var managers = ManagerQuery.getWorkingManagersInCache();
    var handleSubmit = methods.handleSubmit, watch = methods.watch, formState = methods.formState;
    var isUpdated = !!((_d = props.schedule) === null || _d === void 0 ? void 0 : _d.id);
    var replaceFromLastItem = useScheduleModal().replaceFromLastItem;
    var createSchedule = ScheduleQuery.useCreateSchedule();
    var updateSchedule = ScheduleQuery.useUpdateSchedule();
    var updateNaverSchedule = ScheduleQuery.useNaverUpdateSchedule();
    var createNewRepeatSchedule = ScheduleQuery.useCreateNewRepeatSchedule();
    var modalOffValue = useRecoilState(Atoms.scheduleRegisterOffOptionState)[0];
    // TODO: 여기를 막자!!
    var onSubmit = handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var startedAt, endedAt, bizItems_1, bizItemId, hasChangedStoreProducts, status, methods_1, methodsType_1, newMethods, deposit, payload, result, err_1, message, isAvailableAll, data, methodsType_2, newMethods, newSchedule, err_2;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
        return __generator(this, function (_12) {
            switch (_12.label) {
                case 0:
                    startedAt = dayjs((_a = values.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt);
                    endedAt = dayjs((_b = values.dateOption) === null || _b === void 0 ? void 0 : _b.endedAt);
                    if (((_c = values.dateOption) === null || _c === void 0 ? void 0 : _c.isAllDay) || values.type === 'off') {
                        values.dateOption.startedAt = getStartAndEndTimeByStore(dayjs(values.dateOption.startedAt), store, 'start').toDate();
                        values.dateOption.endedAt = getStartAndEndTimeByStore(dayjs(values.dateOption.endedAt), store, 'end').toDate();
                    }
                    if (startedAt.hour() === 0 && startedAt.minute() === 0 && endedAt.hour() === 0 && endedAt.minute() === 0) {
                        values.dateOption.startedAt = startedAt.startOf('day').toDate();
                        values.dateOption.endedAt = endedAt.endOf('day').toDate();
                    }
                    if (!((_d = props.schedule) === null || _d === void 0 ? void 0 : _d.isOfficialNaverReservation)) return [3 /*break*/, 4];
                    bizItems_1 = ((_g = (_f = (_e = props.store) === null || _e === void 0 ? void 0 : _e.preference) === null || _f === void 0 ? void 0 : _f.smartplace) === null || _g === void 0 ? void 0 : _g.bizItems) || {};
                    bizItemId = ((_j = (_h = props.schedule) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.bizItemId) ||
                        Object.keys(bizItems_1).find(function (key) { var _a, _b; return Number((_a = bizItems_1[key]) === null || _a === void 0 ? void 0 : _a.agencyKey) === Number((_b = props.schedule) === null || _b === void 0 ? void 0 : _b.managerId); });
                    if (!bizItemId) {
                        return [2 /*return*/, notification.error({ message: '연결된 직원를 찾을 수 없습니다.' })];
                    }
                    hasChangedStoreProducts = !isSameArray((_l = (_k = props.schedule) === null || _k === void 0 ? void 0 : _k.storeProducts) === null || _l === void 0 ? void 0 : _l.map(function (item) { return item.id; }), (_m = values.storeProducts) === null || _m === void 0 ? void 0 : _m.map(function (item) { return item.id; }));
                    _12.label = 1;
                case 1:
                    _12.trys.push([1, 3, , 4]);
                    status = values.status;
                    if (((_o = props.schedule) === null || _o === void 0 ? void 0 : _o.status) === values.status) {
                        if ((hasChangedStoreProducts ||
                            Number((_p = props.schedule) === null || _p === void 0 ? void 0 : _p.managerId) !== Number(values.managerId) ||
                            // 시간변경이되면서 네이버예약이면서 네이버페이 사용이 아닌 경우
                            ((_r = (_q = props.schedule) === null || _q === void 0 ? void 0 : _q.dateOption) === null || _r === void 0 ? void 0 : _r.startedAt) !== ((_s = values.dateOption) === null || _s === void 0 ? void 0 : _s.startedAt)) &&
                            ((_t = props.schedule) === null || _t === void 0 ? void 0 : _t.isOfficialNaverReservation) &&
                            !((_u = props.schedule) === null || _u === void 0 ? void 0 : _u.isNPayUsed)) {
                            status = 'PARTIAL_CHANGED';
                            // if (dayjs(values.dateOption?.startedAt).isBefore(dayjs())) {
                            //   return notification.error({ message: '네이버 예약은 과거로 변경할 수 없습니다.' });
                            // }
                        }
                        else {
                            status = 'DESCRIPTION_CHANGED';
                        }
                    }
                    methodsType_1 = (_v = values.data) === null || _v === void 0 ? void 0 : _v.methodsType;
                    if (methodsType_1 === 'none') {
                        methods_1 = {};
                    }
                    else if (methodsType_1 === 'multiple') {
                        methods_1 = values.data.methods;
                    }
                    else {
                        newMethods = {};
                        if ((_w = values.data) === null || _w === void 0 ? void 0 : _w.deposit) {
                            if ([
                                EXTENDED_SALES_METHODS.PREPAID_POINT,
                                EXTENDED_SALES_METHODS.TRANSFER,
                                EXTENDED_SALES_METHODS.CASH,
                                EXTENDED_SALES_METHODS.CARD,
                                EXTENDED_SALES_METHODS.ETC1,
                                EXTENDED_SALES_METHODS.ETC2,
                                EXTENDED_SALES_METHODS.ETC3,
                                EXTENDED_SALES_METHODS.ETC4,
                                EXTENDED_SALES_METHODS.ETC5,
                                EXTENDED_SALES_METHODS.NAVER_PAY
                            ].includes(methodsType_1)) {
                                newMethods[methodsType_1] = values.data.deposit;
                            }
                            methods_1 = newMethods;
                        }
                    }
                    deposit = ((_x = values.data) === null || _x === void 0 ? void 0 : _x.hasDeposit) ? (_y = values.data) === null || _y === void 0 ? void 0 : _y.deposit : 0;
                    payload = {
                        bizItemId: bizItemId,
                        scheduleId: (_z = props.schedule) === null || _z === void 0 ? void 0 : _z.id,
                        storeId: (_0 = props.schedule) === null || _0 === void 0 ? void 0 : _0.storeId,
                        bookingId: (_2 = (_1 = props.schedule) === null || _1 === void 0 ? void 0 : _1.data) === null || _2 === void 0 ? void 0 : _2.idFromProvider,
                        startDateTime: dayjs((_3 = values.dateOption) === null || _3 === void 0 ? void 0 : _3.startedAt).toDate(),
                        endDateTime: dayjs((_4 = values.dateOption) === null || _4 === void 0 ? void 0 : _4.endedAt).toDate(),
                        status: status,
                        description: values.description,
                        newStoreProductIds: hasChangedStoreProducts ? (_5 = values.storeProducts) === null || _5 === void 0 ? void 0 : _5.map(function (item) { return item.id; }) : null,
                        methods: deposit ? methods_1 : {},
                        deposit: deposit,
                        actorId: loginedManager
                    };
                    return [4 /*yield*/, updateNaverSchedule.mutateAsync(payload)];
                case 2:
                    result = _12.sent();
                    replaceFromLastItem({ id: result.id, type: 'details', schedule: result });
                    return [2 /*return*/];
                case 3:
                    err_1 = _12.sent();
                    message = err_1.message || '네이버 예약 변경에 실패했습니다';
                    if ((_6 = err_1.message) === null || _6 === void 0 ? void 0 : _6.includes('BOOKING_INVALID_DATE')) {
                        message = "\uB124\uC774\uBC84 \uC608\uC57D\uC758 \uC2DC\uAC04\uB2E8\uC704\uB294 \uB124\uC774\uBC84 \uC124\uC815\uC5D0\uB530\uB77C ".concat(((_7 = props.store) === null || _7 === void 0 ? void 0 : _7.naverIsHour) ? '1시간' : '30분', " \uB2E8\uC704\uB85C\uB9CC \uC870\uC815\uC774 \uAC00\uB2A5\uD569\uB2C8\uB2E4.");
                    }
                    else if ((_8 = err_1.message) === null || _8 === void 0 ? void 0 : _8.includes('already completed booking')) {
                        message = '이미 이용완료처리된 예약은 수정할 수 없습니다.';
                    }
                    return [2 /*return*/, notification.error({
                            message: message
                        })];
                case 4:
                    isAvailableAll = !((_9 = formState.dirtyFields) === null || _9 === void 0 ? void 0 : _9.dateOption);
                    data = values.data;
                    if (values.type === 'reservation') {
                        methodsType_2 = (_10 = values.data) === null || _10 === void 0 ? void 0 : _10.methodsType;
                        if (methodsType_2 === 'none') {
                            data.methods = {};
                        }
                        else if (methodsType_2 === 'multiple') {
                            data.methods = values.data.methods;
                        }
                        else {
                            newMethods = {};
                            if ((_11 = values.data) === null || _11 === void 0 ? void 0 : _11.deposit) {
                                if ([
                                    EXTENDED_SALES_METHODS.PREPAID_POINT,
                                    EXTENDED_SALES_METHODS.TRANSFER,
                                    EXTENDED_SALES_METHODS.CASH,
                                    EXTENDED_SALES_METHODS.CARD,
                                    EXTENDED_SALES_METHODS.ETC1,
                                    EXTENDED_SALES_METHODS.ETC2,
                                    EXTENDED_SALES_METHODS.ETC3,
                                    EXTENDED_SALES_METHODS.ETC4,
                                    EXTENDED_SALES_METHODS.ETC5,
                                    EXTENDED_SALES_METHODS.NAVER_PAY
                                ].includes(methodsType_2)) {
                                    newMethods[methodsType_2] = values.data.deposit;
                                }
                                data.methods = newMethods;
                            }
                            else {
                                data.methods = newMethods;
                            }
                        }
                        delete data.methodsType;
                    }
                    _12.label = 5;
                case 5:
                    _12.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, scheduleCreateOrUpdate(__assign(__assign({}, values), { data: data, managerId: values.managerId === -1 ? null : values.managerId, dateOption: __assign(__assign({}, values.dateOption), { startedAt: dayjs(values.dateOption.startedAt).toDate(), endedAt: dayjs(values.dateOption.endedAt).toDate() }) }), props.schedule, store, createSchedule, updateSchedule, createNewRepeatSchedule, isAvailableAll)];
                case 6:
                    newSchedule = (_12.sent());
                    if (!newSchedule) {
                        loader.hide();
                        return [2 /*return*/];
                    }
                    if (modalOffValue === 'details') {
                        replaceFromLastItem({ id: newSchedule.id, type: 'details', schedule: newSchedule });
                    }
                    else {
                        props.onClose();
                    }
                    return [3 /*break*/, 8];
                case 7:
                    err_2 = _12.sent();
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var values = watch();
    var type = values.type;
    var reservationFeeMethods = ((_f = (_e = props.schedule) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.methods)
        ? Object.keys((_g = props.schedule.data) === null || _g === void 0 ? void 0 : _g.methods).filter(Boolean)
        : [];
    var methodsType = reservationFeeMethods.length === 0
        ? 'none'
        : reservationFeeMethods.length === 1
            ? reservationFeeMethods[0]
            : 'multiple';
    var handleChangeType = function (type) {
        var _a, _b, _c, _d, _e, _f, _g;
        methods.reset(__assign(__assign({}, DEFAULT_VALUES), { managerId: ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.managerId)
                ? (_b = props.schedule) === null || _b === void 0 ? void 0 : _b.managerId
                : type === 'reservation'
                    ? ((_c = props.schedule) === null || _c === void 0 ? void 0 : _c.id)
                        ? -1
                        : DEFAULT_VALUES.managerId
                    : DEFAULT_VALUES.managerId, type: type, data: __assign(__assign(__assign({}, (((_e = (_d = store.preference) === null || _d === void 0 ? void 0 : _d.operation) === null || _e === void 0 ? void 0 : _e.defaultDeposit)
                ? {
                    deposit: (_g = (_f = store.preference) === null || _f === void 0 ? void 0 : _f.operation) === null || _g === void 0 ? void 0 : _g.defaultDeposit,
                    hasDeposit: true
                }
                : {})), (props.schedule.data || {})), { methodsType: methodsType }), dateOption: __assign(__assign({}, values.dateOption), { repeat: type === 'reservation' ? null : values.dateOption.repeat }) }), { keepErrors: true });
    };
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        methods.reset(__assign(__assign(__assign({}, DEFAULT_VALUES), props.schedule), { description: ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.description) || '', data: __assign(__assign(__assign({}, (((_c = (_b = store.preference) === null || _b === void 0 ? void 0 : _b.operation) === null || _c === void 0 ? void 0 : _c.defaultDeposit)
                ? {
                    deposit: (_e = (_d = store.preference) === null || _d === void 0 ? void 0 : _d.operation) === null || _e === void 0 ? void 0 : _e.defaultDeposit,
                    hasDeposit: true
                }
                : {})), (props.schedule.data || {})), { methodsType: methodsType }), managerId: ((_f = props.schedule) === null || _f === void 0 ? void 0 : _f.managerId)
                ? (_g = props.schedule) === null || _g === void 0 ? void 0 : _g.managerId
                : type === 'reservation'
                    ? ((_h = props.schedule) === null || _h === void 0 ? void 0 : _h.id)
                        ? -1
                        : DEFAULT_VALUES.managerId
                    : DEFAULT_VALUES.managerId }));
    }, [props.schedule]);
    var renderForms = React.useCallback(function (type) {
        switch (type) {
            case 'reservation':
                return (React.createElement(ReservationForm, { schedule: props.schedule, values: values, methods: methods, isPC: isPC, managers: managers, store: store }));
            case 'off':
            case 'schedule':
                return (React.createElement(ScheduleForm, { schedule: props.schedule, type: type, values: values, methods: methods, isPC: isPC, managers: managers, store: store }));
        }
    }, [props.schedule, values, methods, isPC, managers]);
    var form = (React.createElement(StyledForm, { onSubmit: handleSubmit(onSubmit) },
        React.createElement(Inner, { padding: isPC ? 0 : 20, children: React.createElement(TypeSelector, { disabled: isUpdated, type: type, onChange: handleChangeType }) }),
        isPC && React.createElement(Marginer, { height: 20 }),
        React.createElement(ScrollBar, { style: { height: isPC ? '100%' : props.height - HEADER_AND_TAB, width: '100%' }, options: { overflowBehavior: { x: 'hidden' } } },
            React.createElement(Inner, { padding: isPC ? 0 : 20, children: renderForms(type) }))));
    var submit = (React.createElement(ScheduleDrawerFooterButtons, { right: React.createElement(RegisterButtonWithOptions, { registerType: "schedule", onClick: onSubmit, disabled: !formState.isValid || (isEmpty(formState.dirtyFields) && isUpdated), size: "large", type: "primary", children: "".concat(type === 'reservation' ? '예약' : type === 'schedule' ? '일정' : '휴무').concat(isUpdated ? '수정' : '등록') }), left: React.createElement(AntPrimaryGhostButton, { children: "\uB2EB\uAE30", size: "large", onClick: props.onClose }) }));
    return (React.createElement(WrapperRow, { wrap: false }, isPC ? (React.createElement(React.Fragment, null,
        React.createElement(DrawerLeft, null, form),
        React.createElement(DrawerRight, null,
            React.createElement(RightTop, null),
            submit))) : (React.createElement(DrawerRight, null,
        form,
        submit))));
};
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"], ["\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"])));
var ReservationForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var values = props.values, methods = props.methods, store = props.store, isPC = props.isPC, managers = props.managers, schedule = props.schedule;
    var timestep = (store === null || store === void 0 ? void 0 : store.timeStep) || 30;
    var control = methods.control;
    var productQuery = ProductQuery.useProducts({ storeId: store === null || store === void 0 ? void 0 : store.id, paranoid: false }, { enabled: !!(store === null || store === void 0 ? void 0 : store.id) });
    var customerId = values.customerId;
    var _r = React.useState(false), selectorVisible = _r[0], setSelectorVisible = _r[1];
    var isUpdated = !!values.id;
    var isDeletedCustomer = isUpdated && ((_a = props.schedule) === null || _a === void 0 ? void 0 : _a.customerId) && !((_b = props.schedule) === null || _b === void 0 ? void 0 : _b.customer);
    var customerData = CustomerQuery.useCustomerDetail({ id: customerId, storeId: (_c = props.store) === null || _c === void 0 ? void 0 : _c.id }, { retryOnMount: false, enabled: !!customerId && !!((_d = props.store) === null || _d === void 0 ? void 0 : _d.id) && !isDeletedCustomer });
    React.useEffect(function () {
        var _a;
        if (((_a = customerData.data) === null || _a === void 0 ? void 0 : _a.id) && !values.id) {
            var defaultStatus = CustomerQuery.getCustomerDefaultReservationStatus(customerData.data);
            props.methods.setValue('status', defaultStatus);
        }
    }, [(_e = customerData.data) === null || _e === void 0 ? void 0 : _e.id]);
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var isSaled = !!values.salesHistoryId;
    var isNaver = isUpdated && ((_f = schedule.data) === null || _f === void 0 ? void 0 : _f.provider) !== 'crm';
    /**
     * 소요시간 변경 불가인 경우 - 매출등록시
     */
    var allowTimeTakePicker = !isSaled;
    /**
     * 예약일시 변경 불가인 경우 - 판매완료, N페이사용, 쿠폰사용내역있음(쿠폰있을때는 네이버 예약 수정 불가(쿠폰사용이 되버려서 사용자가 쿠폰을 다시 사용못함),
     */
    var disabledChangedDateOption = schedule.isNPayUsed || !isEmpty((_g = schedule.data) === null || _g === void 0 ? void 0 : _g.bookingCoupons) || isSaled;
    var shouldRenderWarning = !schedule.isNPayUsed && !isEmpty((_h = schedule.data) === null || _h === void 0 ? void 0 : _h.bookingCoupons);
    /**
     * 담당자 변경, 예약상태 변경
     */
    var disableChangeManagerAndStatus = isNaver || isSaled;
    /**
     * 예약 상품 추가 삭제 (공식 네이버 예약, 매출등록된 예약은 불가)
     */
    var disableProductAddRemove = schedule.disabledUpdateProducts && isUpdated;
    /**
     * 예약금 변경 - 매출등록 이전에 가능
     */
    var disableReservationFee = isSaled;
    /**
     * 메모 변경 항상 가능
     */
    var _s = React.useState(), subDrawer = _s[0], setSubDrawer = _s[1];
    var handleProductChange = function (items) {
        var _a, _b;
        var usedTime = sum(items.map(function (product) { return (product === null || product === void 0 ? void 0 : product.time) || 0; })) || 30;
        var startedAt = (_a = values.dateOption) === null || _a === void 0 ? void 0 : _a.startedAt;
        methods.setValue('storeProducts', items);
        if (!((_b = values.dateOption) === null || _b === void 0 ? void 0 : _b.isAllDay)) {
            var newEndedAt = dayjs(startedAt).add(usedTime, 'minute').toDate();
            var endedAt = dayjs(startedAt).isSame(newEndedAt, 'day')
                ? newEndedAt
                : getEndTimeByTimtstep(timestep, dayjs(startedAt));
            methods.setValue('dateOption.endedAt', endedAt, { shouldDirty: true, shouldValidate: true });
        }
        methods.trigger('dateOption');
    };
    var createPaymentHistory = PaymentHistoryQuery.useCreateSales();
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWithIcon, { field: React.createElement(React.Fragment, null,
                React.createElement(HookFormInputs.GrayCustomerSelector, { inputProps: {
                        disabled: (isUpdated && !!((_j = props.schedule) === null || _j === void 0 ? void 0 : _j.customerId)) || isSaled,
                        hideControlButtons: true,
                        isDeletedCustomer: isDeletedCustomer
                    }, controlProps: {
                        name: 'customerId',
                        control: control
                    } }),
                !values.customerId && (React.createElement(Row, { wrap: false, align: "middle", style: { marginTop: '5px' } },
                    React.createElement(Icon, { name: "alert-triangle", color: COLORS.info }),
                    React.createElement(TextComponent, { children: "\uBBF8\uB4F1\uB85D \uACE0\uAC1D\uC73C\uB85C \uC9C4\uD589\uB429\uB2C8\uB2E4.", color: COLORS.info, marginLeft: 5 })))), icon: "smile" }),
        React.createElement(FieldWithIcon, { fluid: true, field: React.createElement(CustomDatepicker, { store: store, disabled: disabledChangedDateOption, allowTimeTakePicker: allowTimeTakePicker, methods: methods, timestep: timestep, controlProps: control, hasTimepicker: true, hasRepeat: false }), icon: "clock" }),
        React.createElement(FieldWithIcon, { fluid: true, field: React.createElement("div", { style: { width: '100%' } }, (_k = values.storeProducts) === null || _k === void 0 ? void 0 :
                _k.map(function (item, index) { return (React.createElement(ItemRow, { product: item, onDelete: function () {
                        if (!disableProductAddRemove) {
                            handleProductChange(values.storeProducts.filter(function (_, targetIndex) { return targetIndex !== index; }));
                        }
                    } })); }),
                React.createElement(ProductOrTicketSelector, { storeId: (_l = props.store) === null || _l === void 0 ? void 0 : _l.id, type: "product", customer: customerData === null || customerData === void 0 ? void 0 : customerData.data, productGroups: (_m = productQuery.data) === null || _m === void 0 ? void 0 : _m.groups, onComplete: function (value) {
                        handleProductChange((values.storeProducts || []).concat((value === null || value === void 0 ? void 0 : value.map(function (item) { return item.product; })) || []));
                    }, naverProductIds: schedule.isOfficialNaverReservation
                        ? Object.keys(((_p = (_o = store.preference) === null || _o === void 0 ? void 0 : _o.smartplace) === null || _p === void 0 ? void 0 : _p.options) || {})
                            .map(function (key) {
                            var _a, _b, _c, _d;
                            return ((_b = (_a = store.preference) === null || _a === void 0 ? void 0 : _a.smartplace) === null || _b === void 0 ? void 0 : _b.options[key].agencyKey)
                                ? Number((_d = (_c = store.preference) === null || _c === void 0 ? void 0 : _c.smartplace) === null || _d === void 0 ? void 0 : _d.options[key].agencyKey)
                                : null;
                        })
                            .filter(Boolean) || []
                        : null, onClickPurchaseTicket: function () {
                        setSubDrawer({ type: 'ticket', data: { customerId: customerId } });
                    }, visible: selectorVisible, onClose: function () { return setSelectorVisible(false); }, trigger: disableProductAddRemove ? (React.createElement("div", null)) : (React.createElement(ButtonWithPlusCircle, { style: { paddingLeft: '0px' }, children: "\uC0C1\uD488\uC120\uD0DD", onClick: function () { return setSelectorVisible(true); } })) })), icon: "gift" }),
        React.createElement(FieldWithIcon, { field: React.createElement(HookFormInputs.GraySelector, { controlProps: {
                    name: "managerId",
                    control: methods.control
                }, inputProps: {
                    size: 'large',
                    disabled: disableChangeManagerAndStatus,
                    placeholder: '담당자 선택',
                    onChange: function () {
                        methods.trigger('dateOption');
                    },
                    options: managers
                        .map(function (manager) {
                        var _a;
                        return {
                            value: manager.id,
                            disabled: !manager.isWorking,
                            label: (React.createElement(Row, { align: "middle", style: { height: '100%' } },
                                React.createElement(Rectangle, { width: 12, color: (_a = manager.profile) === null || _a === void 0 ? void 0 : _a.color }),
                                React.createElement(TextComponent, { children: manager.displayName, marginLeft: 5 })))
                        };
                    })
                        .concat({
                        value: -1,
                        label: (React.createElement(Row, { align: "middle", style: { height: '100%' } },
                            React.createElement(Rectangle, { width: 12, color: COLORS.staff1 }),
                            React.createElement(TextComponent, { children: '담당자 없음', marginLeft: 5 })))
                    })
                } }), icon: "users" }),
        React.createElement(FieldWithIcon, { field: React.createElement(ReservationFeeInput, { store: store, methods: methods, disabled: disableReservationFee, isPC: isPC }), icon: "dollar" }),
        React.createElement(FieldWithIcon, { field: React.createElement(HookFormInputs.GraySelector, { controlProps: {
                    name: "status",
                    control: methods.control
                }, inputProps: {
                    size: 'large',
                    disabled: disableChangeManagerAndStatus,
                    options: [
                        RESERVATION_STATUS.CONFIRMED,
                        isSaled && 'sales',
                        RESERVATION_STATUS.PENDING,
                        isNaver && RESERVATION_STATUS.COMPLETED,
                        RESERVATION_STATUS.NOSHOW,
                        RESERVATION_STATUS.CANCELLED_CUSTOMR,
                        RESERVATION_STATUS.CANCELLED_STORE
                    ]
                        .filter(Boolean)
                        .map(function (status) {
                        return {
                            value: status,
                            disabled: status === 'sales' || values.status === 'sales',
                            label: (React.createElement(Row, { align: "middle", style: { height: '100%' } },
                                React.createElement(ScheduleStatusTag, { schedule: { status: status } })))
                        };
                    })
                } }), icon: "award" }),
        React.createElement(FieldWithIcon, { fluid: true, field: React.createElement(HookFormInputs.GrayTextarea, { controlProps: {
                    name: "description",
                    control: methods.control
                }, inputProps: {
                    placeholder: '메모 입력'
                } }), icon: "file-text" }),
        shouldRenderWarning && (React.createElement(AlertComponent, { showIcon: true, message: "\uACE0\uAC1D\uC774 \uCFE0\uD3F0\uC744 \uC801\uC6A9\uD55C \uC608\uC57D\uC785\uB2C8\uB2E4.", description: [
                '고객이 쿠폰을 적용한 예약은 메모변경만 가능합니다. 이외의 변경은 스마트플레이스 관리자페이지를 이용해주세요.'
            ] })),
        React.createElement(SubRegisterDrawer, { loginManager: loginManager, data: subDrawer, zIndex: 1001, storeId: (_q = props.store) === null || _q === void 0 ? void 0 : _q.id, createPaymentHistory: createPaymentHistory.mutateAsync, onClose: function () {
                setSubDrawer(undefined);
            }, onComplete: function () {
                var _a, _b;
                QUERY_CACHE.invalidateQueries(['customers', { id: (_a = subDrawer === null || subDrawer === void 0 ? void 0 : subDrawer.data) === null || _a === void 0 ? void 0 : _a.customerId, storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id }]);
            } })));
};
var ScheduleForm = function (props) {
    var values = props.values, methods = props.methods, store = props.store, isPC = props.isPC, managers = props.managers, type = props.type;
    var control = methods.control;
    var timestep = store.timeStep;
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldWithIcon, { field: React.createElement(CustomDatepicker, { store: store, methods: methods, timestep: timestep, controlProps: control, hasRepeat: true, hasTimepicker: type === 'schedule' }), icon: "clock" }),
        React.createElement(FieldWithIcon, { field: React.createElement(HookFormInputs.GraySelector, { controlProps: {
                    name: "managerId",
                    control: methods.control
                }, inputProps: {
                    size: 'large',
                    placeholder: '담당자 선택',
                    options: [
                        props.type === 'off' && {
                            value: -1,
                            label: (React.createElement(Row, { align: "middle", style: { height: '100%' }, wrap: false },
                                React.createElement(ColorTag, { children: "\uC0C1\uC810\uD734\uBB34" })))
                        }
                    ]
                        .concat(managers.map(function (manager) {
                        var _a;
                        return {
                            value: manager.id,
                            disabled: !manager.isWorking,
                            label: (React.createElement(Row, { align: "middle", style: { height: '100%' } },
                                React.createElement(Rectangle, { width: 12, color: (_a = manager.profile) === null || _a === void 0 ? void 0 : _a.color }),
                                React.createElement(TextComponent, { children: manager.displayName, marginLeft: 5 })))
                        };
                    }))
                        .filter(Boolean)
                } }), icon: "users" }),
        React.createElement(FieldWithIcon, { noMarginBottom: true, fluid: true, field: React.createElement(HookFormInputs.GrayText, { controlProps: {
                    name: "name",
                    control: methods.control
                }, inputProps: {
                    placeholder: "".concat(props.type === 'off' ? '휴무' : '일정', " \uC81C\uBAA9 \uC785\uB825")
                } }), icon: "file-text" }),
        React.createElement(FieldWithIcon, { fluid: true, field: React.createElement(TempDescriptionArea, { methods: methods, name: "description" }), icon: "file-text" })));
};
var ItemRow = function (props) {
    var _a, _b;
    return (React.createElement(StyledRow, { justify: "space-between", align: "middle" },
        React.createElement(TextComponent, { children: (_a = props.product) === null || _a === void 0 ? void 0 : _a.nameWithGroupName }),
        React.createElement(Row, { wrap: true, align: "middle" },
            React.createElement(TextComponent, { children: (_b = props.product) === null || _b === void 0 ? void 0 : _b.priceWithTime, color: COLORS.gray3, marginRight: 10 }),
            React.createElement(Icon, { color: COLORS.calendarRedActive, name: "x", onClick: props.onDelete }))));
};
var StyledRow = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 4px;\n  box-shadow: ", ";\n  border: solid 1px var(--gray7);\n  margin-bottom: 8px;\n  padding-left: 18px;\n  padding-right: 18px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n"], ["\n  border-radius: 4px;\n  box-shadow: ", ";\n  border: solid 1px var(--gray7);\n  margin-bottom: 8px;\n  padding-left: 18px;\n  padding-right: 18px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n"])), BOX_SHADOW);
var ReservationFeeInput = function (props) {
    var _a;
    var methods = props.methods;
    var data = methods.watch('data');
    var customerId = methods.watch('customerId');
    var hasDeposit = data === null || data === void 0 ? void 0 : data.hasDeposit;
    var options = Object.keys((_a = props.store) === null || _a === void 0 ? void 0 : _a.paymentMethods)
        .map(function (key) {
        var _a, _b, _c, _d;
        if ([
            EXTENDED_SALES_METHODS.DISCOUNT,
            EXTENDED_SALES_METHODS.DEDUCTION_PRICE_BY_TICKET,
            EXTENDED_SALES_METHODS.UNPAID,
            EXTENDED_SALES_METHODS.CUSTOMER_POINT
        ].includes(key) ||
            (key === EXTENDED_SALES_METHODS.PREPAID_POINT && !customerId)) {
            return;
        }
        return (((_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.paymentMethods[key]) === null || _b === void 0 ? void 0 : _b.active) && {
            name: (_d = (_c = props.store) === null || _c === void 0 ? void 0 : _c.paymentMethods[key]) === null || _d === void 0 ? void 0 : _d.name,
            value: key
        });
    })
        .filter(Boolean);
    React.useEffect(function () {
        if (!customerId && (data === null || data === void 0 ? void 0 : data.methodsType) === EXTENDED_SALES_METHODS.PREPAID_POINT) {
            methods.setValue("data.methodsType", 'none');
        }
    }, [customerId]);
    return (React.createElement(Row, { align: "top", wrap: true, justify: "center", gutter: 4, style: { height: props.isPC ? '32px' : '60px' } },
        React.createElement(Col, { span: props.isPC && hasDeposit ? 8 : 24 },
            React.createElement(Row, { align: "middle", style: props.isPC ? {} : { marginBottom: '10px' } },
                React.createElement(TextComponent, { children: "\uC608\uC57D\uAE08 \uC124\uC815", marginRight: 5 }),
                React.createElement(Switch, { disabled: props.disabled, onChange: function (hasDeposit) {
                        if (hasDeposit) {
                            methods.setValue('data', __assign(__assign({}, data), { hasDeposit: hasDeposit }), { shouldValidate: true, shouldDirty: true });
                        }
                        else {
                            methods.setValue('data', __assign(__assign({}, data), { deposit: 0, hasDeposit: hasDeposit }), { shouldValidate: true, shouldDirty: true });
                        }
                    }, checked: data === null || data === void 0 ? void 0 : data.hasDeposit }))),
        hasDeposit && (React.createElement(React.Fragment, null,
            React.createElement(Col, { span: props.isPC ? 8 : 12 },
                React.createElement(HookFormInputs.GrayNumberInput, { controlProps: {
                        control: methods.control,
                        name: "data.deposit",
                        rules: {
                        // min: { message: '예약금을 입력해주세요. ', value: 0 }
                        }
                    }, inputProps: {
                        size: 'large',
                        suffix: '원',
                        disabled: props.disabled,
                        placeholder: '예약금 입력'
                    } })),
            React.createElement(Col, { span: props.isPC ? 8 : 12 },
                React.createElement(HookFormInputs.GraySelector, { key: "".concat(customerId, "_prepaid"), controlProps: {
                        control: methods.control,
                        name: "data.methodsType"
                    }, inputProps: {
                        size: 'large',
                        disabled: props.disabled || data.methodsType === 'multiple',
                        style: { width: '100%' },
                        placeholder: '예약금 입력',
                        options: options
                            .filter(function (option) { return !!option.name; })
                            .map(function (option) {
                            return {
                                label: "".concat(option.name, " \uBC1B\uC74C"),
                                value: option.value
                            };
                        })
                            .concat([
                            { label: '예약금입금 안함', value: 'none' },
                            { label: '복수 선택', value: 'multiple', disabled: true }
                        ])
                    } }))))));
};
var CustomDatepicker = function (props) {
    var _a;
    var store = props.store;
    var _b = React.useState([]), warnings = _b[0], setWarnings = _b[1];
    var _c = props.methods.getValues(), id = _c.id, managerId = _c.managerId;
    var _d = React.useState(), cache = _d[0], setCache = _d[1];
    var isEqualParams = function (origin, newParams) {
        return isEqual({
            startedAt: dayjs(origin.startedAt).toDate(),
            endedAt: dayjs(origin.endedAt).toDate()
        }, {
            startedAt: dayjs(newParams.startedAt).toDate(),
            endedAt: dayjs(newParams.endedAt).toDate()
        });
    };
    var _e = useController({
        name: 'dateOption',
        control: props.controlProps,
        rules: {
            validate: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                var params, warnings, isInBreakTimeStart, isInBreakTimeEnd, isInOperatingHourStart, isInOperatingHourEnd, shouldChange, result, manager, reservations, schedules, hasStoreOff, managerOff, hasManagerSchedule, hasManagerSchedule, error_1;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            params = {
                                startedAt: dayjs(value.startedAt).toDate(),
                                endedAt: dayjs(value.endedAt).toDate()
                            };
                            if (!value.startedAt || !value.endedAt) {
                                return [2 /*return*/, '시작시간과 종료시간이 모두 입력되야합니다.'];
                            }
                            if (dayjs(value.startedAt).isSame(value.endedAt, 'minute') || dayjs(value.startedAt).isAfter(value.endedAt)) {
                                if (dayjs(value.startedAt).hour() !== 0 &&
                                    dayjs(value.startedAt).minute() !== 0 &&
                                    dayjs(value.endedAt).hour() !== 0 &&
                                    dayjs(value.endedAt).minute() !== 0) {
                                    return [2 /*return*/, '종료시간을 시작시간 이후로 입력해주세요.'];
                                }
                            }
                            if (!dayjs(value.startedAt).isSame(value.endedAt, 'day')) {
                                return [2 /*return*/, '종료일이 시작일 다음날이 될 수 없습니다.'];
                            }
                            warnings = [];
                            isInBreakTimeStart = store.isBreakTime(value.startedAt, '[)');
                            isInBreakTimeEnd = store.isBreakTime(value.endedAt, '(]');
                            if (isInBreakTimeStart || isInBreakTimeEnd) {
                                warnings.push('선택한 시간은 점심시간입니다.');
                            }
                            isInOperatingHourStart = store.isStoreOperatingHour(value.startedAt, '[)');
                            isInOperatingHourEnd = store.isStoreOperatingHour(value.endedAt, '(]');
                            if (!isInOperatingHourStart || !isInOperatingHourEnd) {
                                warnings.push('선택한 시간은 예약가능시간(캘린더 설정시간)이 아닙니다.');
                            }
                            shouldChange = false;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 6, , 7]);
                            if (!(store === null || store === void 0 ? void 0 : store.id)) return [3 /*break*/, 5];
                            if (!(!(cache === null || cache === void 0 ? void 0 : cache.params) || !isEqualParams(cache === null || cache === void 0 ? void 0 : cache.params, params))) return [3 /*break*/, 3];
                            return [4 /*yield*/, checkFromServer((store === null || store === void 0 ? void 0 : store.id) || 1, {
                                    id: typeof id === 'number' ? id : null,
                                    dateOption: value
                                })];
                        case 2:
                            result = _b.sent();
                            shouldChange = true;
                            return [3 /*break*/, 4];
                        case 3:
                            result = cache.result;
                            _b.label = 4;
                        case 4:
                            manager = ManagerQuery.getManagerByIdInCache(managerId);
                            reservations = ((_a = result.reservation) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !item.isCancelled; })) || [];
                            schedules = result.schedule || [];
                            if (!isEmpty(schedules)) {
                                hasStoreOff = schedules.find(function (item) { return ScheduleModel.RESERVATION_TYPE.OFF && !item.managerId; });
                                managerOff = schedules.find(function (item) { return managerId && item.type === ScheduleModel.RESERVATION_TYPE.OFF && item.managerId === managerId; });
                                if (hasStoreOff) {
                                    warnings.push("\uC120\uD0DD\uD55C \uC2DC\uAC04\uC740 \uC0C1\uC810 \uD734\uBB34\uC77C\uC785\uB2C8\uB2E4.");
                                }
                                if (managerOff) {
                                    warnings.push("\uC120\uD0DD\uD55C \uC2DC\uAC04\uC740 ".concat(manager.displayName, "\uC758 \uD734\uBB34\uC77C\uC785\uB2C8\uB2E4."));
                                }
                                hasManagerSchedule = schedules.find(function (item) {
                                    return managerId &&
                                        item.type === ScheduleModel.RESERVATION_TYPE.SCHEDULE &&
                                        item.managerId === managerId &&
                                        (item.isIncludeStartAndEnd(value.startedAt) || item.isIncludeStartAndEnd(value.endedAt));
                                });
                                if (hasManagerSchedule) {
                                    warnings.push("\uC120\uD0DD\uD55C \uC2DC\uAC04\uC740 ".concat(manager.displayName, "\uC758 \uC77C\uC815\uC774 \uC788\uC2B5\uB2C8\uB2E4."));
                                }
                            }
                            if (!isEmpty(reservations)) {
                                hasManagerSchedule = reservations.find(function (item) {
                                    return managerId && item.type === ScheduleModel.RESERVATION_TYPE.RESERVATION && item.managerId === managerId;
                                });
                                if (hasManagerSchedule) {
                                    warnings.push("\uC120\uD0DD\uD55C \uC2DC\uAC04\uC740 ".concat(manager.displayName, " \uB2F4\uB2F9\uC758 \uC608\uC57D\uC774 \uC788\uC2B5\uB2C8\uB2E4."));
                                }
                            }
                            _b.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            error_1 = _b.sent();
                            return [2 /*return*/, true];
                        case 7:
                            setWarnings(warnings);
                            if (shouldChange) {
                                setCache({
                                    params: params,
                                    result: result
                                });
                            }
                            return [2 /*return*/, true];
                    }
                });
            }); }
        }
    }), field = _e.field, fieldState = _e.fieldState;
    var value = (props.methods.watch('dateOption') || field.value);
    var handleDateChangeWithValidate = function (value) {
        field.onChange(value);
        props.methods.trigger(['dateOption']);
    };
    var handleDateChange = function (date) {
        var repeat = value.repeat;
        if (repeat && !dayjs(value.startedAt).isSame(date, 'day')) {
            repeat = ScheduleModel.convertRepeatBySelectedDate(date, value.startedAt, value.repeat);
        }
        if (value.isAllDay) {
            handleDateChangeWithValidate(__assign(__assign({}, value), { repeat: repeat, startedAt: getStartAndEndTimeByStore(dayjs(date), store, 'start'), endedAt: getStartAndEndTimeByStore(dayjs(date), store, 'end') }));
        }
        else {
            var start = dayjs(value.startedAt);
            var end = dayjs(value.endedAt);
            handleDateChangeWithValidate(__assign(__assign({}, value), { repeat: repeat, startedAt: dayjs(date).set('hour', start.hour()).set('minute', start.minute()), endedAt: dayjs(date).set('hour', end.hour()).set('minute', end.minute()) }));
        }
    };
    var handleHourChange = function (type, newDate) {
        if (type === 'startedAt') {
            var diff = dayjs(value.endedAt).diff(value.startedAt, 'minute');
            var newEndedAt = diff > 0
                ? dayjs(value.startedAt).isSame(dayjs(newDate).add(diff, 'minute'), 'day')
                    ? dayjs(newDate).add(diff, 'minute')
                    : getEndTimeByTimtstep(props.timestep, newDate)
                : dayjs(value.endedAt);
            handleDateChangeWithValidate(__assign(__assign({}, value), { startedAt: newDate, endedAt: newEndedAt }));
        }
        else {
            handleDateChangeWithValidate(__assign({}, set(value, type, newDate)));
        }
    };
    var interval = dayjs(value.endedAt).diff(value.startedAt, 'minute');
    var hh = floor(interval / 60);
    var mm = interval % 60;
    var isWarning = !isEmpty(warnings);
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomDatepickerWrapper, { "$isWarning": isWarning },
            React.createElement(CustomDatepickerControlWrapper, null,
                React.createElement(StyledDatepicker, { inputReadOnly: true, value: dayjs(value.startedAt), onChange: handleDateChange, allowClear: false, disabled: props.disabled }),
                props.hasTimepicker && (React.createElement(TimerangePicker, { isWarning: isWarning, timestep: props.timestep, disabled: !!value.isAllDay || props.disabled, value: { startedAt: dayjs(value.startedAt), endedAt: dayjs(value.endedAt) }, onChange: handleHourChange })),
                props.hasTimepicker && (React.createElement(TimeTakePicker, { isWarning: isWarning, timestep: props.timestep, disabled: (!!value.isAllDay || props.disabled) && !props.allowTimeTakePicker, value: {
                        startedAt: dayjs(value.startedAt),
                        hh: hh,
                        mm: mm
                    }, onChange: handleHourChange })),
                props.hasTimepicker && (React.createElement(Checkbox, { children: "\uC885\uC77C", disabled: props.disabled, checked: value.isAllDay, onChange: function (e) {
                        handleDateChangeWithValidate(__assign(__assign({}, value), { isAllDay: e.target.checked, startedAt: getStartAndEndTimeByStore(dayjs(value.startedAt), store, 'start'), endedAt: getStartAndEndTimeByStore(dayjs(value.startedAt), store, 'end') }));
                    } }))),
            props.hasRepeat && !props.disabled && (React.createElement(RepeatController, { value: value, onChange: handleDateChangeWithValidate }))),
        fieldState.error ? (React.createElement(TextComponent, { color: COLORS.calendarRedActive, children: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message })) : (React.createElement("div", null)),
        isWarning ? (warnings.map(function (warning, index) { return React.createElement(TextComponent, { key: index, color: COLORS.warning, children: "- ".concat(warning) }); })) : (React.createElement("div", null))));
};
var TimerangePicker = function (props) {
    var _a = props.value, startedAt = _a.startedAt, endedAt = _a.endedAt;
    var handleMinuteChange = function (type, mm) {
        props.onChange(type, props.value[type]
            .set('minute', mm)
            .set('year', startedAt.year())
            .set('month', startedAt.month())
            .set('date', startedAt.date()));
    };
    var handleHourChange = function (type, hh) {
        props.onChange(type, props.value[type]
            .set('hour', hh)
            .set('year', startedAt.year())
            .set('month', startedAt.month())
            .set('date', startedAt.date()));
    };
    return (React.createElement(DatepickerElementWrapper, { "$isWarning": props.isWarning },
        React.createElement(HourSelector, { disabled: props.disabled, value: startedAt, onChange: function (hh) { return handleHourChange('startedAt', hh); } }),
        React.createElement(TextComponent, { children: ':', color: props.disabled ? COLORS.gray4 : COLORS.black }),
        React.createElement(MinuteSelector, { timestep: props.timestep, disabled: props.disabled, value: startedAt, onChange: function (mm) { return handleMinuteChange('startedAt', mm); } }),
        React.createElement(TextComponent, { children: '-', color: props.disabled ? COLORS.gray4 : COLORS.black }),
        React.createElement(HourSelector, { disabled: props.disabled, value: endedAt, onChange: function (hh) { return handleHourChange('endedAt', hh); } }),
        React.createElement(TextComponent, { children: ':', color: props.disabled ? COLORS.gray4 : COLORS.black }),
        React.createElement(MinuteSelector, { timestep: props.timestep, disabled: props.disabled, value: endedAt, onChange: function (mm) { return handleMinuteChange('endedAt', mm); } })));
};
var getEndTimeByTimtstep = function (timestep, day) {
    return day.set('hour', 23).set('minute', timestep === 1 ? 59 : timestep === 10 ? 50 : 30);
};
var getStartAndEndTimeByStore = function (date, store, type) {
    var day = dayjs(date).day();
    var dateNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var getTime = function (hhmm) {
        return dayjs("".concat(dayjs(date).format('YYYY-MM-DD'), " ").concat(hhmm));
    };
    var operationHour = store === null || store === void 0 ? void 0 : store.operatingHour[dateNames[day]];
    return (operationHour === null || operationHour === void 0 ? void 0 : operationHour.isActive)
        ? getTime(operationHour[type])
        : type === 'start'
            ? getTime('09:00')
            : getTime('18:00');
};
var TimeTakePicker = function (props) {
    var _a = props.value, hh = _a.hh, mm = _a.mm, startedAt = _a.startedAt;
    var handleMinuteChange = function (mm) {
        var newValue = startedAt.add(hh, 'hour').add(mm, 'minute');
        if (!newValue.isSame(startedAt, 'day')) {
            newValue = getEndTimeByTimtstep(props.timestep, startedAt);
        }
        props.onChange('endedAt', newValue);
    };
    var handleHourChange = function (hh) {
        var newValue = startedAt.add(hh, 'hour').add(mm, 'minute');
        if (!newValue.isSame(startedAt, 'day')) {
            newValue = newValue = getEndTimeByTimtstep(props.timestep, startedAt);
        }
        props.onChange('endedAt', newValue);
    };
    var isInvalid = hh < 0 || mm < 0;
    var getLabel = function (mm) {
        return isInvalid ? '-' : mm >= 10 ? "".concat(mm) : "0".concat(mm);
    };
    var hours = times(24).map(function (value) { return ({
        label: getLabel(value),
        value: value
    }); });
    var minuteStep = props.timestep;
    var minuteItems = (minuteStep === 1 ? times(60).map(function (value) { return value; }) : minuteStep === 10 ? [0, 10, 20, 30, 40, 50] : [0, 30]).map(function (value) {
        return {
            label: getLabel(value),
            value: value
        };
    });
    return (React.createElement(DatepickerElementWrapper, { "$isWarning": props.isWarning },
        React.createElement(Row, { wrap: false, align: "middle" },
            React.createElement(ScolledDropdown, { disabled: props.disabled, items: hours, value: hh, getLabel: getLabel, onChange: handleHourChange }),
            React.createElement(TextComponent, { children: ':', color: props.disabled ? COLORS.gray4 : COLORS.black }),
            React.createElement(ScolledDropdown, { disabled: props.disabled, items: minuteItems, value: mm, getLabel: getLabel, onChange: handleMinuteChange }),
            React.createElement(TextComponent, { children: '소요', color: props.disabled ? COLORS.gray4 : COLORS.black }))));
};
var HourSelector = function (props) {
    var _a;
    var getLabel = function (value) {
        var isAfternoon = value >= 12;
        var hour = value > 12 ? value - 12 : value;
        return "".concat(isAfternoon ? '오후' : '오전', " ").concat(hour < 10 ? "0".concat(hour) : hour);
    };
    var hours = times(24).map(function (value) { return ({
        label: getLabel(value),
        value: value
    }); });
    var hh = ((_a = props.value) === null || _a === void 0 ? void 0 : _a.hour()) || 0;
    return (React.createElement(ScolledDropdown, { disabled: props.disabled, items: hours, value: hh, getLabel: getLabel, onChange: props.onChange }));
};
var MinuteSelector = function (props) {
    var _a;
    var mm = ((_a = props.value) === null || _a === void 0 ? void 0 : _a.minute()) || 0;
    var getLabel = function (mm) {
        return mm >= 10 ? "".concat(mm) : "0".concat(mm);
    };
    var minuteStep = props.timestep;
    var minuteItems = (minuteStep === 1 ? times(60).map(function (value) { return value; }) : minuteStep === 10 ? [0, 10, 20, 30, 40, 50] : [0, 30]).map(function (value) {
        return {
            label: getLabel(value),
            value: value
        };
    });
    return (React.createElement(ScolledDropdown, { disabled: props.disabled, items: minuteItems, value: mm, getLabel: getLabel, onChange: props.onChange }));
};
var ScolledDropdown = function (props) {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    return (React.createElement(Dropdown, { disabled: props.disabled, trigger: ['click'], onOpenChange: function (visible) {
            setOpen(visible);
        }, dropdownRender: function () {
            return (React.createElement(StyleOverlayscrollComponent, { open: open, items: props.items, onChange: props.onChange, value: props.value }));
        }, destroyPopupOnHide: false },
        React.createElement(ButtonInTimepicker, { children: props.getLabel(props.value) })));
};
var StyleOverlayscrollComponent = function (props) {
    var ref = React.useRef();
    var height = 29;
    var index = props.items.findIndex(function (item) { return item.value === props.value; });
    React.useEffect(function () {
        var _a;
        if (props.open) {
            setTimeout(function () {
                var _a;
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.osInstance().scroll({ y: height * index });
            }, 200);
        }
        else {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.osInstance().scroll({ y: 0 });
        }
    }, [props.open]);
    return (React.createElement(StyleOverlayscroll, { ref: ref, options: {
            overflowBehavior: {
                y: 'scroll'
            }
        } },
        React.createElement(StyleUl, null, props.items.map(function (item) { return (React.createElement(StyledLi, { "aria-checked": item.value === props.value, key: item.value, onClick: function () {
                props.onChange(item.value);
            } }, item.label)); }))));
};
var StyleOverlayscroll = styled(OverlayScrollbarsComponent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 201px;\n"], ["\n  height: 201px;\n"])));
var StyleUl = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n  width: 70px;\n  padding: 0px;\n"], ["\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n  width: 70px;\n  padding: 0px;\n"])), COLORS.gray6, COLORS.gray7);
var StyledLi = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 25px;\n  margin: 5px;\n  width: 60px;\n  padding-bottom: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 2px;\n\n  background-color: ", ";\n  color: ", ";\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  height: 25px;\n  margin: 5px;\n  width: 60px;\n  padding-bottom: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 2px;\n\n  background-color: ", ";\n  color: ", ";\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return (props['aria-checked'] ? COLORS.primary1 : COLORS.gray7); }, function (props) { return (props['aria-checked'] ? COLORS.white : COLORS.black); }, COLORS.primary1, COLORS.white);
var ButtonInTimepicker = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: 0px !important;\n  background-color: ", " !important;\n  display: flex;\n  align-items: center;\n  padding: 0px;\n\n  &.ant-btn:focus {\n    background-color: ", " !important;\n\n    & > span {\n      background-color: ", ";\n      color: ", ";\n    }\n  }\n\n  &.ant-btn:hover,\n  &.ant-btn:active {\n    background-color: ", " !important;\n  }\n  & > span {\n    border-radius: 2px;\n    padding-left: 5px;\n    padding-right: 5px;\n  }\n"], ["\n  border: 0px !important;\n  background-color: ", " !important;\n  display: flex;\n  align-items: center;\n  padding: 0px;\n\n  &.ant-btn:focus {\n    background-color: ", " !important;\n\n    & > span {\n      background-color: ", ";\n      color: ", ";\n    }\n  }\n\n  &.ant-btn:hover,\n  &.ant-btn:active {\n    background-color: ", " !important;\n  }\n  & > span {\n    border-radius: 2px;\n    padding-left: 5px;\n    padding-right: 5px;\n  }\n"])), COLORS.gray6, COLORS.gray6, COLORS.primary1, COLORS.white, COLORS.gray6);
var CustomDatepickerWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  & .ant-picker-focused {\n    border-right-width: 0px !important;\n  }\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  & .ant-picker-focused {\n    border-right-width: 0px !important;\n  }\n  ", "\n"])), function (props) {
    return (props.$isWarning &&
        "\n    & .ant-dropdown-trigger > button {\n      border: 1px solid ".concat(COLORS.warning, " !important;\n    }\n    & .ant-picker {\n      border: 1px solid ").concat(COLORS.warning, " !important;\n      border-right-width: 1px solid ").concat(COLORS.warning, " !important;\n    }\n    & .ant-picker-focused {\n      border-right-width: 0px !important;\n    }\n    "));
});
var CustomDatepickerControlWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  & > * {\n    margin-bottom: 5px;\n    margin-right: 5px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n  & > * {\n    margin-bottom: 5px;\n    margin-right: 5px;\n  }\n"])));
var DatepickerElementWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 36px;\n  border-radius: 2px;\n  background-color: var(--gray-6);\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  padding-left: 4px;\n  padding-right: 4px;\n  ", "\n"], ["\n  height: 36px;\n  border-radius: 2px;\n  background-color: var(--gray-6);\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  padding-left: 4px;\n  padding-right: 4px;\n  ", "\n"])), function (props) {
    return (props.$isWarning &&
        "\n    border: 1px solid ".concat(COLORS.warning, " !important;\n    "));
});
var StyledDatepicker = styled(DatePicker).attrs({ suffixIcon: null, format: 'YYYY-MM-DD(ddd)' })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  height: 36px;\n  width: 120px;\n  min-width: 120px;\n  border-radius: 2px;\n  background-color: var(--gray-6);\n  border-color: var(--gray-6);\n\n  & input {\n    color: ", ";\n  }\n  &.ant-picker-focused {\n    border-color: var(--gray-6);\n    box-shadow: 0 0 0 0px rgb(150 117 248 / 0%);\n    outline: 0;\n  }\n"], ["\n  height: 36px;\n  width: 120px;\n  min-width: 120px;\n  border-radius: 2px;\n  background-color: var(--gray-6);\n  border-color: var(--gray-6);\n\n  & input {\n    color: ", ";\n  }\n  &.ant-picker-focused {\n    border-color: var(--gray-6);\n    box-shadow: 0 0 0 0px rgb(150 117 248 / 0%);\n    outline: 0;\n  }\n"])), COLORS.black);
var TypeSelector = function (props) {
    var items = [
        { key: ScheduleModel.RESERVATION_TYPE.RESERVATION, label: '예약' },
        { key: ScheduleModel.RESERVATION_TYPE.SCHEDULE, label: '일정' },
        { key: ScheduleModel.RESERVATION_TYPE.OFF, label: '휴무' }
    ];
    return (React.createElement(Row, { justify: "start" }, items.map(function (item) {
        return (React.createElement(TypeItem, { disabled: props.disabled, key: item.key, "$isActive": item.key === props.type, children: item.label, onClick: function () {
                if (!props.disabled) {
                    props.onChange(item.key);
                }
            } }));
    })));
};
var TypeItem = styled(Button)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", "\n  width: 60px;\n  height: 40px;\n  border-radius: 6px;\n  border: 0px;\n  margin-right: 8px;\n  color: ", ";\n  background-color: ", ";\n\n  &.ant-btn:hover,\n  &.ant-btn:focus,\n  &.ant-btn:active {\n    background-color: ", ";\n  }\n\n  &.ant-btn[disabled],\n  &.ant-btn[disabled]:hover,\n  &.ant-btn[disabled]:focus,\n  &.ant-btn[disabled]:active {\n    color: ", ";\n    border-color: ", ";\n    background: ", ";\n    text-shadow: none;\n    box-shadow: none;\n  }\n"], ["\n  ", "\n  width: 60px;\n  height: 40px;\n  border-radius: 6px;\n  border: 0px;\n  margin-right: 8px;\n  color: ", ";\n  background-color: ", ";\n\n  &.ant-btn:hover,\n  &.ant-btn:focus,\n  &.ant-btn:active {\n    background-color: ", ";\n  }\n\n  &.ant-btn[disabled],\n  &.ant-btn[disabled]:hover,\n  &.ant-btn[disabled]:focus,\n  &.ant-btn[disabled]:active {\n    color: ", ";\n    border-color: ", ";\n    background: ", ";\n    text-shadow: none;\n    box-shadow: none;\n  }\n"])), function () { return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), typographyByName('headline3')); }, function (props) { return (props.$isActive ? COLORS.primary1 : COLORS.gray4); }, function (props) { return (props.$isActive ? COLORS.primary5 : COLORS.white); }, function (props) { return (props.$isActive ? COLORS.primary5 : COLORS.white); }, function (props) { return (props.$isActive ? COLORS.primary1 : COLORS.gray4); }, function (props) { return (props.$isActive ? COLORS.primary5 : COLORS.white); }, function (props) { return (props.$isActive ? COLORS.primary5 : COLORS.white); });
var RepeatController = function (props) {
    var _a, _b, _c;
    var startedAt = dayjs(props.value.startedAt);
    //   Returns numbers from 0 (Sunday) to 6 (Saturday).
    var days = startedAt.day();
    var weekNumber = startedAt.diff(startedAt.startOf('month'), 'weeks') + 1;
    var date = startedAt.date();
    var items = [
        { unit: 'none' },
        { unit: 'week', data: { interval: 1, days: [0, 1, 2, 3, 4, 5, 6] } },
        { unit: 'week', data: { interval: 1, days: [1, 2, 3, 4, 5] } },
        { unit: 'week', data: { interval: 1, days: [days] } },
        { unit: 'month', data: { interval: 1, th: weekNumber, days: [days] } },
        { unit: 'month', data: { interval: 1, date: date } },
        { unit: 'year', data: { interval: 1, month: startedAt.month(), date: date } }
    ].map(function (item) { return ({ value: item, label: ScheduleModel.getRepeatString(item) }); });
    var defaultValues = ((_a = props.value) === null || _a === void 0 ? void 0 : _a.repeat)
        ? (_b = props.value) === null || _b === void 0 ? void 0 : _b.repeat
        : {
            unit: 'week',
            data: {
                interval: 1,
                days: [startedAt.day()]
            }
        };
    var modalForm = useForm({
        defaultValues: defaultValues
    });
    var onSubmit = function (data) { return console.log(data); };
    var modalValues = modalForm.watch();
    var repeat = (_c = props.value) === null || _c === void 0 ? void 0 : _c.repeat;
    //   const repeatObject = items.find((item) => isEqual(item.value, repeat)) || { label: '반복안함' };
    var dayArray = [
        { value: 1, label: '월' },
        { value: 2, label: '화' },
        { value: 3, label: '수' },
        { value: 4, label: '목' },
        { value: 5, label: '금' },
        { value: 6, label: '토' },
        { value: 0, label: '일' }
    ];
    var _d = React.useState(false), visible = _d[0], setVisible = _d[1];
    React.useEffect(function () {
        modalForm.reset(defaultValues);
    }, [visible]);
    var additional = function (unit) {
        switch (unit) {
            case 'week':
                return (React.createElement(Row, { style: { marginTop: '5px' } }, dayArray.map(function (day) {
                    var _a, _b;
                    return (React.createElement(Checkbox, { key: day.value, onClick: function (ev) {
                            ev.stopPropagation();
                            ev.preventDefault();
                            modalForm.setValue('data.days', orderBy(addOrRemove(modalValues.data.days || [], day.value)));
                        }, checked: isNumber((_b = (_a = modalValues.data) === null || _a === void 0 ? void 0 : _a.days) === null || _b === void 0 ? void 0 : _b.find(function (item) { return day.value === item; })) }, day.label));
                })));
            case 'month':
                return (React.createElement(Dropdown, { overlay: React.createElement(Menu, null, [
                        { unit: 'month', data: { interval: 1, date: date } },
                        { unit: 'month', data: { interval: 1, th: weekNumber, days: [days] } }
                    ].map(function (item) {
                        return (React.createElement(Menu.Item, { onClick: function () {
                                modalForm.setValue('data', item.data);
                            } }, ScheduleModel.getRepeatString(item)));
                    })) },
                    React.createElement(GrayButtonWithDown, { children: ScheduleModel.getRepeatString(modalValues) })));
            case 'year':
            case 'day':
            default:
                return React.createElement("div", null);
        }
    };
    var handleUnitChange = function (value) {
        var _a, _b, _c, _d;
        if (value === 'day') {
            modalForm.setValue('data', {
                interval: ((_a = modalValues.data) === null || _a === void 0 ? void 0 : _a.interval) || 1
            });
        }
        else if (value === 'week') {
            modalForm.setValue('data', {
                interval: ((_b = modalValues.data) === null || _b === void 0 ? void 0 : _b.interval) || 1,
                days: [startedAt.day()]
            });
        }
        else if (value === 'month') {
            modalForm.setValue('data', {
                interval: ((_c = modalValues.data) === null || _c === void 0 ? void 0 : _c.interval) || 1,
                date: startedAt.date()
            });
        }
        else if (value === 'year') {
            modalForm.setValue('data', {
                interval: ((_d = modalValues.data) === null || _d === void 0 ? void 0 : _d.interval) || 1,
                date: startedAt.date(),
                month: startedAt.month()
            });
        }
    };
    var handleModalSubmit = function () {
        modalValues;
        props.onChange(__assign(__assign({}, props.value), { repeat: modalValues }));
        setVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dropdown, { trigger: ['click'], overlay: React.createElement(Menu, null,
                items.map(function (item, index) {
                    return (React.createElement(Menu.Item, { key: index, onClick: function () { return props.onChange(set(props.value, 'repeat', item.value)); } }, item.label));
                }),
                React.createElement(Menu.Item, { onClick: function () { return setVisible(true); } }, "\uB9DE\uCDA4")) },
            React.createElement("div", null,
                React.createElement(GrayButtonWithDown, { children: ScheduleModel.getRepeatString(repeat) }))),
        React.createElement(BigTitleModal, { okButtonProps: {
                disabled: !isEmpty(modalForm.formState.errors)
            }, visible: visible, onCancel: function () { return setVisible(false); }, onOk: handleModalSubmit, title: "\uBC18\uBCF5\uC124\uC815", children: React.createElement(RepeatForm, { onSubmit: modalForm.handleSubmit(onSubmit) },
                React.createElement("div", null,
                    React.createElement(Row, { align: "bottom", gutter: 8, style: { marginBottom: '5px' } },
                        React.createElement(Col, null,
                            React.createElement(HookFormInputs.NumberInput, { label: "\uBC18\uBCF5 \uC8FC\uAE30", inputProps: {
                                    size: 'large'
                                }, controlProps: {
                                    name: "data.interval",
                                    control: modalForm.control,
                                    rules: {
                                        required: { value: true, message: '반복 주기를 입력해주세요.' },
                                        min: { value: 1, message: '반복주기는 1이상으로 입력해주세요.' },
                                        validate: function (value) {
                                            if (value < 1) {
                                                return '반복주기는 1이상으로 입력해주세요.';
                                            }
                                            return true;
                                        }
                                    }
                                } })),
                        React.createElement(Col, null,
                            React.createElement(HookFormInputs.SelectInput, { inputProps: {
                                    size: 'large',
                                    options: [
                                        { value: 'day', label: '일' },
                                        { value: 'week', label: '주' },
                                        { value: 'month', label: '개월' },
                                        { value: 'year', label: '년' }
                                    ],
                                    onChange: handleUnitChange
                                }, controlProps: {
                                    name: "unit",
                                    control: modalForm.control,
                                    rules: {
                                        required: { value: true, message: '반복 주기를 입력해주세요.' }
                                    }
                                } }))),
                    additional(modalValues.unit)),
                React.createElement(HookFormInputs.DatePicker, { label: "\uC885\uB8CC\uC77C", inputProps: {
                        size: 'large',
                        placeholder: '종료일 없음(무제한)'
                    }, controlProps: {
                        name: "expired.endedAt",
                        control: modalForm.control
                    } }),
                React.createElement("br", null),
                ScheduleModel.getRepeatString(modalValues)) })));
};
var RepeatForm = styled.form(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 20px;\n  padding-top: 10px;\n\n  & > * {\n    margin-bottom: 20px;\n  }\n"], ["\n  padding: 20px;\n  padding-top: 10px;\n\n  & > * {\n    margin-bottom: 20px;\n  }\n"])));
var GrayButtonWithDown = function (props) {
    return (React.createElement(StyledGrayButtonWithDown, __assign({}, props, { children: React.createElement(Row, { align: "middle", justify: "space-between" },
            props.children,
            React.createElement(Icon, { name: "down-chevron", color: COLORS.black, style: { marginLeft: '5px' } })) })));
};
var StyledGrayButtonWithDown = styled(Button)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  height: 36px;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n  border: 0px solid var(--gray-7);\n\n  &.ant-btn:hover,\n  &.ant-btn:active {\n    background-color: ", " !important;\n  }\n  & > span {\n    border-radius: 2px;\n    padding-left: 5px;\n    padding-right: 5px;\n  }\n"], ["\n  height: 36px;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n  border: 0px solid var(--gray-7);\n\n  &.ant-btn:hover,\n  &.ant-btn:active {\n    background-color: ", " !important;\n  }\n  & > span {\n    border-radius: 2px;\n    padding-left: 5px;\n    padding-right: 5px;\n  }\n"])), COLORS.gray7);
var checkFromServer = function (storeId, _a) {
    var id = _a.id, dateOption = _a.dateOption;
    return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, ScheduleQuery.checkAvailableReservationByDate('check', storeId, {
                        id: id,
                        startedAt: dayjs(dateOption.startedAt).toDate(),
                        endedAt: dayjs(dateOption.endedAt).toDate()
                    })];
                case 1:
                    result = _b.sent();
                    return [2 /*return*/, result];
            }
        });
    });
};
export var scheduleCreateOrUpdate = function (newSchedule, prevSchedule, store, createSchedule, updateSchedule, createNewRepeatSchedule, availableAll) { return __awaiter(void 0, void 0, void 0, function () {
    var isUpdated, isChangeTime, shouldBlockNaver, result;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                isUpdated = !!(prevSchedule === null || prevSchedule === void 0 ? void 0 : prevSchedule.id);
                isChangeTime = !newSchedule.id ||
                    prevSchedule.status !== newSchedule.status ||
                    !dayjs(newSchedule.dateOption.startedAt).isSame(prevSchedule.dateOption.startedAt, 'minute') ||
                    !dayjs(newSchedule.dateOption.endedAt).isSame(prevSchedule.dateOption.endedAt, 'minute');
                shouldBlockNaver = (((_b = (_a = store.preference.smartplace) === null || _a === void 0 ? void 0 : _a.blockSettings) === null || _b === void 0 ? void 0 : _b.isActive) ||
                    ((_d = (_c = store.preference.naverSmartPlace) === null || _c === void 0 ? void 0 : _c.blockSettings) === null || _d === void 0 ? void 0 : _d.isActive)) &&
                    (isChangeTime || prevSchedule.managerId !== newSchedule.managerId || newSchedule.status !== prevSchedule.status);
                if (prevSchedule.managerId !== newSchedule.managerId &&
                    (newSchedule.from === 'naver' || ((_e = newSchedule.data) === null || _e === void 0 ? void 0 : _e.provider) === 'naver')) {
                    notification.error({ message: '네이버 예약은 담당자를 변경할 수 없습니다.' });
                    return [2 /*return*/];
                }
                if (!dayjs((_f = newSchedule.dateOption) === null || _f === void 0 ? void 0 : _f.startedAt).isSame((_g = newSchedule.dateOption) === null || _g === void 0 ? void 0 : _g.endedAt, 'day')) {
                    notification.error({ message: '예약은 하루를 넘어갈 수 없습니다.' });
                    return [2 /*return*/];
                }
                if (!prevSchedule.isReservation &&
                    prevSchedule.isRepeatSchedule &&
                    newSchedule.managerId !== prevSchedule.managerId) {
                    notification.error({ message: '반복 일정 및 휴무는 담당자를 변경할 수 없습니다.' });
                    return [2 /*return*/];
                }
                return [4 /*yield*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                        var updatedSchedule, dateOption, storeTimelimit, data, payload, schedule_1, availableAfter, radioValue_1, modal_1;
                        var _a, _b, _c, _d, _e;
                        return __generator(this, function (_f) {
                            switch (_f.label) {
                                case 0:
                                    if (newSchedule.type === ScheduleModel.RESERVATION_TYPE.SCHEDULE) {
                                        if (newSchedule.managerId === -1 || !newSchedule.managerId) {
                                            notification.error({ message: '일정은 담당자를 지정해야합니다.' });
                                            return [2 /*return*/];
                                        }
                                    }
                                    dateOption = __assign(__assign({}, newSchedule.dateOption), { repeat: newSchedule.dateOption.repeat && newSchedule.dateOption.repeat.unit !== 'none'
                                            ? __assign(__assign({}, newSchedule.dateOption.repeat), { expired: isEmpty((_b = (_a = newSchedule.dateOption) === null || _a === void 0 ? void 0 : _a.repeat) === null || _b === void 0 ? void 0 : _b.expired) ? null : (_d = (_c = newSchedule.dateOption) === null || _c === void 0 ? void 0 : _c.repeat) === null || _d === void 0 ? void 0 : _d.expired }) : null });
                                    storeTimelimit = store.operationPreference.depositTimeLimit;
                                    data = newSchedule.type === 'reservation'
                                        ? __assign(__assign({}, newSchedule.data), { provider: prevSchedule.from === 'naver' ? 'naver' : 'crm', depositTimeLimit: dayjs().add(storeTimelimit, 'minute').toDate() }) : { provider: prevSchedule.from === 'naver' ? 'naver' : 'crm' };
                                    payload = __assign(__assign({}, newSchedule), { managerId: newSchedule.managerId === -1 ? null : newSchedule.managerId, dateOption: dateOption, storeProducts: (_e = newSchedule.storeProducts) === null || _e === void 0 ? void 0 : _e.map(function (item) { return ({ id: item.id }); }), storeId: store.id, from: (prevSchedule === null || prevSchedule === void 0 ? void 0 : prevSchedule.from) === 'naver' ? 'naver' : 'crm', data: data });
                                    if (!isUpdated) return [3 /*break*/, 4];
                                    if (!(typeof payload.id === 'string')) return [3 /*break*/, 1];
                                    schedule_1 = new ScheduleModel(payload);
                                    availableAfter = !!payload.dateOption.repeat;
                                    radioValue_1 = 'only';
                                    modal_1 = Modal.info({
                                        centered: true,
                                        closable: true,
                                        zIndex: 99999999,
                                        title: "".concat(schedule_1.typeString, " \uC218\uC815"),
                                        onCancel: function () {
                                            modal_1.destroy();
                                            return reject('예약 수정이 취소되었습니다.');
                                        },
                                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            var origin, result_1, newRepeat;
                                            var _a, _b, _c;
                                            return __generator(this, function (_d) {
                                                switch (_d.label) {
                                                    case 0:
                                                        origin = ((_b = (_a = prevSchedule === null || prevSchedule === void 0 ? void 0 : prevSchedule.dateOption) === null || _a === void 0 ? void 0 : _a.repeat) === null || _b === void 0 ? void 0 : _b.origin) ||
                                                            ((_c = ScheduleQuery.getSchedulesFromCache()) === null || _c === void 0 ? void 0 : _c.find(function (item) { var _a, _b; return item.id === ((_b = (_a = prevSchedule === null || prevSchedule === void 0 ? void 0 : prevSchedule.dateOption) === null || _a === void 0 ? void 0 : _a.repeat) === null || _b === void 0 ? void 0 : _b.originId); }));
                                                        if (!origin) return [3 /*break*/, 5];
                                                        result_1 = schedule_1.createNewScheduleFromRepeatSchedule(origin, prevSchedule, radioValue_1);
                                                        if (!(radioValue_1 === 'all')) return [3 /*break*/, 2];
                                                        return [4 /*yield*/, updateSchedule.mutateAsync(result_1.origin)];
                                                    case 1:
                                                        updatedSchedule = _d.sent();
                                                        return [3 /*break*/, 4];
                                                    case 2: return [4 /*yield*/, createNewRepeatSchedule.mutateAsync(result_1)];
                                                    case 3:
                                                        newRepeat = _d.sent();
                                                        updatedSchedule = newRepeat.newItem;
                                                        _d.label = 4;
                                                    case 4: return [3 /*break*/, 6];
                                                    case 5:
                                                        notification.error({ message: '기존 예약을 찾을 수 없습니다.' });
                                                        _d.label = 6;
                                                    case 6:
                                                        modal_1.destroy();
                                                        return [2 /*return*/, resolve(updatedSchedule)];
                                                }
                                            });
                                        }); },
                                        content: (React.createElement("div", null,
                                            React.createElement(Radio.Group, { defaultValue: 'only', onChange: function (e) {
                                                    radioValue_1 = e.target.value;
                                                } },
                                                React.createElement(Space, { direction: "vertical" },
                                                    React.createElement(Radio, { value: "only" }, "\uC774 ".concat(schedule_1.typeString, "\uB9CC \uC218\uC815")),
                                                    availableAfter && (React.createElement(Radio, { value: "after" }, "\uC774 ".concat(schedule_1.typeString, " \uBC0F \uD5A5\uD6C4 \uBC18\uBCF5 ").concat(schedule_1.typeString, " \uC218\uC815"))),
                                                    availableAll && React.createElement(Radio, { value: "all" }, "\uBAA8\uB4E0 \uBC18\uBCF5".concat(schedule_1.typeString, " \uC218\uC815"))))))
                                    });
                                    return [2 /*return*/];
                                case 1: return [4 /*yield*/, updateSchedule.mutateAsync(payload)];
                                case 2:
                                    updatedSchedule = _f.sent();
                                    return [2 /*return*/, resolve(updatedSchedule)];
                                case 3: return [3 /*break*/, 6];
                                case 4: return [4 /*yield*/, createSchedule.mutateAsync(payload)];
                                case 5:
                                    updatedSchedule = _f.sent();
                                    return [2 /*return*/, resolve(updatedSchedule)];
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); })];
            case 1:
                result = _k.sent();
                if (shouldBlockNaver) {
                    syncNaverCalendar({
                        store: store,
                        effectedDates: [(_h = newSchedule === null || newSchedule === void 0 ? void 0 : newSchedule.dateOption) === null || _h === void 0 ? void 0 : _h.startedAt, (_j = prevSchedule === null || prevSchedule === void 0 ? void 0 : prevSchedule.dateOption) === null || _j === void 0 ? void 0 : _j.startedAt]
                    });
                }
                return [2 /*return*/, result];
        }
    });
}); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
