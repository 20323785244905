import React from 'react';
import * as Styles from './styles.css';
import { EntireBigTitleModal } from 'renderer/components';
import { useWindowSize } from 'renderer/utils';
import { StoreQuery } from 'renderer/queries';
import { useGetScheduleModals, useScheduleModal } from 'renderer/stores';
import { ScheduleActions, ScheduleFormComponent } from './elements';
export var findProductInGroup = function (groups, productId) {
    var product;
    groups === null || groups === void 0 ? void 0 : groups.forEach(function (item) {
        var target = item.products.find(function (item) { return item.id === productId; });
        if (target) {
            product = target;
        }
    });
    return product;
};
export var ReservationDrawer = function (props) {
    var remove = useScheduleModal().remove;
    var items = useGetScheduleModals();
    var _a = useWindowSize(), isPC = _a.isPC, height = _a.height;
    var store = StoreQuery.getMyStoreInCache();
    var handleClose = React.useCallback(function (id) {
        remove(id);
    }, [remove]);
    return (React.createElement(React.Fragment, null, items === null || items === void 0 ? void 0 : items.map(function (modalData) {
        var formMode = ['create', 'update'].includes(modalData === null || modalData === void 0 ? void 0 : modalData.type);
        var schedule = modalData === null || modalData === void 0 ? void 0 : modalData.schedule;
        var isUpdated = !!(schedule === null || schedule === void 0 ? void 0 : schedule.id);
        return (React.createElement(EntireBigTitleModal, { key: modalData.id, className: Styles.holiday, open: !!modalData, onCancel: function () { return handleClose(modalData.id); }, title: formMode ? "\uC2A4\uCF00\uC974 ".concat(isUpdated ? '수정' : '등록') : '등록된 스케쥴', footer: null }, schedule ? (formMode ? (React.createElement(ScheduleFormComponent, { isPC: isPC, height: height, schedule: schedule, onClose: function () { return handleClose(modalData.id); }, store: store })) : (React.createElement(ScheduleActions, { isPC: isPC, schedule: schedule, type: modalData === null || modalData === void 0 ? void 0 : modalData.type }))) : (React.createElement("div", null))));
    })));
};
