var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AntPrimaryGhostButton, AntdForm, CreditCardRegister, DrawerComponent, DrawerFooter, FormattedNumber, KRWFomatToString, TextComponent } from 'renderer/components';
import { COLORS } from 'renderer/styles';
import { StorePaymentMethodQuery } from 'renderer/queries';
import styled from 'styled-components';
import { AlertComponent } from 'renderer/components';
import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import { isEmpty } from 'renderer/utils';
export var ChargeDrawer = function (props) {
    var _a, _b;
    var _c = React.useState(0), value = _c[0], setValue = _c[1];
    var store = props.store;
    var storeId = store.id;
    var productsQuery = StorePaymentMethodQuery.useOurProducts(storeId, { enabled: !!storeId });
    var pointProduct = (_a = productsQuery.data) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.name === 'point'; });
    var options = (pointProduct === null || pointProduct === void 0 ? void 0 : pointProduct.options) || [];
    var paymentMethods = StorePaymentMethodQuery.usePaymentMethods(storeId, { enabled: !!storeId });
    var chargePointByMethods = StorePaymentMethodQuery.useChargeStorePoints();
    var hasCreditCard = !isEmpty(paymentMethods.data);
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var option, result, primary;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    option = options.find(function (item) { return item.id === value; });
                    if (!option) return [3 /*break*/, 4];
                    result = void 0;
                    if (!hasCreditCard) return [3 /*break*/, 2];
                    primary = (_a = paymentMethods.data) === null || _a === void 0 ? void 0 : _a.find(function (method) { return method.isPrimary; });
                    return [4 /*yield*/, chargePointByMethods.mutateAsync({
                            storeId: props.store.id,
                            productId: pointProduct.id,
                            optionId: option.id,
                            paymentMethodId: primary.id
                        })];
                case 1:
                    result = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    notification.error({ message: '카드 등록이 필요합니다.' });
                    _b.label = 3;
                case 3:
                    if (result) {
                        notification.success({ message: '충전이 완료되었습니다.' });
                    }
                    props.onClose();
                    _b.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (options && options[0]) {
            setValue(options[0].id);
        }
    }, [options]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DrawerComponent, { footer: React.createElement(DrawerFooter, { onCancel: props.onClose, onConfirm: handleSubmit, confirmText: "\uCDA9\uC804" }), title: "\uBB38\uC790 \uD3EC\uC778\uD2B8 \uC218\uB3D9 \uCDA9\uC804", visible: props.visible && hasCreditCard, onClose: props.onClose },
            React.createElement(Flex, null,
                React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, marginTop: 24, marginBottom: 30, children: "\uCDA9\uC804\uC0C1\uD488 \uC120\uD0DD" }),
                React.createElement(PointRadioGroup, { value: value }, options.map(function (item) {
                    return (React.createElement(Radio, { value: item.id, onClick: function () { return setValue(item.id); }, key: item.quantity },
                        React.createElement(TextComponent, { children: "".concat(item.name, " - ").concat(KRWFomatToString({ value: item.price + item.vat })) })));
                })),
                React.createElement(AlertComponent, { showIcon: true, style: { width: '100%' }, message: "\uC548\uB0B4", description: React.createElement("div", null,
                        React.createElement(TextComponent, { type: "subtitle3", color: COLORS.gray2, children: "- \uD3EC\uC778\uD2B8 \uCDA9\uC804\uC2DC \uC548\uB0B4\uB418\uB294 \uACB0\uC81C\uAE08\uC561\uC740 \uBD80\uAC00\uC138 10%\uAC00 \uD3EC\uD568\uB41C \uAE08\uC561\uC785\uB2C8\uB2E4." })), type: "info" }))),
        React.createElement(CreditCardRegister, { storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id, visible: !hasCreditCard && props.visible, onClose: function (isRegistered) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!isRegistered) {
                        props.onClose();
                    }
                    return [2 /*return*/];
                });
            }); } })));
};
var Flex = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var PointRadioGroup = styled(Radio.Group)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  & > label {\n    display: flex;\n    align-items: center;\n    border-radius: 2px;\n    border: solid 1px var(--gray-6);\n    height: 40px;\n    margin-bottom: 12px;\n    padding-left: 18px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  & > label {\n    display: flex;\n    align-items: center;\n    border-radius: 2px;\n    border: solid 1px var(--gray-6);\n    height: 40px;\n    margin-bottom: 12px;\n    padding-left: 18px;\n  }\n"])));
var StorePointCenter = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = React.useState(false), visible = _f[0], setVisible = _f[1];
    var handleClose = function () {
        setVisible(false);
    };
    var handleOpen = function () {
        setVisible(true);
    };
    var store = props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var form = Form.useForm()[0];
    var query = StorePaymentMethodQuery.usePaymentMethods(storeId, { enabled: !!storeId });
    var primaryCard = (_a = query === null || query === void 0 ? void 0 : query.data) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.isPrimary; });
    var updateStorePointsSettings = StorePaymentMethodQuery.useUpdateStorePoints();
    var storePointQuery = StorePaymentMethodQuery.useStorePoint(storeId, { enabled: !!storeId });
    var productsQuery = StorePaymentMethodQuery.useOurProducts(storeId, { enabled: !!storeId });
    var pointProduct = (_b = productsQuery.data) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.name === 'point'; });
    var options = (pointProduct === null || pointProduct === void 0 ? void 0 : pointProduct.options) || [];
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var chargeSetting_1, currentPoint, option, err_1, err_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 6, , 7]);
                    chargeSetting_1 = values.meta.chargeSetting;
                    if (!primaryCard && chargeSetting_1.isAuto) {
                        notification.info({ message: '자동결제에 사용할 카드를 등록해주세요.' });
                        return [2 /*return*/, setCardRegisterVisible(true)];
                    }
                    return [4 /*yield*/, updateStorePointsSettings.mutateAsync({
                            storeId: storeId,
                            meta: { chargeSetting: __assign({}, values.meta.chargeSetting) }
                        })];
                case 1:
                    _b.sent();
                    notification.success({ message: '설정 저장이 완료되었습니다.' });
                    currentPoint = (_a = storePointQuery.data) === null || _a === void 0 ? void 0 : _a.amount;
                    if (!(chargeSetting_1.isAuto && (currentPoint || 0) < chargeSetting_1.min)) return [3 /*break*/, 5];
                    option = options.find(function (item) { return item.quantity === chargeSetting_1.autoChargeAmount; });
                    if (!option) {
                        return [2 /*return*/, notification.error({ message: '상품정보가 잘못입력되었습니다. 관리자에게 문의해주세요.' })];
                    }
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, chargePointByMethods.mutateAsync({
                            storeId: props.store.id,
                            productId: pointProduct.id,
                            optionId: option.id,
                            paymentMethodId: primaryCard.id
                        })];
                case 3:
                    _b.sent();
                    notification.success({ message: '자동 충전이 완료되었습니다.' });
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _b.sent();
                    notification.success({ message: '자동 충전 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    err_2 = _b.sent();
                    notification.success({ message: err_2.message || '설정 중 에러발생' });
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var paymentMethods = StorePaymentMethodQuery.usePaymentMethods(storeId, { enabled: !!storeId });
    var chargePointByMethods = StorePaymentMethodQuery.useChargeStorePoints();
    var hasCreditCard = !isEmpty(paymentMethods.data);
    var _g = React.useState(false), isAuto = _g[0], setIsAuto = _g[1];
    var minOptions = [
        { value: 5000, label: '5,000점' },
        { value: 10000, label: '10,000점' }
    ];
    var amountOptions = pointProduct === null || pointProduct === void 0 ? void 0 : pointProduct.options.map(function (option) {
        return {
            value: option.quantity,
            label: "".concat(FormattedNumber({ value: option.quantity }), " \uC810")
        };
    });
    React.useEffect(function () {
        var _a, _b, _c;
        if (storePointQuery.data) {
            form.setFieldsValue(storePointQuery.data);
            var isAuto_1 = (_c = (_b = (_a = storePointQuery.data) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.chargeSetting) === null || _c === void 0 ? void 0 : _c.isAuto;
            setIsAuto(isAuto_1);
        }
    }, [storePointQuery.data]);
    // const isAuto = form.getFieldValue(['meta', 'chargeSetting', 'isAuto']);
    var _h = React.useState(false), cardRegisterVisible = _h[0], setCardRegisterVisible = _h[1];
    return (React.createElement("div", null,
        React.createElement(CreditCardRegister, { storeId: (_c = props.store) === null || _c === void 0 ? void 0 : _c.id, visible: cardRegisterVisible, onClose: function (newCreditCard) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log('newCreditCard', newCreditCard);
                            setCardRegisterVisible(false);
                            if (!newCreditCard) return [3 /*break*/, 2];
                            return [4 /*yield*/, paymentMethods.refetch()];
                        case 1:
                            _a.sent();
                            form.submit();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); } }),
        React.createElement(ChargeDrawer, { store: store, visible: visible, onClose: handleClose }),
        React.createElement(Row, null,
            React.createElement(Col, { lg: { span: 16 }, md: { span: 24 }, style: { width: '100%' } },
                React.createElement(TextComponent, { type: "headline3", color: COLORS.gray1, marginTop: 16, marginBottom: 30, children: "\uBB38\uC790 \uD3EC\uC778\uD2B8" }),
                React.createElement(AntdForm, { onSubmit: handleSubmit, formProps: { form: form, initialValues: storePointQuery.data, onFinish: handleSubmit }, fields: [
                        {
                            type: 'text',
                            itemProps: { name: 'amount', label: '내 문자 포인트' },
                            inputProps: { placeholder: '0', suffix: '점', disabled: true }
                        },
                        {
                            type: 'radio',
                            itemProps: {
                                name: ['meta', 'chargeSetting', 'isAuto'],
                                label: '충전 방법 설정'
                            },
                            inputProps: {
                                onChange: function (value) {
                                    setIsAuto(value.target.value);
                                },
                                options: [
                                    { value: false, label: '수동 충전' },
                                    { value: true, label: '자동 충전' }
                                ]
                            }
                        },
                        isAuto && {
                            type: 'select',
                            leftElement: (React.createElement(ExtraCol, { sm: { offset: 0 }, lg: { offset: 8 } },
                                React.createElement(TextComponent, { children: "\uB0B4 \uBB38\uC790 \uD3EC\uC778\uD2B8\uAC00", color: COLORS.gray1 }))),
                            rightElement: (React.createElement(ExtraCol, null,
                                React.createElement(TextComponent, { children: "\uC774\uD558\uC77C \uB54C", color: COLORS.gray1 }))),
                            itemProps: {
                                rules: [{ type: 'number' }, { required: true, message: '포인트 최소값을 입력해주세요.' }],
                                rule: [],
                                name: ['meta', 'chargeSetting', 'min']
                            },
                            inputProps: { placeholder: '0', suffix: '점', options: minOptions }
                        },
                        isAuto && {
                            type: 'select',
                            leftElement: (React.createElement(ExtraCol, { sm: { offset: 0 }, lg: { offset: 8 } },
                                React.createElement(TextComponent, { children: "\uB2E4\uC74C \uAE08\uC561", color: COLORS.gray1 }))),
                            rightElement: (React.createElement(ExtraCol, null,
                                React.createElement(TextComponent, { children: "\uC744 \uC790\uB3D9 \uCDA9\uC804", color: COLORS.gray1 }))),
                            itemProps: {
                                rules: [{ type: 'number' }, { required: true, message: '자동충전할 금액을 입력해주세요.' }],
                                name: ['meta', 'chargeSetting', 'autoChargeAmount']
                            },
                            inputProps: {
                                placeholder: '0',
                                options: amountOptions,
                                dropdownAlign: 'right'
                            }
                        }
                    ].filter(Boolean) }),
                React.createElement(Row, { justify: "end" },
                    React.createElement(AntPrimaryGhostButton, { disabled: !((_d = props.permission) === null || _d === void 0 ? void 0 : _d.update), children: "\uCDA9\uC804 \uC124\uC815 \uC800\uC7A5", onClick: form.submit })),
                isAuto && (React.createElement(AlertComponent, { style: { marginTop: '20px', marginBottom: '20px' }, showIcon: true, message: "\uC8FC\uC758", description: React.createElement("div", null,
                        isAuto ? (React.createElement(TextComponent, { children: "- \uCD5C\uC18C \uAE08\uC561\uC774 \uD604\uC7AC \uC794\uC561\uBCF4\uB2E4 \uD06C\uBA74 \uC800\uC7A5\uACFC \uB3D9\uC2DC\uC5D0 \uACB0\uC81C\uAC00 \uB420 \uC218 \uC788\uC73C\uB2C8 \uCC38\uACE0\uD574\uC8FC\uC138\uC694." })) : (React.createElement("div", null)),
                        isAuto ? (React.createElement(TextComponent, { children: "- \uC790\uB3D9 \uCDA9\uC804 \uC124\uC815 \uC2DC \uACB0\uC81C\uAD00\uB9AC\uC5D0 \uB4F1\uB85D\uB41C \uCE74\uB4DC\uB85C \uACB0\uC81C\uB429\uB2C8\uB2E4." })) : (React.createElement("div", null)),
                        React.createElement(TextComponent, { children: "- \uBB38\uC790\uD3EC\uC778\uD2B8 \uCDA9\uC804\uC744 \uC704\uD574\uC11C\uB294 \uCE74\uB4DC\uB4F1\uB85D\uC774 \uD544\uC694\uD569\uB2C8\uB2E4." }),
                        React.createElement(TextComponent, { children: "- \uCE74\uB4DC\uB4F1\uB85D\uC740 [\uACB0\uC81C\uC218\uB2E8\uAD00\uB9AC] \uC5D0\uC11C \uC774\uC6A9\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4." }),
                        React.createElement(TextComponent, { children: "- \uBB34\uD1B5\uC7A5\uC785\uAE08\uC73C\uB85C \uACB0\uC81C\uB97C \uC6D0\uD558\uC2DC\uB294 \uBD84\uC740 \uCC44\uD305\uC0C1\uB2F4\uC744 \uD1B5\uD574 \uBB38\uC758\uD574\uC8FC\uC138\uC694." })), type: "warning" })))),
        React.createElement(Row, { style: { marginTop: '20px' } },
            React.createElement(Col, { span: 6 },
                React.createElement(TextComponent, { children: "\uC218\uB3D9\uCDA9\uC804", color: COLORS.gray1 })),
            React.createElement(Col, { span: 18 },
                React.createElement(AntPrimaryGhostButton, { disabled: !((_e = props.permission) === null || _e === void 0 ? void 0 : _e.update), children: "\uBB38\uC790 \uD3EC\uC778\uD2B8 \uC218\uB3D9 \uCDA9\uC804", onClick: handleOpen })))));
};
export default StorePointCenter;
export var ExtraCol = styled(Col)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 110px;\n  padding-top: 5px;\n"], ["\n  width: 110px;\n  padding-top: 5px;\n"])));
export var StyledEmpty = styled(Empty)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.4);\n  top: 0;\n  left: 0;\n  margin-left: 0px;\n  z-index: 9999;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.4);\n  top: 0;\n  left: 0;\n  margin-left: 0px;\n  z-index: 9999;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
