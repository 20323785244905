var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as yup from 'yup';
import dayjs from 'dayjs';
import { groupBy, isEmpty, omitEmptyValues, sum, unionWith, uniq, orderBy, floor, mapSeries } from 'renderer/utils';
import { createUUID } from 'renderer/utils/uuid';
import { RestClient } from '../../utils/RestClient';
import { GENDER_ENUM, PAYMENT_LOCATION, PAYMENT_STATUS, TicketModel, TicketTemplateModel, TICKET_TYPE } from 'renderer/models';
import { DATE_FORMAT_INCLUDE_TIME } from 'renderer/constants';
import { KRWFomatToString } from 'renderer/components';
import { DATE_FORMAT } from '../../constants/index';
var getNumberOrZero = function (data) { return Number(data || 0); };
var divideByManagerLength = function (amount, managerArrayLength, index) {
    var rest = Number(amount) % managerArrayLength;
    return floor(Number(amount) / managerArrayLength) + (index === 0 ? rest : 0);
};
export var convertTemplateSheetToJSON = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var file, workbook, productSheet, templateSheet, validationError, restGroup_1, groups_1, restTemplate_1, tempTemplates_1, templatePayload_1, lastGroupIndex_1, err_1, value, rest;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                file = props.file;
                return [4 /*yield*/, getWorkbook(file)];
            case 1:
                workbook = _a.sent();
                productSheet = sheetToJSON(workbook, '상품관리');
                templateSheet = sheetToJSON(workbook, '정기권관리');
                _a.label = 2;
            case 2:
                _a.trys.push([2, 5, , 6]);
                return [4 /*yield*/, validateProductSheet(productSheet)];
            case 3:
                productSheet = _a.sent();
                return [4 /*yield*/, validateTicketTemplateSheet(templateSheet)];
            case 4:
                templateSheet = (_a.sent());
                restGroup_1 = __spreadArray([], props.productGroupModels, true);
                groups_1 = uniq(productSheet.map(function (product) { return product.구분1차; })).map(function (groupName, index) {
                    var prevGroup = (props.productGroupModels || []).find(function (group) { return group.name === groupName; });
                    if (prevGroup) {
                        restGroup_1 = restGroup_1.filter(function (group) { return group.name !== groupName; });
                        return __assign(__assign({}, prevGroup), { priority: index, newProducts: [] });
                    }
                    else {
                        return {
                            id: null,
                            name: groupName,
                            priority: index,
                            products: [],
                            newProducts: []
                        };
                    }
                });
                productSheet.map(function (product, index) {
                    var group = groups_1.find(function (group) { return group.name === product.구분1차; });
                    if (group) {
                        var prevProduct = group.products.find(function (item) { return item.name === product.구분2차; });
                        if (prevProduct) {
                            group.products = group.products.filter(function (item) { return item.name !== product.구분2차; });
                        }
                        var newProduct = prevProduct
                            ? __assign(__assign({}, prevProduct), { price: product.금액, time: product.시술시간 }) : {
                            name: product.구분2차,
                            price: product.금액,
                            time: product.시술시간,
                            productGroupId: group.id
                        };
                        group.newProducts = (group.newProducts || []).concat(__assign(__assign({}, newProduct), { priority: index }));
                    }
                });
                groups_1.map(function (group) {
                    if (!isEmpty(group.products)) {
                        var lastPriority_1 = isEmpty(group.newProducts) ? 0 : __spreadArray([], group.newProducts, true).pop().priority;
                        group.products.map(function (product, index) {
                            group.newProducts.push(__assign(__assign({}, product), { priority: lastPriority_1 + index + 1 }));
                        });
                        group.products = [];
                    }
                });
                restTemplate_1 = __spreadArray([], props.templates, true);
                tempTemplates_1 = templateSheet.map(function (template, index) {
                    var isCharging = template.정기권종류 === '금액권';
                    var group = groups_1.find(function (group) { return group.name === template.구분1차; });
                    var product = group === null || group === void 0 ? void 0 : group.newProducts.find(function (product) { return product.name === template.구분2차; });
                    if (!isCharging && (!group || !product)) {
                        throw new Error('제품이 없는 템플릿이 있습니다.');
                    }
                    var type = isCharging
                        ? TICKET_TYPE.CHARGING_MONEY
                        : template.정기권종류 === '기간권'
                            ? TICKET_TYPE.TERM
                            : TICKET_TYPE.COUNT;
                    var data = omitEmptyValues({
                        amount: template.제공,
                        unit: TICKET_TYPE.TERM === type ? 'month' : null,
                        expiredOption: template.유효기간 ? { unit: 'month', amount: template.유효기간 } : null
                    });
                    var rest = {
                        price: template.결제금액,
                        data: __assign(__assign({}, data), { benefit: template.제공혜택 === '할인'
                                ? { type: 'discount', amount: template.혜택 }
                                : template.제공혜택 === '추가'
                                    ? type === TICKET_TYPE.TERM
                                        ? { type: 'add', amount: template.혜택, unit: 'month' }
                                        : { type: 'add', amount: template.혜택 }
                                    : { type: 'none' } })
                    };
                    var name = isCharging
                        ? template.구분2차
                        : TicketTemplateModel.createTemplatetName("(".concat(group.name, ") ").concat(product.name), __assign(__assign({}, rest), { type: type }));
                    var prevTemplate = props.templates.find(function (item) { return item.name === name && item.type === type; });
                    if (prevTemplate) {
                        restTemplate_1 = restTemplate_1.filter(function (item) { return item.id !== prevTemplate.id; });
                    }
                    var newTemplate = prevTemplate
                        ? __assign(__assign({}, prevTemplate), rest) : __assign({ name: name, type: type, storeId: props.storeId, storeProductId: null, product: !isCharging && {
                            groupName: group.name,
                            productName: product.name
                        }, meta: {
                            isSell: true,
                            cancellableDays: 7,
                            priority: 0
                        } }, rest);
                    return newTemplate;
                });
                tempTemplates_1.push.apply(tempTemplates_1, restTemplate_1);
                templatePayload_1 = [];
                [TICKET_TYPE.CHARGING_MONEY, TICKET_TYPE.COUNT, TICKET_TYPE.TERM].map(function (type) {
                    templatePayload_1 = templatePayload_1.concat(tempTemplates_1.filter(function (item) { return item.type === type; }));
                });
                templatePayload_1 = templatePayload_1.map(function (item, index) { return (__assign(__assign({}, item), { meta: __assign(__assign({}, item.meta), { priority: index }) })); });
                lastGroupIndex_1 = groups_1.length - 1;
                return [2 /*return*/, {
                        productGroupPayload: groups_1.concat((restGroup_1 === null || restGroup_1 === void 0 ? void 0 : restGroup_1.map(function (group, index) {
                            return __assign(__assign({}, group), { priority: lastGroupIndex_1 + 1 + index });
                        })) || []),
                        templatePayload: templatePayload_1
                    }];
            case 5:
                err_1 = _a.sent();
                value = err_1.value, rest = __rest(err_1, ["value"]);
                validationError = __assign(__assign({}, rest), { name: "\uC2DC\uD2B8 \uB370\uC774\uD130 \uC774\uC0C1 ".concat(JSON.stringify(err_1)) });
                return [2 /*return*/, { validationError: validationError }];
            case 6: return [2 /*return*/];
        }
    });
}); };
export var convertCustomerSheetToCustomerData = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var file, managers, groups, prevCustomers, workbook, customerSheet, validationError, err_2, value, rest, customers, customerPayload;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                file = props.file, managers = props.managers, groups = props.groups, prevCustomers = props.prevCustomers;
                return [4 /*yield*/, getWorkbook(file)];
            case 1:
                workbook = _a.sent();
                customerSheet = sheetToJSON(workbook, '고객데이터');
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 5]);
                return [4 /*yield*/, validateCustomerSheet(customerSheet, { managers: managers, groups: groups })];
            case 3:
                customerSheet = (_a.sent());
                return [3 /*break*/, 5];
            case 4:
                err_2 = _a.sent();
                value = err_2.value, rest = __rest(err_2, ["value"]);
                validationError = __assign(__assign({}, rest), { name: "\uACE0\uAC1D\uB370\uC774\uD130 ".concat(err_2.name) });
                return [2 /*return*/, { validationError: validationError }];
            case 5:
                customers = unionByNameAndPhone(customerSheet, { phone: '휴대폰', name: '고객명' });
                customerPayload = {
                    new: [],
                    prev: []
                };
                customers.map(function (item) {
                    var _a;
                    var prevCustomer = prevCustomers.find(function (prev) { return isEqualCustomer({ name: item.고객명, phone: item.휴대폰 }, prev); });
                    // 매출을 통해 추출된 고객정보가 있는지 체크
                    var customerInSheet = item;
                    if (!prevCustomer) {
                        // 고객시트의 이름을 기준으로 변경
                        var manager = managers.find(function (manager) { var _a; return ((_a = manager.profile) === null || _a === void 0 ? void 0 : _a.name) === customerInSheet.담당자; });
                        var group = groups.find(function (group) { return group.name === customerInSheet.등급명; });
                        customerPayload.new.push({
                            name: customerInSheet.고객명,
                            phone: customerInSheet.휴대폰,
                            birth: customerInSheet.생년월일,
                            gender: customerInSheet.성별 === '여'
                                ? GENDER_ENUM.FEMALE
                                : customerInSheet.성별 === '남'
                                    ? GENDER_ENUM.MALE
                                    : undefined,
                            memo: customerInSheet.메모,
                            customerPoint: customerInSheet['마일리지'] || 0,
                            customerPointExpiredAt: getKoreanExpiredAt(customerInSheet['마일리지만료일']),
                            managerId: manager === null || manager === void 0 ? void 0 : manager.id,
                            customerGroupId: (group === null || group === void 0 ? void 0 : group.id) || ((_a = groups.find(function (item) { return item.priority === 0; })) === null || _a === void 0 ? void 0 : _a.id),
                            address: customerInSheet.주소 || '',
                            data: {
                                rejectReceive: customerInSheet.수신거부 === '거부',
                                email: customerInSheet.이메일 || ''
                            },
                            customerNumber: customerInSheet.고객번호
                        });
                    }
                    else {
                        customerPayload.prev.push(prevCustomer);
                    }
                });
                return [2 /*return*/, { customerPayload: customerPayload }];
        }
    });
}); };
export var getSalesDataFromFile = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var storeId, file, managers, templates, productGroupModels, customers, _a, validationError, paymentSheet, ticketSheet, customersFromOtherSheet, productGroupsFromOtherSheet, ticketTemplatesFromOtherSheet, productGroups, ticketTemplates, payments;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                storeId = props.storeId, file = props.file, managers = props.managers, templates = props.templates, productGroupModels = props.productGroupModels, customers = props.customers;
                return [4 /*yield*/, validateAndConvertSalesBuffer(file, {
                        managers: managers
                    })];
            case 1:
                _a = _b.sent(), validationError = _a.validationError, paymentSheet = _a.paymentSheet, ticketSheet = _a.ticketSheet;
                if (validationError) {
                    return [2 /*return*/, { validationError: validationError }];
                }
                customersFromOtherSheet = [];
                productGroupsFromOtherSheet = [];
                ticketTemplatesFromOtherSheet = [];
                paymentSheet.map(function (item, index) {
                    // 매출데이터에서 고객데이터 추출
                    if (!item.미등록고객구분) {
                        customersFromOtherSheet.push({ name: item.고객명, phone: item.휴대폰 });
                    }
                    var commons = {
                        groupName: item.구분1차 || '',
                        productName: item.구분2차 || '',
                        price: Number(item.단가)
                    };
                    // 금액권 판매가 아닐 경우 제품이랑 그룹만들어준다.
                    if (item.판매구분 !== '금액권') {
                        productGroupsFromOtherSheet.push(commons);
                    }
                    // 상품이 아닌경우 템플릿 데이터를 만들어준다.
                    if (item.판매구분 !== '상품') {
                        ticketTemplatesFromOtherSheet.push(__assign(__assign({}, commons), { type: item.판매구분, total: item.판매구분 === '금액권' ? Number(item.단가) : item.판매구분 === '기간권' ? 1 : 10, remain: 0 }));
                    }
                });
                ticketSheet.map(function (item) {
                    var _a;
                    // 매출데이터에서 고객데이터 추출
                    customersFromOtherSheet.push({ name: (_a = item.고객명) === null || _a === void 0 ? void 0 : _a.trim(), phone: item.휴대폰 });
                    var commons = {
                        groupName: item.구분1차 || '',
                        productName: item.구분2차 || '',
                        price: Number(0)
                    };
                    productGroupsFromOtherSheet.push(commons);
                    ticketTemplatesFromOtherSheet.push(__assign(__assign({}, commons), { type: item.정기권종류, total: item.정기권종류 === '기간권' ? Number(item.유효기간 || 1) : Number(item.총제공 || 1), remain: item.정기권종류 === '기간권' ? Number(item.유효기간 || 1) : Number(item.총제공 || 1), expiredOption: {
                            unit: 'month',
                            amount: item.유효기간
                        }, ticketData: {
                            phone: item.휴대폰,
                            name: item.고객명,
                            expiredAt: getKoreanExpiredAt(item.만료일),
                            remainData: item.정기권종류 === '기간권'
                                ? {
                                    startedAt: subtractNine(item.총제공, false),
                                    expiredAt: getKoreanExpiredAt(dayjs(item.잔여))
                                }
                                : {
                                    total: Number(item.총제공),
                                    remain: Number(item.잔여)
                                }
                        } }));
                });
                productGroups = getProductGroupWithProducts(productGroupsFromOtherSheet, productGroupModels, storeId);
                ticketTemplates = createTicketTemplates(ticketTemplatesFromOtherSheet, templates, storeId);
                payments = createPayments(paymentSheet, { customers: customers, storeId: storeId, productGroups: productGroups, managers: managers });
                //   storeId,
                return [2 /*return*/, {
                        productGroups: productGroups,
                        ticketTemplates: ticketTemplates,
                        ticketTemplatesFromOtherSheet: ticketTemplatesFromOtherSheet,
                        payments: payments
                    }];
        }
    });
}); };
var createPayments = function (items, _a) {
    var storeId = _a.storeId, customers = _a.customers, managers = _a.managers, productGroups = _a.productGroups;
    var payments = [];
    var groupedByDateAndUserName = groupBy(items, function (item) { return "".concat(dayjs(item.판매일시)).concat(item.고객명).concat(item.휴대폰); });
    var shouldPaidKeysInMigration = ['현금', '카드', '계좌이체', '할인', '미수', '기타1', '기타2'];
    var errors = [];
    Object.keys(groupedByDateAndUserName).map(function (paymentKey) {
        var _a;
        var usings = groupedByDateAndUserName[paymentKey];
        var productUsings = [];
        usings.map(function (using) {
            var customer = customers.find(function (item) {
                var _a, _b;
                var phone1 = (_a = using.휴대폰) === null || _a === void 0 ? void 0 : _a.replace(/-/g, '');
                var phone2 = (_b = item.phone) === null || _b === void 0 ? void 0 : _b.replace(/-/g, '');
                if (phone1 && phone2) {
                    return phone1 === phone2;
                }
                else {
                    if (!phone1 && !phone2) {
                        var name1 = using.고객명;
                        var name2 = item.name;
                        return name1 === name2;
                    }
                    return false;
                }
            });
            console.log('customer', customer);
            if (!customer && !using.미등록고객구분) {
                // TODO: 고객이 없으면 에러 기록 후 오류 표시
                // return undefined;
                // throw new Error('고객이 없습니다.');
                return null;
            }
            var managerArray = [using.담당자, using.담당자2]
                .map(function (managerName) {
                if (managerName) {
                    return managers.find(function (manager) { var _a; return ((_a = manager.profile) === null || _a === void 0 ? void 0 : _a.name) === managerName; });
                }
            })
                .filter(Boolean);
            // NOTE!! 마이그레이션과 시스템이 다른점!!
            // NOTE!! 결제액(받을금액) = 총금액 - (포인트차감 + 횟수권차감 + 기간권차감 + 선불충전액차감)
            if (getNumberOrZero(using['결제액(받을금액)']) !==
                getNumberOrZero(using.총금액) -
                    (getNumberOrZero(using.포인트차감) +
                        (getNumberOrZero(using.횟수권차감) || getNumberOrZero(using.기간권차감)) +
                        getNumberOrZero(using.선불충전액차감))) {
                throw new Error("".concat(using.고객명, " ").concat(using.판매일시, " \uACB0\uC81C\uC561(\uBC1B\uC744\uAE08\uC561) = \uCD1D\uAE08\uC561 - (\uD3EC\uC778\uD2B8\uCC28\uAC10 + \uD69F\uC218\uAD8C\uCC28\uAC10 + \uAE30\uAC04\uAD8C\uCC28\uAC10 + \uC120\uBD88\uCDA9\uC804\uC561\uCC28\uAC10) \uC774\uC5B4\uC57C\uD569\uB2C8\uB2E4."));
            }
            if (using.판매구분 === '상품') {
                // 상품이 아닐 경우 티켓 판매로서 독립된 매출기록을 가진다.
                productUsings.push(using);
            }
            else {
                if (using.미등록고객구분) {
                    throw new Error('미등록 고객에게는 정기권을 판매할 수 없습니다.');
                }
                var ticketTemplateData = getTemplateNameAndTypeByTicketTemplateData({
                    groupName: using.구분1차 || '',
                    productName: using.구분2차 || '',
                    price: Number(using.단가),
                    type: using.판매구분,
                    total: using.판매구분 === '금액권' ? Number(using.단가) : using.판매구분 === '기간권' ? 1 : 10
                });
                var shouldPaid = getNumberOrZero(using['결제액(받을금액)']);
                var shouldPaidInRow = sum(shouldPaidKeysInMigration.map(function (key) { return getNumberOrZero(using[key]); }));
                if (shouldPaid !== shouldPaidInRow) {
                    throw new Error("".concat(using.고객명, " ").concat(using.판매일시, " \uACB0\uC81C\uC561(\uBC1B\uC744\uAE08\uC561) = '\uD604\uAE08', '\uCE74\uB4DC', '\uACC4\uC88C\uC774\uCCB4', '\uD560\uC778', '\uBBF8\uC218', '\uAE30\uD0C01', '\uAE30\uD0C02'\uC758 \uD569\uACFC \uAC19\uC544\uC57C\uD569\uB2C8\uB2E4."));
                }
                var ticket = {
                    status: TicketModel.TICKET_STATUS.COMPLETE,
                    name: ticketTemplateData.name,
                    type: ticketTemplateData.type,
                    description: '마이그레이션에 의한 티켓 생성',
                    expiredAt: getKoreanExpiredAt(using.판매일시),
                    storeId: storeId,
                    data: ticketTemplateData.data,
                    sendQR: false
                    // copiedTicketTemplate: template,
                    // ticketTemplateId: template.id,
                    // 미등록 고객 테스트 필요 / 전화번호 없는 동명이인 테스트 필요
                    // customerId: customer?.id
                };
                // totalPrice(정가: 템플릿정가 - 할인) - 쿠폰할인 - 선불액차감 - 포인트차감 - 미수금 - 가격할인
                payments.push({
                    ticket: ticket,
                    paidAt: subtractNine(using.판매일시, true),
                    payerName: using.고객명 || '미등록고객',
                    // managerId: manager?.id,
                    totalPrice: Number(using.적용금액 || 0),
                    // paidPrice:
                    //   Number(using['결제액(받을금액)'] || 0) -
                    //   Number(using.미수 || 0) -
                    //   Number(using.선불충전액차감 || 0) -
                    //   Number(using.포인트차감 || 0) -
                    //   Number(using.할인 || 0),
                    status: PAYMENT_STATUS.COMPLETED,
                    meta: {
                        location: PAYMENT_LOCATION.OFFLINE,
                        isGeneratedCashReceipts: false,
                        applySales: true,
                        isMigration: true
                    },
                    memo: using.메모,
                    storeId: storeId,
                    customer: {
                        name: customer === null || customer === void 0 ? void 0 : customer.name,
                        phone: customer === null || customer === void 0 ? void 0 : customer.phone
                    },
                    methods: omitEmptyValues({
                        cash: using.현금 ? Number(using.현금 || 0) : undefined,
                        card: using.카드 ? Number(using.카드 || 0) : undefined,
                        transfer: using.계좌이체 ? Number(using.계좌이체 || 0) : undefined,
                        unpaid: using.미수 ? Number(using.미수 || 0) : undefined,
                        couponDiscount: undefined,
                        discount: using.할인 ? Number(using.할인 || 0) : undefined,
                        customerPoint: using.포인트차감 ? Number(using.포인트차감 || 0) : undefined,
                        prepaidPoint: using.선불충전액차감 ? Number(using.선불충전액차감 || 0) : undefined,
                        etc1: using.기타1 ? Number(using.기타1 || 0) : undefined,
                        etc2: using.기타2 ? Number(using.기타2 || 0) : undefined
                    }),
                    createCustomerPoint: Number(using.포인트적립 || 0) || 0,
                    salesPerManager: (managerArray === null || managerArray === void 0 ? void 0 : managerArray.map(function (manager, index) {
                        return {
                            managerId: manager === null || manager === void 0 ? void 0 : manager.id,
                            type: 'ticket',
                            methods: {
                                cash: divideByManagerLength(using.현금, managerArray.length, index),
                                card: divideByManagerLength(using.카드, managerArray.length, index),
                                transfer: divideByManagerLength(using.계좌이체, managerArray.length, index),
                                unpaid: divideByManagerLength(using.미수, managerArray.length, index),
                                customerPoint: divideByManagerLength(using.포인트차감, managerArray.length, index),
                                prepaidPoint: divideByManagerLength(using.선불충전액차감, managerArray.length, index),
                                deductionPriceByTicket: 0,
                                discount: divideByManagerLength(using.할인, managerArray.length, index),
                                etc1: divideByManagerLength(using.기타1, managerArray.length, index),
                                etc2: divideByManagerLength(using.기타2, managerArray.length, index)
                            }
                        };
                    })) || []
                });
            }
        });
        if (!isEmpty(productUsings)) {
            // NOTE!! 마이그레이션과 시스템이 다른점!!
            // NOTE!! 하나의 그룹으로 묶이는 줄들(고객명과 날짜로 그룹화)의 결제액(받을금액)의 합은 = 모든줄의 ‘현금‘, ‘카드‘, ‘계좌이체‘, ‘할인‘, ‘미수‘, ‘기타1’, ‘기타2’의 합과 같아야합니다.
            var shouldPaid = sum(productUsings.map(function (using) { return getNumberOrZero(using['결제액(받을금액)']); }));
            var shouldRealPaid_1 = sum(productUsings.map(function (using) { return sum(shouldPaidKeysInMigration.map(function (key) { return getNumberOrZero(using[key]); })); }));
            if (shouldPaid !== shouldRealPaid_1) {
                var using = productUsings[0];
                throw new Error("".concat(using.고객명, " ").concat(using.판매일시, " \uACB0\uC81C\uC561(\uBC1B\uC744\uAE08\uC561) = '\uD604\uAE08', '\uCE74\uB4DC', '\uACC4\uC88C\uC774\uCCB4', '\uD560\uC778', '\uBBF8\uC218', '\uAE30\uD0C01', '\uAE30\uD0C02'\uC758 \uD569\uACFC \uAC19\uC544\uC57C\uD569\uB2C8\uB2E4."));
            }
            var totalMethod_1 = {
                현금: sum(productUsings.map(function (using) { return getNumberOrZero(using['현금']) || 0; })),
                카드: sum(productUsings.map(function (using) { return getNumberOrZero(using['카드']) || 0; })),
                계좌이체: sum(productUsings.map(function (using) { return getNumberOrZero(using['계좌이체']) || 0; })),
                할인: sum(productUsings.map(function (using) { return getNumberOrZero(using['할인']) || 0; })),
                미수: sum(productUsings.map(function (using) { return getNumberOrZero(using['미수']) || 0; })),
                기타1: sum(productUsings.map(function (using) { return getNumberOrZero(using['기타1']) || 0; })),
                기타2: sum(productUsings.map(function (using) { return getNumberOrZero(using['기타2']) || 0; }))
            };
            var restMethod_1 = totalMethod_1;
            var bigShareIndex_1 = 0;
            var lastShare_1 = 0;
            var newProductUsings = productUsings
                .map(function (using, index) {
                var shouldPaidInRow = getNumberOrZero(using['결제액(받을금액)']);
                var share = shouldPaidInRow / shouldRealPaid_1;
                if (share > lastShare_1) {
                    bigShareIndex_1 = index;
                    lastShare_1 = share;
                }
                var newUsing = __assign(__assign({}, using), { 현금: floor(totalMethod_1['현금'] * share), 카드: floor(totalMethod_1['카드'] * share), 계좌이체: floor(totalMethod_1['계좌이체'] * share), 할인: floor(totalMethod_1['할인'] * share), 미수: floor(totalMethod_1['미수'] * share), 기타1: floor(totalMethod_1['기타1'] * share), 기타2: floor(totalMethod_1['기타2'] * share) });
                restMethod_1 = {
                    현금: restMethod_1['현금'] - newUsing['현금'],
                    카드: restMethod_1['카드'] - newUsing['카드'],
                    계좌이체: restMethod_1['계좌이체'] - newUsing['계좌이체'],
                    할인: restMethod_1['할인'] - newUsing['할인'],
                    미수: restMethod_1['미수'] - newUsing['미수'],
                    기타1: restMethod_1['기타1'] - newUsing['기타1'],
                    기타2: restMethod_1['기타2'] - newUsing['기타2']
                };
                return newUsing;
            })
                .map(function (using, index) {
                // return using;
                if (index === bigShareIndex_1) {
                    return __assign(__assign({}, using), { 현금: using['현금'] + restMethod_1['현금'], 카드: using['카드'] + restMethod_1['카드'], 계좌이체: using['계좌이체'] + restMethod_1['계좌이체'], 할인: using['할인'] + restMethod_1['할인'], 미수: using['미수'] + restMethod_1['미수'], 기타1: using['기타1'] + restMethod_1['기타1'], 기타2: using['기타2'] + restMethod_1['기타2'] });
                }
                else {
                    return using;
                }
            });
            var usingHistory = newProductUsings
                .map(function (using) {
                var _a, _b;
                var customer = customers.find(function (target) {
                    var _a, _b;
                    return (target.phone && using.휴대폰 && ((_a = target.phone) === null || _a === void 0 ? void 0 : _a.replace(/-/g, '')) === ((_b = using.휴대폰) === null || _b === void 0 ? void 0 : _b.replace(/-/g, ''))) ||
                        (!target.phone && !using.휴대폰 && target.name === using.고객명);
                });
                if (!customer && !using.미등록고객구분) {
                    // TODO: 고객이 없으면 에러 기록 후 오류 표시
                    // return undefined;
                    // throw new Error('고객이 없습니다.');
                    return null;
                }
                var managerArray = [using.담당자, using.담당자2]
                    .map(function (managerName) {
                    if (managerName) {
                        return managers.find(function (manager) { var _a; return ((_a = manager.profile) === null || _a === void 0 ? void 0 : _a.name) === managerName; });
                    }
                })
                    .filter(Boolean) || [];
                var firmstManager = managerArray[0];
                var productGroup = productGroups.find(function (item) { return (item === null || item === void 0 ? void 0 : item.name) === using.구분1차; });
                var tempGroup = __assign(__assign({}, productGroup), { products: (_a = productGroup.products) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.name === using.구분2차; }) });
                var product = (_b = productGroup === null || productGroup === void 0 ? void 0 : productGroup.products) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.name === using.구분2차; });
                console.log('product', product);
                if (!product) {
                    // TODO: 고객이 없으면 에러 기록 후 오류 표시
                    // return undefined;
                    return undefined;
                }
                else {
                    var amount = Number(using.개수 || 1);
                    var adjustOption = using['%할인']
                        ? 'minusPercent'
                        : using['%추가']
                            ? 'plusPercent'
                            : using.정액할인
                                ? 'minusAmount'
                                : using.정액추가
                                    ? 'plusAmount'
                                    : '';
                    var adjustValue = using['%할인']
                        ? Number(using['%할인'])
                        : using['%추가']
                            ? Number(using['%추가'])
                            : using.정액할인
                                ? Number(using['정액할인'])
                                : using.정액추가
                                    ? Number(using['정액추가'])
                                    : 0;
                    var totalPrice = amount * Number(using.단가);
                    var deductionPriceByTicket_1 = Number(using.횟수권차감 || using.기간권차감 || 0);
                    var finalPrice = Number(using.총금액) - deductionPriceByTicket_1;
                    var customerPoint = Number(using.포인트차감 || 0);
                    var prepaidPoint = Number(using.선불충전액차감 || 0);
                    var discount = Number(using.할인 || 0);
                    var unpaid = Number(using.미수 || 0);
                    return {
                        amount: amount,
                        adjustOption: adjustOption,
                        adjustValue: adjustOption === '' ? 0 : Number(adjustValue),
                        adjustPrice: finalPrice - totalPrice + deductionPriceByTicket_1,
                        finalPrice: finalPrice,
                        deductionPriceByTicket: deductionPriceByTicket_1,
                        customer: !!using.미등록고객구분
                            ? undefined
                            : {
                                name: customer === null || customer === void 0 ? void 0 : customer.name,
                                phone: customer === null || customer === void 0 ? void 0 : customer.phone
                            },
                        storeId: storeId,
                        managerId: firmstManager === null || firmstManager === void 0 ? void 0 : firmstManager.id,
                        product: product,
                        productGroup: tempGroup,
                        createdAt: subtractNine(using.판매일시, true),
                        updatedAt: subtractNine(using.판매일시, true),
                        salesPerManager: (managerArray === null || managerArray === void 0 ? void 0 : managerArray.map(function (manager, index) {
                            return {
                                managerId: manager === null || manager === void 0 ? void 0 : manager.id,
                                type: 'product',
                                methods: {
                                    cash: divideByManagerLength(using.현금, managerArray.length, index),
                                    card: divideByManagerLength(using.카드, managerArray.length, index),
                                    transfer: divideByManagerLength(using.계좌이체, managerArray.length, index),
                                    discount: divideByManagerLength(using.할인, managerArray.length, index),
                                    customerPoint: divideByManagerLength(using.포인트차감, managerArray.length, index),
                                    prepaidPoint: divideByManagerLength(using.선불충전액차감, managerArray.length, index),
                                    deductionPriceByTicket: divideByManagerLength(deductionPriceByTicket_1, managerArray.length, index),
                                    unpaid: divideByManagerLength(using.미수, managerArray.length, index),
                                    etc1: divideByManagerLength(using.기타1, managerArray.length, index),
                                    etc2: divideByManagerLength(using.기타2, managerArray.length, index)
                                }
                            };
                        })) || [],
                        paymentInfo: {
                            paidAt: subtractNine(using.판매일시, true),
                            payerName: (customer === null || customer === void 0 ? void 0 : customer.name) || '미등록 고객',
                            createPoint: Number(using.포인트적립 || 0),
                            methods: omitEmptyValues({
                                customerPoint: customerPoint,
                                prepaidPoint: prepaidPoint,
                                discount: discount,
                                unpaid: unpaid,
                                deductionPriceByTicket: deductionPriceByTicket_1,
                                card: Number(using.카드 || 0),
                                cash: Number(using.현금 || 0),
                                transfer: Number(using.계좌이체 || 0),
                                etc1: Number(using.기타1 || 0),
                                etc2: Number(using.기타2 || 0)
                            }),
                            memo: using.메모
                        }
                        // TODO: groupId, paymentHistoryId
                    };
                }
            })
                .filter(Boolean);
            var firstUsing = usingHistory[0];
            if (!firstUsing) {
                return;
            }
            var payment = {
                paidAt: dayjs(firstUsing.createdAt),
                payerName: (_a = firstUsing.paymentInfo) === null || _a === void 0 ? void 0 : _a.payerName,
                status: PAYMENT_STATUS.COMPLETED,
                meta: {
                    location: PAYMENT_LOCATION.OFFLINE,
                    isGeneratedCashReceipts: false,
                    applySales: true,
                    isMigration: true
                },
                storeId: storeId,
                usingHistory: usingHistory,
                // managerId: firstUsing?.managerId,
                totalPrice: sum(usingHistory.map(function (using) { return using.finalPrice || 0; })),
                memo: usingHistory
                    .map(function (history) { var _a; return (_a = history === null || history === void 0 ? void 0 : history.paymentInfo) === null || _a === void 0 ? void 0 : _a.memo; })
                    .filter(Boolean)
                    .join(' '),
                customer: firstUsing === null || firstUsing === void 0 ? void 0 : firstUsing.customer,
                methods: omitEmptyValues({
                    cash: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.cash) || 0; })) || undefined,
                    card: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.card) || 0; })) || undefined,
                    transfer: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.transfer) || 0; })) || undefined,
                    discount: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.discount) || 0; })) || undefined,
                    // paidPrice: sum(usingHistory.map((using) => using?.paymentInfo?.paidPrice || 0)) || undefined,
                    customerPoint: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.customerPoint) || 0; })) || undefined,
                    prepaidPoint: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.prepaidPoint) || 0; })) || undefined,
                    deductionPriceByTicket: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.deductionPriceByTicket) || 0; })) || undefined,
                    unpaid: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.unpaid) || 0; })) || undefined,
                    etc1: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.etc1) || 0; })) || undefined,
                    etc2: sum(usingHistory.map(function (using) { var _a, _b; return ((_b = (_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.methods) === null || _b === void 0 ? void 0 : _b.etc2) || 0; })) || undefined,
                    couponDiscount: undefined
                }),
                createCustomerPoint: sum(usingHistory.map(function (using) { var _a; return ((_a = using === null || using === void 0 ? void 0 : using.paymentInfo) === null || _a === void 0 ? void 0 : _a.createPoint) || 0; })),
                createdAt: dayjs(firstUsing.createdAt),
                updatedAt: dayjs(firstUsing.createdAt)
            };
            validatePayments(payment);
            payments.push(payment);
        }
    });
    return payments;
};
var getProductGroupWithProducts = function (items, prevProductGroups, storeId) {
    var productGroups = prevProductGroups;
    items.map(function (product) {
        // 금액권이 아니면 제품을 넣어준다. 판매만을 데이터로
        var prevGroup = productGroups.find(function (group) { return group.name === product.groupName; });
        // 1차 구분 없을 경우
        if (!product.groupName || product.groupName === '') {
            prevGroup =
                productGroups.find(function (group) { return group.name === '일반'; }) ||
                    {
                        name: '일반',
                        storeId: storeId,
                        priority: 0
                    };
        }
        if (prevGroup) {
            var prevGroupducts = prevGroup.products || [];
            var prevProduct = prevGroupducts.find(function (item) { return item.name === product.productName; });
            if (!prevProduct) {
                prevGroupducts.push({ name: product.productName, storeId: storeId, priority: 0, price: Number(product.price) });
            }
        }
        else {
            productGroups.push({
                storeId: storeId,
                name: product.groupName,
                priority: 0,
                products: [{ name: product.productName, storeId: storeId, priority: 0, price: Number(product.price) }]
            });
        }
    });
    return productGroups;
};
var createTicketTemplates = function (items, templates, storeId) {
    var tempTicketTemplates = templates;
    items.map(function (templateData) {
        var _a, _b, _c, _d;
        var _e = getTemplateNameAndTypeByTicketTemplateData(templateData), type = _e.type, name = _e.name;
        // 금액권이 아니면 제품을 넣어준다. 판매만을 데이터로
        var prevTicketTemplate = templates.find(function (template) { return template.name === name; });
        if (!prevTicketTemplate) {
            tempTicketTemplates.push({
                storeId: storeId,
                name: name,
                price: templateData.price,
                type: type,
                data: type === TICKET_TYPE.TERM
                    ? {
                        unit: 'month',
                        amount: templateData.total,
                        expiredOption: {
                            unit: ((_a = templateData === null || templateData === void 0 ? void 0 : templateData.expiredOption) === null || _a === void 0 ? void 0 : _a.unit) || 'month',
                            amount: ((_b = templateData === null || templateData === void 0 ? void 0 : templateData.expiredOption) === null || _b === void 0 ? void 0 : _b.amount) || 12
                        }
                    }
                    : {
                        amount: templateData.total,
                        expiredOption: {
                            unit: ((_c = templateData === null || templateData === void 0 ? void 0 : templateData.expiredOption) === null || _c === void 0 ? void 0 : _c.unit) || 'month',
                            amount: ((_d = templateData === null || templateData === void 0 ? void 0 : templateData.expiredOption) === null || _d === void 0 ? void 0 : _d.amount) || 12
                        }
                    },
                product: {
                    groupName: templateData.groupName,
                    productName: templateData.productName
                }
            });
        }
    });
    return tempTicketTemplates;
};
var getTemplateNameAndTypeByTicketTemplateData = function (templateData) {
    var type = templateData.type === '금액권'
        ? TICKET_TYPE.CHARGING_MONEY
        : templateData.type === '기간권'
            ? TICKET_TYPE.TERM
            : TICKET_TYPE.COUNT;
    var name = type === TICKET_TYPE.CHARGING_MONEY
        ? "".concat(templateData.productName)
        : type === TICKET_TYPE.COUNT
            ? "(".concat(templateData.groupName, ") ").concat(templateData.productName, " ").concat(templateData.total, "\uD68C\uAD8C")
            : "(".concat(templateData.groupName, ") ").concat(templateData.productName, " ").concat(templateData.total, "\uAC1C\uC6D4\uAD8C");
    var data = type === TICKET_TYPE.CHARGING_MONEY
        ? { remain: 0, total: templateData.total }
        : type === TICKET_TYPE.COUNT
            ? { remain: templateData.remain || 0, total: templateData.total || 0 }
            : { startedAt: subtractNine(templateData.total, false), expiredAt: getKoreanExpiredAt(templateData.remain) };
    return { type: type, name: name, data: data };
};
var validateAndConvertSalesBuffer = function (file, _a) {
    var managers = _a.managers;
    return __awaiter(void 0, void 0, void 0, function () {
        var workbook, paymentSheet, ticketSheet, validationError, sales, tickets, err_3, value, rest, err_4, value, rest;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getWorkbook(file)];
                case 1:
                    workbook = _b.sent();
                    paymentSheet = sheetToJSON(workbook, '매출데이터');
                    ticketSheet = sheetToJSON(workbook, '횟수권데이터');
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, validatePaymentSheet(paymentSheet, { managers: managers })];
                case 3:
                    sales = _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    err_3 = _b.sent();
                    value = err_3.value, rest = __rest(err_3, ["value"]);
                    validationError = __assign(__assign({}, rest), { name: "\uB9E4\uCD9C\uB370\uC774\uD130 ".concat(err_3.name) });
                    return [3 /*break*/, 5];
                case 5:
                    _b.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, validateTicketSheet(ticketSheet)];
                case 6:
                    tickets = _b.sent();
                    return [3 /*break*/, 8];
                case 7:
                    err_4 = _b.sent();
                    value = err_4.value, rest = __rest(err_4, ["value"]);
                    validationError = __assign(__assign({}, rest), { name: "\uD69F\uC218\uAD8C\uB370\uC774\uD130 ".concat(err_4.name) });
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/, { validationError: validationError, paymentSheet: sales, ticketSheet: tickets }];
            }
        });
    });
};
var validatePayments = function (sales) {
    var totalPrice = sales.totalPrice;
    var methods = sales.methods;
    // const paidPrice =
    //   totalPrice -
    //   // (methods?.customerPoint || 0) -
    //   (methods?.prepaidPoint || 0) -
    //   (methods?.discount || 0) -
    // (methods?.unpaid || 0) -
    // (methods?.deductionPriceByTicket || 0) -
    // (methods?.etc1 || 0) -
    // (methods?.etc2 || 0);
    var realPaidPrice = sum(['card', 'unpaid', 'etc1', 'etc2', 'cash', 'transfer', 'discount', 'prepaidPoint', 'customerPoint'].map(function (key) { return (methods && methods[key]) || 0; }));
    if (totalPrice !== realPaidPrice) {
        throw new Error("\uC5D0\uB7EC : ".concat(JSON.stringify(sales)));
    }
};
var validateCustomerSheet = function (data, _a) {
    var managers = _a.managers, groups = _a.groups;
    var scheme = yup.array().of(yup.object().shape(__assign(__assign({}, customerValidator), { 생년월일: yup.date(), 메모: yup.string().trim(), 담당자: yup.string().oneOf(managers.map(function (item) { var _a; return (_a = item.profile) === null || _a === void 0 ? void 0 : _a.name; }) || []), 등급명: yup
            .string()
            .trim()
            .oneOf(groups.map(function (item) { return item === null || item === void 0 ? void 0 : item.name; }) || [])
            .required(), 마일리지: yup.number(), 마일리지만료일: yup.date(), 성별: yup.string().trim().oneOf(['남', '여']), 주소: yup.string().trim(), 이메일: yup.string().trim().email() })));
    return scheme.validate(data);
};
var validateProductSheet = function (data) {
    var scheme = yup.array().of(yup.object().shape({
        구분1차: yup.string().trim().required(),
        구분2차: yup.string().trim().required(),
        시술시간: yup.number().required(),
        금액: yup.number().required()
    }));
    return scheme.validate(data);
};
var validateTicketTemplateSheet = function (data) {
    var scheme = yup.array().of(yup.object().shape({
        정기권종류: yup.string().trim().oneOf(['횟수권', '금액권', '기간권']).required(),
        구분1차: yup.string().trim(),
        구분2차: yup.string().trim().required(),
        결제금액: yup.number().required(),
        제공: yup.number().required(),
        제공혜택: yup.string(),
        혜택: yup.number(),
        유효기간: yup.number()
    }));
    return scheme.validate(data);
};
var validatePaymentSheet = function (data, _a) {
    var managers = _a.managers;
    return yup
        .array()
        .of(yup.object().shape(__assign(__assign({ 판매일시: yup.date().required() }, customerValidator), { 판매구분: yup.string().trim().oneOf(['상품', '횟수권', '금액권', '기간권']).required(), 구분1차: yup.string().trim(), 구분2차: yup.string().trim().required(), 
        // 없는 담당자 인지 체크??
        담당자: yup
            .string()
            .trim()
            .oneOf(managers.map(function (item) { var _a; return (_a = item.profile) === null || _a === void 0 ? void 0 : _a.name; }) || []), 담당자2: yup
            .string()
            .trim()
            .oneOf(managers.map(function (item) { var _a; return (_a = item.profile) === null || _a === void 0 ? void 0 : _a.name; }) || []), 단가: yup.number().required(), '%할인': yup.number(), 적용금액: yup.number().required(), 개수: yup.number(), 총금액: yup.number().required(), 포인트차감: yup.number(), 횟수권차감: yup.number(), 선불충전액차감: yup.number(), '결제액(받을금액)': yup.number().required(), 현금: yup.number(), 카드: yup.number(), 할인: yup.number(), 미수: yup.number(), 기타1: yup.number(), 기타2: yup.number(), 포인트적립: yup.number(), 계좌이체: yup.number(), 미등록고객구분: yup.string(), 메모: yup.string() })))
        .validate(data);
};
var validateTicketSheet = function (data) {
    var scheme = yup.array().of(yup.object().shape(__assign(__assign({}, customerValidator), { 구분1차: yup.string().trim(), 구분2차: yup.string().trim().required(), 정기권종류: yup.string().oneOf(['횟수권', '기간권', '금액권']).required(), 
        // 총제공(시작일)
        총제공: yup
            .mixed()
            .when('정기권종류', {
            is: function (type) { return type !== '기간권'; },
            then: yup.number(),
            // then: yup.number('횟수권, 금액권의 총제공은 숫자여야합니다.'),
            otherwise: yup.date()
            // otherwise: yup.date('기간권의 총제공(시작일)은 날짜여야합니다.')
        })
            .required(), 
        // 잔여(종료일)
        잔여: yup
            .mixed()
            .when('정기권종류', {
            is: function (type) { return type !== '기간권'; },
            then: yup.number(),
            // then: yup.number('횟수권, 금액권의 잔여는 숫자여야합니다.'),
            otherwise: yup.date()
            // otherwise: yup.date('기간권의 잔여(종료일)은 날짜여야합니다.')
        })
            .required(), 유효기간: yup.number().required(), 만료일: yup.date() })));
    return scheme.validate(data);
};
var customerValidator = {
    고객명: yup.string().trim().max(30).required(),
    휴대폰: yup
        .string()
        .trim()
        .transform(function (value, _) {
        var first = value && value[0];
        return value ? formmatPhoneNumber(Number(first) === 1 ? "0".concat(value) : value) : value;
    })
        .matches(/^01([0|1|6|7|8|9]?)-([0-9]{3,4})-([0-9]{4})$/)
};
export var convertAhaToPlussharp = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, sheetName, ahaPayments, unpaidList, deletedList, payments, groupedByUserNumberAndPaidAt, alternatives, datas, finalUnpaidList, groupedByCustomerNumber, paymentSheet, deletedListSheet, unpaidListSheet, book;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!file) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, getWorkbook(file)];
            case 1:
                workbook = _a.sent();
                sheetName = workbook.SheetNames[0];
                ahaPayments = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                    raw: false,
                    dateNF: 'yyyy-MM-DD HH:mm'
                });
                unpaidList = [];
                deletedList = [];
                payments = ahaPayments
                    .map(function (payment) {
                    var _a, _b, _c, _d, _e, _f, _g;
                    var paidAt = dayjs(payment.판매일시.replace(/오후|오전/, ''));
                    var commons = {
                        // 팅커뷰 폼에 반영안됌(임시)
                        id: createUUID(),
                        // 팅커뷰 폼에 반영안됌(임시)
                        고객번호: payment.고객번호,
                        판매일시: (((_a = payment.판매일시) === null || _a === void 0 ? void 0 : _a.includes('오후'))
                            ? paidAt.hour() !== 12
                                ? paidAt.add(12, 'hour')
                                : paidAt
                            : paidAt.hour() === 12
                                ? paidAt.subtract(12, 'hour')
                                : paidAt).format('YYYY-MM-DD HH:mm'),
                        고객명: payment.고객명,
                        휴대폰: formmatPhoneNumber(payment.휴대폰),
                        담당자: payment.담당자1,
                        담당자2: payment.담당자2,
                        미등록고객구분: payment.고객명 === '미등록고객' ? 'o' : null,
                        메모: payment.메모,
                        단가: 0,
                        '%할인': 0,
                        '%추가': 0,
                        정액할인: 0,
                        정액추가: 0,
                        // Question2 이거 맞나??? (없애는게 맞거나 총금액에서 수량 빼야할 듯??)
                        적용금액: 0,
                        개수: 1,
                        총금액: 0,
                        포인트차감: 0,
                        // Question3 티켓 차감이 있으면 총금액은 무조건 0이되나??
                        횟수권차감: 0,
                        기간권차감: 0,
                        선불충전액차감: 0,
                        '결제액(받을금액)': 0,
                        현금: 0,
                        카드: 0,
                        계좌이체: 0,
                        할인: 0,
                        미수: 0,
                        기타1: 0,
                        기타2: 0,
                        포인트적립: 0
                    };
                    // 미수입금 처리
                    if (payment.판매구분 === '미수입금') {
                        unpaidList.push(payment);
                        // 서비스, 정액권, 티켓 처리
                    }
                    else if (['서비스', '정액권', '티켓', '제품', '티켓판매', '맞춤티켓'].includes(payment.판매구분)) {
                        // FIXME: %할인, %추가 일때 단가 - adjustPrice | 정액할인,정액추가일때는 그냥 단가그대로
                        var adjustPrice = ((_b = payment.할인) === null || _b === void 0 ? void 0 : _b.includes('%'))
                            ? (Number(payment.단가) * Number((_c = payment.할인) === null || _c === void 0 ? void 0 : _c.replace('%', ''))) / 100
                            : 0;
                        return __assign(__assign({}, commons), { 
                            // Question1. 티켓이 횟수권, 정액권이 금액권?
                            판매구분: payment.판매구분 === '제품' || payment.판매구분 === '서비스'
                                ? '상품'
                                : payment.판매구분 === '정액권'
                                    ? '금액권'
                                    : ['맞춤티켓', '티켓판매', '티켓'].includes(payment.판매구분)
                                        ? '횟수권'
                                        : '', 구분1차: payment.판매구분 === '제품' ? '제품' : payment.분류, 구분2차: payment['서비스/제품명'], 단가: payment.단가, '%할인': ((_d = payment.할인) === null || _d === void 0 ? void 0 : _d.includes('%')) ? Number((_e = payment.할인) === null || _e === void 0 ? void 0 : _e.replace('%', '')) : 0, 정액할인: ((_f = payment.할인) === null || _f === void 0 ? void 0 : _f.includes('원')) ? Number((_g = payment.할인) === null || _g === void 0 ? void 0 : _g.replace('원', '')) : 0, 
                            // Question2 이거 맞나??? (없애는게 맞거나 총금액에서 수량 빼야할 듯??)
                            // 적용금액: payment.할인?.includes('%') ? payment.단가 : price,
                            // 적용금액: using?.adjustOption?.includes('Percent') ? price + adjustPrice : price,
                            적용금액: Number(payment.단가) - adjustPrice, 개수: payment.수량, 총금액: Number(payment.총금액), 포인트차감: Number(payment.포인트차감), 
                            // Question3 티켓 차감이 있으면 총금액은 무조건 0이되나??
                            횟수권차감: Number(payment.티켓차감) !== 0 ? Number(payment.총금액) : 0, 선불충전액차감: payment.정액차감, '결제액(받을금액)': payment['매출(받을금액)'], 현금: payment.현금, 카드: payment.카드, 계좌이체: payment.계좌이체, 미수: payment.미수, 기타1: payment.기타, 포인트적립: payment.포인트적립 });
                    }
                    else if (payment.판매구분 === '정액권환불') {
                        return __assign(__assign({}, commons), { 판매구분: '상품', 구분1차: '환불', 구분2차: '정액권환불', 개수: 1, 메모: "".concat(payment['매출(받을금액)'], " \uD658\uBD88") });
                    }
                    else if (['맞춤티켓이용', '티켓이용'].includes(payment.판매구분)) {
                        return __assign(__assign({}, commons), { 판매구분: '상품', 구분1차: payment.분류, 구분2차: payment['서비스/제품명'], 단가: 1, 
                            // Question2 이거 맞나??? (없애는게 맞거나 총금액에서 수량 빼야할 듯??)
                            적용금액: 1, 개수: payment.수량, 총금액: payment.수량, 횟수권차감: payment.수량, 현금: payment.현금, 카드: payment.카드, 계좌이체: payment.계좌이체, 미수: payment.미수, 기타1: payment.기타 });
                    }
                    else {
                        deletedList.push(payment);
                    }
                })
                    .filter(Boolean);
                groupedByUserNumberAndPaidAt = groupBy(payments, function (item) { return "".concat(dayjs(item.판매일시)).concat(item.고객번호); });
                alternatives = [];
                datas = Object.keys(groupedByUserNumberAndPaidAt).map(function (key) {
                    var data = groupedByUserNumberAndPaidAt[key];
                    var isComplexSales = uniq(data.map(function (item) { return item.판매구분; })).length > 1;
                    if (isComplexSales) {
                        var restMethods_1 = {};
                        var keys_1 = ['현금', '카드', '계좌이체', '기타1', '미수'];
                        var paid = sum(keys_1.map(function (key) {
                            restMethods_1[key] = sum(data.map(function (item) { return Number(item[key] || 0); })) || 0;
                            return restMethods_1[key];
                        }));
                        var shouldPaid = sum(data.map(function (item) { return Number(item['결제액(받을금액)']) || 0; }));
                        if (paid !== shouldPaid) {
                            throw new Error("\uC815\uAE30\uAD8C, \uC0C1\uD488 \uBCF5\uD569 \uB9E4\uCD9C \uACC4\uC0B0 \uC911 \uAE08\uC561 \uC624\uB958 - ".concat(JSON.stringify(data)));
                        }
                        var newItems = data.map(function (item) {
                            var totalPrice = Number(item['결제액(받을금액)']);
                            var newItem = __assign({}, item);
                            keys_1.map(function (key) {
                                var available = restMethods_1[key];
                                if (totalPrice > 0) {
                                    if (available >= totalPrice) {
                                        newItem[key] = totalPrice;
                                        restMethods_1[key] = available - totalPrice;
                                        totalPrice = 0;
                                    }
                                    else if (available < totalPrice) {
                                        newItem[key] = available;
                                        restMethods_1[key] = 0;
                                        totalPrice = totalPrice - available;
                                    }
                                }
                                else {
                                    newItem[key] = 0;
                                    // methods[key] = 0;
                                }
                            });
                            return newItem;
                        });
                        alternatives = alternatives.concat(newItems);
                    }
                    // data.map((item)=> item.)
                });
                payments = payments.map(function (item) {
                    return alternatives.find(function (alt) { return alt.id === item.id; }) || item;
                });
                // 2. 미수입금 해결
                alternatives = [];
                finalUnpaidList = [];
                groupedByCustomerNumber = groupBy(unpaidList, function (item) { return item.고객번호; });
                Object.keys(groupedByCustomerNumber).map(function (groupKey) {
                    // 고객번호가 0인경우 - 미등록 고객일 경우 제외
                    if (Number(groupKey) === 0) {
                        return;
                    }
                    var unpaids = groupedByCustomerNumber[groupKey];
                    var shouldPaid = payments.filter(function (payment) { return payment.고객번호 === groupKey; }).map(function (item) { return (__assign({}, item)); });
                    var totalPaidUnpaid = sum(unpaids.map(function (unpaid) { return Number(unpaid.총금액); }));
                    var keys = ['현금', '카드', '계좌이체', '기타'];
                    unpaids.map(function (unpaid) {
                        // unpaid
                        var remainUnpaid = -Number(unpaid.미수);
                        if (remainUnpaid !== Number(unpaid.총금액)) {
                            throw new Error("".concat(unpaid.판매일시, ", ").concat(unpaid.고객번호, " \uBBF8\uC218\uC785\uAE08 \uCC98\uB9AC \uC5D0\uB7EC"));
                        }
                        shouldPaid = shouldPaid.map(function (item) {
                            var unpaidInShould = Number(item.미수);
                            if (unpaidInShould > 0) {
                                var newUnpaidInShould = 0;
                                if (remainUnpaid <= unpaidInShould) {
                                    newUnpaidInShould = unpaidInShould - remainUnpaid;
                                    keys.map(function (methodKey) {
                                        var methodKeyInPaid = methodKey === '기타' ? '기타1' : methodKey;
                                        var methodAmountInUnpaid = Number(unpaid[methodKey]);
                                        item[methodKeyInPaid] = Number(item[methodKeyInPaid]) + methodAmountInUnpaid;
                                        unpaid[methodKey] = 0;
                                    });
                                    remainUnpaid = 0;
                                    return __assign(__assign({}, item), { 미수: newUnpaidInShould });
                                }
                                else {
                                    newUnpaidInShould = unpaidInShould;
                                    var left_1 = unpaidInShould;
                                    keys.map(function (methodKey) {
                                        var methodKeyInPaid = methodKey === '기타' ? '기타1' : methodKey;
                                        var methodAmountInUnpaid = Number(unpaid[methodKey]);
                                        var chargedValue = methodAmountInUnpaid > left_1 ? left_1 : methodAmountInUnpaid;
                                        item[methodKeyInPaid] = Number(item[methodKeyInPaid]) + chargedValue;
                                        unpaid[methodKey] = methodAmountInUnpaid - chargedValue;
                                        left_1 = left_1 - chargedValue;
                                    });
                                    remainUnpaid = remainUnpaid - unpaidInShould;
                                    // 남은 금액이 더 클 경우
                                    return __assign(__assign({}, item), { 미수: 0 });
                                }
                            }
                            else {
                                return item;
                            }
                        });
                        if (remainUnpaid > 0) {
                            finalUnpaidList.push(__assign(__assign({}, unpaid), { 미수: remainUnpaid }));
                        }
                        return __assign(__assign({}, unpaid), { 미수: remainUnpaid });
                    });
                    alternatives.push.apply(alternatives, shouldPaid);
                    // customerPayments.map()
                });
                payments = payments.map(function (item) {
                    return alternatives.find(function (alt) { return alt.id === item.id; }) || item;
                });
                paymentSheet = XLSX.utils.json_to_sheet(payments.filter(Boolean).map(function (item) {
                    delete item.id;
                    delete item.고객번호;
                    return item;
                }), {
                    header: [
                        '판매일시',
                        '고객명',
                        '휴대폰',
                        '판매구분',
                        '구분1차',
                        '구분2차',
                        '담당자',
                        '담당자2',
                        '단가',
                        '%할인',
                        '%추가',
                        '정액할인',
                        '정액추가',
                        '적용금액',
                        '개수',
                        '총금액',
                        '포인트차감',
                        '횟수권차감',
                        '기간권차감',
                        '선불충전액차감',
                        '결제액(받을금액)',
                        '현금',
                        '카드',
                        '계좌이체',
                        '할인',
                        '미수',
                        '기타1',
                        '기타2',
                        '포인트적립',
                        '미등록고객구분',
                        '메모'
                    ]
                });
                deletedListSheet = XLSX.utils.json_to_sheet(deletedList);
                unpaidListSheet = XLSX.utils.json_to_sheet(finalUnpaidList);
                book = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(book, paymentSheet, '매출데이터');
                XLSX.utils.book_append_sheet(book, deletedListSheet, '삭제데이터');
                XLSX.utils.book_append_sheet(book, unpaidListSheet, '미수금 미처리내역');
                XLSX.writeFile(book, "converted_".concat(file.name));
                return [2 /*return*/, paymentSheet];
        }
    });
}); };
export var preprocessAhaCustomer = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    var workbook, sheetName, phones, ahaCustomers, paymentSheet, book;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!file) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, getWorkbook(file)];
            case 1:
                workbook = _a.sent();
                sheetName = workbook.SheetNames[0];
                phones = [];
                ahaCustomers = orderBy(orderBy(XLSX.utils
                    .sheet_to_json(workbook.Sheets[sheetName], {
                    raw: false,
                    dateNF: 'yyyy-MM-DD HH:mm'
                })
                    .map(function (item) {
                    return __assign(__assign({}, item), { 휴대폰: formmatPhoneNumber(item.휴대폰), 최근방문일: item.최근방문일 ? dayjs(item.최근방문일).format('YYYY-MM-DD') : '' });
                }), function (item) { return item.최근방문일 || ''; }, 'desc').map(function (item) {
                    if (phones.includes(item.휴대폰)) {
                        return __assign(__assign({}, item), { 휴대폰: '', 메모: item.휴대폰 ? "".concat(item.메모 || '', " ").concat(item.휴대폰 || '') : item.memo });
                    }
                    else {
                        phones.push(item.휴대폰);
                        return item;
                    }
                }), function (item) { return item.고객번호; }, 'asc');
                paymentSheet = XLSX.utils.json_to_sheet(ahaCustomers, {
                    headers: [
                        '등록일',
                        '고객번호',
                        '고객명',
                        '성별',
                        '고객등급',
                        '고객구분',
                        '휴대폰',
                        '휴대폰수신거부',
                        '총매출',
                        '판매횟수',
                        '선불잔액',
                        '선불만료일',
                        '메모',
                        '담당자',
                        '생일',
                        '결혼기념일',
                        '방문경로',
                        '회원구분',
                        '객단가',
                        '전화번호',
                        '잔여포인트',
                        '소개횟수',
                        '미수금',
                        '최근방문일',
                        '우편번호',
                        '주소',
                        '이메일'
                    ]
                });
                book = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(book, paymentSheet, '고객데이터');
                XLSX.writeFile(book, "converted_".concat(file.name));
                return [2 /*return*/, paymentSheet];
        }
    });
}); };
// let handSOSJSON = require('../../../../../../test.json');
export var mergeCustomerDataWithSalesFromHandSOS = function (payload, origin) { return __awaiter(void 0, void 0, void 0, function () {
    var customerFile, salesFile, rest, workbook, sheetName, handsosCustomers, excelSales, customers, salesDataResponse, prevMemoKey, saleList, book, paymentSheet;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload.customerFile) {
                    return [2 /*return*/];
                }
                customerFile = payload.customerFile, salesFile = payload.salesFile, rest = __rest(payload, ["customerFile", "salesFile"]);
                return [4 /*yield*/, getWorkbook(payload.customerFile)];
            case 1:
                workbook = _b.sent();
                sheetName = workbook.SheetNames[0];
                handsosCustomers = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                    raw: false,
                    range: 1,
                    dateNF: 'yyyy-MM-DD HH:mm'
                });
                excelSales = [];
                return [4 /*yield*/, mapSeries(orderBy(salesFile, 'name', 'desc'), function (file) { return __awaiter(void 0, void 0, void 0, function () {
                        var workbook, sheetName, excelSalesJSON;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getWorkbook(file)];
                                case 1:
                                    workbook = _a.sent();
                                    sheetName = workbook.SheetNames[0];
                                    excelSalesJSON = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                                        raw: false,
                                        dateNF: 'yyyy-MM-DD HH:mm',
                                        range: 22
                                        // range: 16
                                    });
                                    excelSales = excelSales.concat(excelSalesJSON.filter(Boolean));
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            case 2:
                _b.sent();
                customers = unionByNameAndPhone(handsosCustomers
                    .filter(Boolean)
                    .map(function (customer) {
                    if (!(customer === null || customer === void 0 ? void 0 : customer.성명) && !(customer === null || customer === void 0 ? void 0 : customer.핸드폰)) {
                        return;
                    }
                    return {
                        성명: customer === null || customer === void 0 ? void 0 : customer.성명,
                        핸드폰: customer === null || customer === void 0 ? void 0 : customer.핸드폰
                    };
                })
                    .filter(Boolean), { phone: '핸드폰', name: '성명' });
                return [4 /*yield*/, RestClient.getInstance().post("".concat(origin, "/dev/spy/getHandSOSData"), rest, {
                        timeout: -1
                    })];
            case 3:
                salesDataResponse = _b.sent();
                prevMemoKey = '';
                saleList = (((_a = salesDataResponse === null || salesDataResponse === void 0 ? void 0 : salesDataResponse.data) === null || _a === void 0 ? void 0 : _a.salesList) || [])
                    .map(function (sale, index) {
                    // 날짜	고객	핸드폰	구분	메뉴	상세메뉴	담당	판매가	할인%	할인액	결제액	현금	카드	통장	정액	외상	회원권	상품권	결제P	적립P	방문	카드
                    // 22-05-18  18:49","이름","3646","시술","페이스.","구레나룻","혜현","20,000"," 20","0","16,000","0","0","16,000","0","0","0","0","0","0","소개",""
                    var excelSale = excelSales[index];
                    if (!excelSale) {
                        console.log('excelSale', excelSale, sale, index);
                    }
                    var date = dayjs("20".concat(sale[0].replace(/\s/g, '')));
                    var excelDate = dayjs("20".concat(excelSale['날짜'].replace(/\s/g, '')));
                    var currentMemoKey = "".concat(excelDate.format(DATE_FORMAT_INCLUDE_TIME), "_").concat(excelSale['고객'], "_").concat(excelSale['메모']);
                    var stringTrim = function (val) { return ((val === null || val === void 0 ? void 0 : val.trim) && val.trim()) || ''; };
                    // 핸드SOS 매출테이블 웹화면 순서(sale순서)
                    // 날짜	고객	핸드폰	구분	메뉴	상세메뉴	담당	판매가	할인%	할인액	결제액	현금	카드	통장	정액	외상	회원권	상품권	결제P	적립P	방문	카드(겹침)
                    var newItem = {
                        날짜: date.format('YYYY-MM-DD HH:mm'),
                        엑셀날짜: excelDate.format('YYYY-MM-DD HH:mm'),
                        검증: date.isSame(excelDate, 'minute') ? '' : '비정상',
                        고객: stringTrim(excelSale['고객']),
                        핸드폰: '',
                        구분: stringTrim(sale[3]),
                        메뉴: stringTrim(excelSale['메뉴'] || sale[4]),
                        상세메뉴: stringTrim(excelSale['상세메뉴'] || sale[5]),
                        수량: stringTrim(excelSale['수량']),
                        담당: stringTrim(sale[6]),
                        판매가: stringTrim(sale[7]),
                        '할인%': stringTrim(sale[8]),
                        할인액: stringTrim(sale[9]),
                        결제액: stringTrim(sale[10]),
                        현금: stringTrim(sale[11]),
                        카드: stringTrim(sale[12]),
                        통장: stringTrim(sale[13]),
                        정액: stringTrim(sale[14]),
                        외상: stringTrim(sale[15]),
                        회원권: stringTrim(sale[16]),
                        상품권: stringTrim(sale[17]),
                        결제P: stringTrim(sale[18]),
                        적립P: stringTrim(sale[19]),
                        방문: stringTrim(sale[20]),
                        '카드?': stringTrim(sale[21]),
                        메모: stringTrim(currentMemoKey === prevMemoKey ? '' : excelSale['메모'])
                    };
                    var customer = customers.find(function (item) {
                        var phoneEnd = (item.핸드폰 && item.핸드폰.slice(-4)) || '';
                        return item.성명 == newItem['고객'] && phoneEnd == sale[2];
                    });
                    newItem['고객'] = (customer === null || customer === void 0 ? void 0 : customer.성명) || excelSale['고객'] || sale[1] || '';
                    newItem['핸드폰'] = (customer === null || customer === void 0 ? void 0 : customer.핸드폰) || sale[2] || '';
                    prevMemoKey = currentMemoKey;
                    return newItem;
                })
                    .filter(Boolean);
                book = XLSX.utils.book_new();
                paymentSheet = XLSX.utils.json_to_sheet(saleList, {
                    headers: [
                        '날짜',
                        '엑셀날짜',
                        '검증',
                        '고객',
                        '핸드폰',
                        '구분',
                        '메뉴',
                        '수량',
                        '상세메뉴',
                        '담당',
                        '판매가',
                        '할인%',
                        '할인액',
                        '결제액',
                        '현금',
                        '카드',
                        '통장',
                        '정액',
                        '외상',
                        '회원권',
                        '상품권',
                        '결제P',
                        '적립P',
                        '방문'
                    ]
                });
                XLSX.utils.book_append_sheet(book, paymentSheet, '매출데이터');
                XLSX.writeFile(book, "HandSOS_\uB9E4\uCD9C\uB370\uC774\uD130_".concat(dayjs().format(DATE_FORMAT_INCLUDE_TIME), ".xlsx"));
                return [2 /*return*/, saleList];
        }
    });
}); };
export var mergeCustomerDataWithSalesFromYonggam = function (payload, origin) { return __awaiter(void 0, void 0, void 0, function () {
    var customerFile, rest, workbook, sheetName, yonggamCustomersInExcel, salesDataResponse, phones, customers, lastPaidAt, lastMemberIdx, refineNumber, saleList, tickets, salesSheet, customerSheet, ticketSheet, book;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                if (!payload.customerFile) {
                    return [2 /*return*/];
                }
                customerFile = payload.customerFile, rest = __rest(payload, ["customerFile"]);
                return [4 /*yield*/, getWorkbook(payload.customerFile)];
            case 1:
                workbook = _d.sent();
                sheetName = workbook.SheetNames[0];
                yonggamCustomersInExcel = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                    raw: false,
                    dateNF: 'yyyy-MM-DD HH:mm',
                    range: 1
                });
                return [4 /*yield*/, RestClient.getInstance().post("".concat(origin, "/dev/spy/getYonggamBeauty"), rest, {
                        timeout: -1
                    })];
            case 2:
                salesDataResponse = _d.sent();
                phones = [];
                customers = orderBy(salesDataResponse.data.customers.map(function (customer, index) {
                    var customerInExcel = yonggamCustomersInExcel[index];
                    return __assign(__assign({}, customer), { memo: customerInExcel.메모, lastDate: customerInExcel['최근방문일'] !== '-' && customerInExcel['최근방문일']
                            ? dayjs("20".concat(customerInExcel['최근방문일'])).format('YYYY-MM-DD')
                            : '' });
                }), function (item) { return item.lastDate || ''; }, 'desc').map(function (item) {
                    if (phones.includes(item.phone)) {
                        return __assign(__assign({}, item), { name: "".concat(item.name).concat(item.memberIdx), phone: '', gender: !item.gender ? '' : item.gender === 'female' ? '여' : '남', memo: item.phone ? "".concat(item.memo || '', " ").concat(item.phone || '') : item.memo });
                    }
                    else {
                        phones.push(item.phone);
                        return item;
                    }
                }).map(function (item) {
                    return {
                        memberIdx: item.memberIdx || '',
                        // 2016-02-22 오후 7:17:00
                        고객명: item.name || '',
                        휴대폰: item.phone || '',
                        수신거부: '',
                        생년월일: '',
                        메모: item.memo || '',
                        담당자: item.manager || '',
                        등급명: item.group === '-' || !item.group ? '기본등급' : item.group,
                        마일리지: 0,
                        마일리지만료일: '',
                        성별: item.gender === 'female' ? '여' : item.gender === 'male' ? '남' : '',
                        주소: ''
                    };
                });
                lastPaidAt = '';
                lastMemberIdx = '';
                refineNumber = function (value) {
                    return Number(value.split('\t')[0].replace(/,/g, '') || 0);
                };
                /**
                 * TODO
                 * 1. 할인 처리 어케함(맞게되었는지 확인필요)
                 * 2. P결제 처리어케함?
                 * */
                console.log('salesDataResponse?.data?.sales', (_a = salesDataResponse === null || salesDataResponse === void 0 ? void 0 : salesDataResponse.data) === null || _a === void 0 ? void 0 : _a.sales);
                saleList = (((_b = salesDataResponse === null || salesDataResponse === void 0 ? void 0 : salesDataResponse.data) === null || _b === void 0 ? void 0 : _b.sales) || []).map(function (sale, index) {
                    var currentPaidAt = sale.paidAt === '' ? lastPaidAt : sale.paidAt;
                    var currentMemberIdx = sale.memberIdx === '' ? (sale.name === '' && sale.memo === '손님' ? '손님' : lastMemberIdx) : sale.memberIdx;
                    lastPaidAt = currentPaidAt;
                    lastMemberIdx = currentMemberIdx;
                    var customer = customers.find(function (item) { return item.memberIdx === lastMemberIdx; });
                    // 날짜	고객명	구분	대메뉴	상세메뉴	사진	담당자	수량	원가	%	할인	결제	현금	계좌	카드	N-pay	정액	횟수권	외상	P결제	P적립	방문	메모
                    /**
                     *  용감한 뷰티
                     *  결제 금액 = 수량 * 원가
                     *  결제 금액 - 횟수권 = 결제 금액 - 현금 - 계좌 - N-pay - 정액 - 외상
                     *
                     *  팅커뷰
                     *  결제액(받을금액) = 총금액 - (포인트차감 + 횟수권차감 + 기간권차감 + 선불충전액차감) 이어야함
                     *  총금액 = 단가 * 개수
                     *
                     */
                    // paidPrice 에 외상,선불액, 포함
                    // paidPrice에 횟수권 미포함
                    var pecentDiscount = refineNumber(sale.percentDiscount);
                    var discountPrice = refineNumber(sale.discountPrice);
                    var appliedPrice = refineNumber(sale.price) + refineNumber(sale.count);
                    // 정액할인, %할인 동시에 적용시 하나로 통합
                    if (pecentDiscount && discountPrice) {
                        var percentPrice = appliedPrice * refineNumber(sale.amount) * (pecentDiscount / 100);
                        discountPrice = percentPrice + discountPrice;
                        pecentDiscount = 0;
                    }
                    else {
                        if (pecentDiscount) {
                            appliedPrice = appliedPrice * ((100 - pecentDiscount) / 100);
                        }
                    }
                    var paidPrice = refineNumber(sale.paidPrice) - refineNumber(sale.prepaid);
                    var commons = {
                        // 팅커뷰 폼에 반영안됌(임시)
                        id: createUUID(),
                        // 팅커뷰 폼에 반영안됌(임시)
                        memberIdx: currentMemberIdx,
                        판매일시: dayjs("20".concat(lastPaidAt)).format(DATE_FORMAT_INCLUDE_TIME) || '',
                        고객명: (customer === null || customer === void 0 ? void 0 : customer.고객명) || '미등록고객',
                        휴대폰: customer === null || customer === void 0 ? void 0 : customer.휴대폰,
                        미등록고객구분: !customer ? 'o' : '',
                        메모: sale.memo || '',
                        담당자: sale.manager || '',
                        담당자2: '',
                        단가: sale.price,
                        '%할인': pecentDiscount,
                        '%추가': 0,
                        정액할인: discountPrice,
                        정액추가: 0,
                        // Question2 이거 맞나??? (없애는게 맞거나 총금액에서 수량 빼야할 듯??)
                        적용금액: appliedPrice,
                        개수: refineNumber(sale.amount),
                        총금액: appliedPrice * refineNumber(sale.amount) - discountPrice,
                        포인트차감: 0,
                        // Question3 티켓 차감이 있으면 총금액은 무조건 0이되나??
                        횟수권차감: refineNumber(sale.count),
                        기간권차감: 0,
                        선불충전액차감: refineNumber(sale.prepaid),
                        '결제액(받을금액)': paidPrice,
                        현금: refineNumber(sale.cash),
                        카드: refineNumber(sale.card),
                        계좌이체: refineNumber(sale.transfer),
                        할인: 0,
                        미수: refineNumber(sale.unpaid),
                        기타1: refineNumber(sale.nPay),
                        기타2: 0,
                        포인트적립: 0
                    };
                    var type = sale['구분'];
                    if (type === '시술') {
                        return __assign(__assign({}, commons), { 판매구분: '상품', 구분1차: sale.groupName, 구분2차: sale.productName });
                    }
                    else if (type === '제품') {
                        return __assign(__assign({}, commons), { 판매구분: '상품', 구분1차: '제품', 구분2차: sale.productName });
                    }
                    else if (type === '정액권') {
                        return __assign(__assign({}, commons), { 판매구분: '금액권', 구분1차: '', 구분2차: sale.productName });
                    }
                    else if (type === '횟수권') {
                        var ticket = salesDataResponse.data.ticketTemplates.find(function (item) { return item.type === 'count' && item.name === sale.productName; });
                        return __assign(__assign({}, commons), { 판매구분: '횟수권', 구분1차: (ticket === null || ticket === void 0 ? void 0 : ticket.groupName) || '데이터이전', 구분2차: (ticket === null || ticket === void 0 ? void 0 : ticket.productName) || sale.productName });
                    }
                    // TODO: 미수금 처리 (외상수금)
                });
                tickets = [];
                (_c = salesDataResponse.data.tickets) === null || _c === void 0 ? void 0 : _c.map(function (ticket) {
                    var _a;
                    var customer = customers.find(function (item) { return item.memberIdx === ticket.memberIdx; });
                    var type = ticket.type;
                    if (!type) {
                        return;
                    }
                    var ticketTemplate = salesDataResponse.data.ticketTemplates.find(function (item) { var _a, _b; return ((_a = item.name) === null || _a === void 0 ? void 0 : _a.trim()) === ((_b = ticket.ticketName) === null || _b === void 0 ? void 0 : _b.trim()); });
                    tickets.push({
                        정기권종류: type,
                        고객명: (customer === null || customer === void 0 ? void 0 : customer.고객명) || '',
                        휴대폰: (customer === null || customer === void 0 ? void 0 : customer.휴대폰) || '',
                        구분1차: type === '금액권' ? '' : (ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.groupName) || '데이터이전',
                        구분2차: type === '금액권'
                            ? ticketTemplate
                                ? ticketTemplate.name
                                : KRWFomatToString({ value: getNumberOrZero(ticket.remain) })
                            : (ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.productName) || ticket.ticketName,
                        결제금액: (ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.price) ? refineNumber(ticketTemplate.price) : 0,
                        만료일: ticket.expiryDate ? dayjs(ticket.expiryDate).format(DATE_FORMAT) : '',
                        제공: (ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.amount)
                            ? refineNumber((_a = ticketTemplate === null || ticketTemplate === void 0 ? void 0 : ticketTemplate.amount) === null || _a === void 0 ? void 0 : _a.replace('회'))
                            : getNumberOrZero(ticket.remain) || 0,
                        잔여: getNumberOrZero(ticket.remain)
                    });
                });
                salesSheet = XLSX.utils.json_to_sheet(saleList.filter(Boolean).map(function (item) {
                    delete item.custCode;
                    return item;
                }), {
                    header: [
                        '판매일시',
                        '고객명',
                        '휴대폰',
                        '판매구분',
                        '구분1차',
                        '구분2차',
                        '담당자',
                        '담당자2',
                        '단가',
                        '%할인',
                        '%추가',
                        '정액할인',
                        '정액추가',
                        '적용금액',
                        '개수',
                        '총금액',
                        '포인트차감',
                        '횟수권차감',
                        '기간권차감',
                        '선불충전액차감',
                        '결제액(받을금액)',
                        '현금',
                        '카드',
                        '계좌이체',
                        '할인',
                        '미수',
                        '기타1',
                        '기타2',
                        '포인트적립',
                        '미등록고객구분',
                        '메모'
                    ]
                });
                customerSheet = XLSX.utils.json_to_sheet(customers.filter(Boolean));
                ticketSheet = XLSX.utils.json_to_sheet(tickets.filter(Boolean));
                book = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(book, customerSheet, '고객데이터');
                XLSX.utils.book_append_sheet(book, salesSheet, '매출데이터');
                XLSX.utils.book_append_sheet(book, ticketSheet, '횟수권데이터');
                XLSX.writeFile(book, "\uC6A9\uAC10\uD55C\uBDF0\uD2F0_".concat(dayjs().format(DATE_FORMAT_INCLUDE_TIME), ".xlsx"));
                return [2 /*return*/];
        }
    });
}); };
function getWorkbook(file) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array' });
            resolve(workbook);
        };
        reader.readAsArrayBuffer(file);
    });
}
function sheetToJSON(workbook, sheetName, dateNF) {
    if (dateNF === void 0) { dateNF = 'yyyy-MM-DD HH:mm'; }
    return XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        raw: false,
        dateNF: dateNF
    });
}
export var formmatPhoneNumber = function (phone) {
    var target = phone && phone.replace(/[^0-9]/g, '');
    var formmated = target && target.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
    return formmated;
};
var subtractNine = function (birth, subtractNine) {
    var date = dayjs(birth);
    return date.isValid() ? date.toDate() : null;
};
var getKoreanExpiredAt = function (date) {
    return date
        ? dayjs("".concat(dayjs(date).format('YYYY-MM-DD'), " 14:59"))
            .endOf('minute')
            .toDate()
        : null;
};
var unionByNameAndPhone = function (customers, keys) {
    if (keys === void 0) { keys = { phone: 'phone', name: 'name' }; }
    return unionWith(customers, function (data, data2) {
        var phone1 = data[keys.phone];
        var phone2 = data2[keys.phone];
        if (phone1 && phone2) {
            return phone1 === phone2;
        }
        else {
            if (!phone1 && !phone2) {
                var name1 = data[keys.name];
                var name2 = data2[keys.name];
                return name1 === name2;
            }
            return false;
        }
    });
};
var isEqualCustomer = function (customer1, customer2) {
    return ((customer1.phone && customer2.phone && customer1.phone === customer2.phone) ||
        (!customer1.phone && !customer2.phone && customer1.name === customer2.name));
};
export var convertBeautysarangToThinkofyou = function (beautyData) {
    // 메모에 넣고 전화번호 빼고
    // 콘텐츠에 컴마있는 경우 ()
    var phones = [];
    // beautyData = example as any;
    var customers = orderBy(orderBy(beautyData.customers.map(function (item) {
        return __assign(__assign({}, item), { 
            // 고객 파일에서 추출
            // gender: file[index].성별
            // address:( !file[index].주소 || file[index].주소  === '--' )? '': file[index].주소
            hphone: formmatPhoneNumber(item.hphone).length === 12 || formmatPhoneNumber(item.hphone).length === 13
                ? formmatPhoneNumber(item.hphone)
                : '', lastDate: item.lastDate ? dayjs(item.lastDate).format('YYYY-MM-DD') : '' });
    }), function (item) { return item.lastDate || ''; }, 'desc').map(function (item) {
        if (phones.includes(item.hphone)) {
            return __assign(__assign({}, item), { name: "".concat(item.name).concat(item.custCode), hphone: '', memo: item.hphone ? "".concat(item.memo || '', " ").concat(item.hphone || '') : item.memo });
        }
        else {
            phones.push(item.hphone);
            return item;
        }
    }), function (item) { return item.custCode; }, 'asc').map(function (item) {
        return {
            custCode: item.custCode || '',
            // 2016-02-22 오후 7:17:00
            고객명: item.name || '',
            휴대폰: item.hphone || '',
            수신거부: '',
            생년월일: '',
            메모: item.memo || '',
            담당자: item.staffName || '',
            등급명: item.levelName || '기본등급',
            마일리지: item.point || 0,
            마일리지만료일: null || '',
            성별: item.gender || '',
            주소: item.address || ''
        };
    });
    // 환불, 미수정산
    /**
     * TODO:
     * 1. 매출 담당자가 3명이상일 경우 대응이안됌 -> 안하기로 2명만
     * 2. 기간 이용권이 할인개념이라 그대로 옮겨올 수 없음(우리는 기간권에 상품이 연결되야함) -> 기간이용권 없음
     * 3. 기간권으로 할인받은 데이터를 안줌 그냥 총액에서 빠져있음 (그래서 기간권 데이터 못옮김) -> 기간이용권 없음
     * 4. 예치금이라는 개념이 있는데 우리는 없음 (포인트에 그냥 합쳐서 계산) -> 일단 이대로
     * 5. 구분 1차 어케 가져옴?
     * 6. 주소랑 성별은 안옮겨짐 (파일가져오면 되긴할듯)
     */
    var getSaleDataFromSale = function (sale, isCalculateUnpaid) {
        var unpaid = getNumberOrZero(sale.priceReceivables);
        // 마이너스면 미수금 정산
        var point = getNumberOrZero(sale.useDeposit) + getNumberOrZero(sale.usePoint);
        // 팅커뷰는 지불할 금액에 미수와 할인도 포함시켜야함
        var paidPrice = getNumberOrZero(sale.priceTotal) + getNumberOrZero(sale.priceDiscount) + (isCalculateUnpaid ? 0 : unpaid);
        var deductionTicket = !sale.couponCountTxt || sale.couponCountTxt === '-'
            ? 0
            : getNumberOrZero(sale.couponCountTxt.split(' ').pop().replace('회', '')) || 1;
        var prepaidPoint = getNumberOrZero(sale.useCash);
        var price = paidPrice + point + deductionTicket + prepaidPoint;
        return {
            단가: price,
            포인트차감: point,
            횟수권차감: deductionTicket,
            총금액: price,
            // TODO 기간권으로 할인받은 데이터를 안줌 그냥 총액에서 빠져있음 (그래서 기간권 데이터 못옮김)
            // 기간권차감: getNumberOrZero(sale.useCash),
            선불충전액차감: prepaidPoint,
            '결제액(받을금액)': paidPrice,
            현금: getNumberOrZero(sale.priceCash),
            카드: getNumberOrZero(sale.priceCard),
            계좌이체: getNumberOrZero(sale.priceBank),
            할인: getNumberOrZero(sale.priceDiscount),
            기타1: getNumberOrZero(sale.priceEtc),
            미수: unpaid
        };
    };
    var sales = [];
    var unpaidSales = [];
    var serviceAndGroup = beautyData.products;
    var latestMemoKey = '';
    beautyData.sales.map(function (sale) {
        var _a, _b, _c, _d;
        //
        var customer = customers.find(function (item) { return item.custCode === sale.custCode; });
        var accountDate = dayjs(sale.accountDate);
        var managers = sale.staffName.startsWith('<div>')
            ? sale.staffName
                .replace(/<\/?div>/g, '')
                .split('</p>')
                .map(function (item) { return item.replace('<p>', '').replace(/\s(.+)/, ''); })
            : [sale.staffName];
        var currentMemoKey = "".concat(accountDate.format(DATE_FORMAT_INCLUDE_TIME)).concat((customer === null || customer === void 0 ? void 0 : customer.고객명) ? customer === null || customer === void 0 ? void 0 : customer.고객명 : '').concat(sale.memo ? sale.memo : '');
        var memo = latestMemoKey !== currentMemoKey ? sale.memo : '';
        latestMemoKey = currentMemoKey;
        var commons = {
            // 팅커뷰 폼에 반영안됌(임시)
            id: createUUID(),
            // 팅커뷰 폼에 반영안됌(임시)
            custCode: sale.custCode,
            판매일시: accountDate.format(DATE_FORMAT_INCLUDE_TIME) || '',
            고객명: (customer === null || customer === void 0 ? void 0 : customer.고객명) || '',
            휴대폰: (customer === null || customer === void 0 ? void 0 : customer.휴대폰) || '',
            미등록고객구분: !customer ? 'o' : '',
            메모: memo || '',
            담당자: (managers && managers[0]) || '',
            담당자2: (managers && managers[1]) || '',
            단가: 0,
            '%할인': 0,
            '%추가': 0,
            정액할인: 0,
            정액추가: 0,
            // Question2 이거 맞나??? (없애는게 맞거나 총금액에서 수량 빼야할 듯??)
            적용금액: 0,
            개수: 1,
            총금액: 0,
            포인트차감: 0,
            // Question3 티켓 차감이 있으면 총금액은 무조건 0이되나??
            횟수권차감: 0,
            기간권차감: 0,
            선불충전액차감: 0,
            '결제액(받을금액)': 0,
            현금: 0,
            카드: 0,
            계좌이체: 0,
            할인: 0,
            미수: 0,
            기타1: 0,
            기타2: 0,
            포인트적립: 0
        };
        if (sale.content === '미수금 정산') {
            unpaidSales.push(__assign(__assign({}, commons), getSaleDataFromSale(sale, true)));
            return;
        }
        /**
         * 환불
         */
        if (sale.accountKind.includes('환불')) {
            sales.push(__assign(__assign({}, commons), { 판매구분: '상품', 구분1차: '환불', 구분2차: sale.accountKind || '', 개수: 1, 메모: "".concat(sale.priceTotal, " \uD658\uBD88(").concat(sale.content, ")").concat(memo ? " / ".concat(memo) : '') }));
            return;
        }
        // <div><p>김지민 (50,129)</p><p>이혜람 (19,871)</p></div>
        // <div class="serviceListStaff"><p>젤네일</p><p class="px11">이혜람(19,871), 김지민(129)</p></div><div class="serviceListStaff"><p>기본페디</p><p class="px11">김지민(50,000)</p></div>
        if (['서비스', '관리', '시술', '케어'].includes(sale.accountKind)) {
            var services = sale.content.startsWith('<div class="serviceListStaff">')
                ? sale.content
                    .split('<div class="serviceListStaff">')
                    .map(function (item) { return item.replace(/<p class="px11">.+<\/p>/g, ''); })
                    .filter(Boolean)
                    .map(function (item) {
                    return item
                        .replace(/<div>/, '')
                        .replace(/<p>/, '')
                        .replace(/<\/p>/, '')
                        .replace(/<\/div>/, '');
                })
                : sale.content.split(/,\s/);
            sales.push.apply(sales, services.map(function (serviceName, index) {
                var serviceNameWithGroup = serviceAndGroup.find(function (item) { return item.serviceName === serviceName; });
                if (index === 0) {
                    var saleData = getSaleDataFromSale(sale);
                    return __assign(__assign(__assign({}, commons), { id: createUUID(), 판매구분: '상품', 구분1차: (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.group) || '데이터이전', 구분2차: (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.serviceName) || serviceName || '' }), saleData);
                }
                else {
                    return __assign(__assign({}, commons), { 
                        // 팅커뷰 폼에 반영안됌(임시) - 다중 시술은 이게 달라져야함
                        id: createUUID(), 판매구분: '상품', 구분1차: (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.group) || '데이터이전', 구분2차: (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.serviceName) || serviceName || '' });
                }
            }));
        }
        else if ('제품판매' === sale.accountKind) {
            var saleData = getSaleDataFromSale(sale);
            var amount = (((_a = sale.content) === null || _a === void 0 ? void 0 : _a.match(/\d+개/)) || [])[0];
            sales.push(__assign(__assign(__assign({}, commons), { 판매구분: '상품', 구분1차: '제품', 구분2차: ((_b = sale.content) === null || _b === void 0 ? void 0 : _b.replace(/\d+개/, '')) || '', 개수: amount ? getNumberOrZero(amount.replace('개', '')) || 1 : 1 }), saleData));
        }
        else if (['금액이용권', '금액쿠폰', '금액회원권', '회차이용권', '회차쿠폰', '회차회원권'].includes(sale.accountKind)) {
            if (['금액이용권', '금액쿠폰', '금액회원권'].includes(sale.accountKind)) {
                var saleData = getSaleDataFromSale(sale);
                sales.push(__assign(__assign(__assign({}, commons), { 판매구분: '금액권', 구분1차: '', 구분2차: ((_c = sale.content) === null || _c === void 0 ? void 0 : _c.replace(/\s판매/g, '')) || '' }), saleData));
            }
            else if (['회차이용권', '회차쿠폰', '회차회원권'].includes(sale.accountKind)) {
                var serviceName_1 = (_d = sale.content) === null || _d === void 0 ? void 0 : _d.replace(/\s\d+회권/g, '');
                var serviceNameWithGroup = serviceAndGroup.find(function (item) { return item.serviceName === serviceName_1; });
                var saleData = getSaleDataFromSale(sale);
                sales.push(__assign(__assign(__assign({}, commons), { 판매구분: '횟수권', 구분1차: (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.group) || '데이터이전', 구분2차: (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.serviceName) || serviceName_1 || '' }), saleData));
            }
        }
    });
    var tickets = [];
    beautyData.tickets.map(function (ticket) {
        var customer = customers.find(function (item) { return item.custCode === ticket.custCode; });
        var type = ticket.cash ? '금액권' : ticket.totalCount ? '횟수권' : null;
        if (!type) {
            return;
        }
        var serviceName = ticket.serviceName;
        var serviceNameWithGroup = serviceName && serviceAndGroup.find(function (item) { return (item.serviceName = serviceName); });
        tickets.push({
            정기권종류: type,
            고객명: (customer === null || customer === void 0 ? void 0 : customer.고객명) || '',
            휴대폰: (customer === null || customer === void 0 ? void 0 : customer.휴대폰) || '',
            구분1차: type === '금액권' ? '' : (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.group) || '데이터이전' || '',
            구분2차: type === '금액권'
                ? KRWFomatToString({ value: getNumberOrZero(ticket.cash) })
                : (serviceNameWithGroup === null || serviceNameWithGroup === void 0 ? void 0 : serviceNameWithGroup.serviceName) || serviceName || '',
            결제금액: type === '금액권' ? getNumberOrZero(ticket.cash) : 0,
            // 제공: 0,
            // 제공혜택: '없음',
            // 혜택: 0,
            // 유효기간: ticket.isExpried === 'Y' ? dayjs(ticket.expiryDate).diff(ticket.regDate, 'month') || null : null,
            만료일: ticket.expiryDate || '',
            총제공: type === '금액권' ? getNumberOrZero(ticket.cash) : getNumberOrZero(ticket.totalCount),
            잔여: type === '금액권' ? getNumberOrZero(ticket.marginCash) : getNumberOrZero(ticket.marginCount)
        });
    });
    // 2. 미수입금 해결
    var alternatives = [];
    var finalUnpaidList = [];
    var groupedByCustomerCode = groupBy(unpaidSales.reverse(), function (item) { return item.custCode; });
    Object.keys(groupedByCustomerCode).map(function (custCode) {
        var calculateUnpaids = groupedByCustomerCode[custCode];
        var shouldPaid = sales
            .filter(function (payment) { return payment.custCode === custCode && payment.미수; })
            .map(function (item) { return (__assign({}, item)); })
            .reverse();
        var keys = ['현금', '카드', '계좌이체', '기타', '할인'];
        calculateUnpaids.map(function (calculateUnpaid) {
            // unpaid
            var remainUnpaid = -Number(calculateUnpaid.미수);
            if (remainUnpaid !== Number(calculateUnpaid.총금액)) {
                throw new Error("".concat(calculateUnpaid.판매일시, ", ").concat(calculateUnpaid.custCode, " \uBBF8\uC218\uC785\uAE08 \uCC98\uB9AC \uC5D0\uB7EC"));
            }
            shouldPaid = shouldPaid.map(function (item) {
                var unpaidInShould = Number(item.미수);
                if (unpaidInShould > 0) {
                    var newUnpaidInShould = 0;
                    if (remainUnpaid <= unpaidInShould) {
                        newUnpaidInShould = unpaidInShould - remainUnpaid;
                        keys.map(function (methodKey) {
                            var methodKeyInPaid = methodKey === '기타' ? '기타1' : methodKey;
                            var methodAmountInUnpaid = Number(calculateUnpaid[methodKey]);
                            item[methodKeyInPaid] = Number(item[methodKeyInPaid]) + methodAmountInUnpaid;
                            calculateUnpaid[methodKey] = 0;
                        });
                        remainUnpaid = 0;
                        return __assign(__assign({}, item), { 미수: newUnpaidInShould });
                    }
                    else {
                        newUnpaidInShould = unpaidInShould;
                        var left_2 = unpaidInShould;
                        keys.map(function (methodKey) {
                            var methodKeyInPaid = methodKey === '기타' ? '기타1' : methodKey;
                            var methodAmountInUnpaid = Number(calculateUnpaid[methodKey]);
                            var chargedValue = methodAmountInUnpaid > left_2 ? left_2 : methodAmountInUnpaid;
                            item[methodKeyInPaid] = Number(item[methodKeyInPaid]) + chargedValue;
                            calculateUnpaid[methodKey] = methodAmountInUnpaid - chargedValue;
                            left_2 = left_2 - chargedValue;
                        });
                        remainUnpaid = remainUnpaid - unpaidInShould;
                        // 남은 금액이 더 클 경우
                        return __assign(__assign({}, item), { 미수: 0 });
                    }
                }
                else {
                    return item;
                }
            });
            if (remainUnpaid > 0) {
                finalUnpaidList.push(__assign(__assign({}, calculateUnpaid), { 미수: remainUnpaid }));
            }
            return __assign(__assign({}, calculateUnpaid), { 미수: remainUnpaid });
        });
        alternatives.push.apply(alternatives, shouldPaid);
        // customerPayments.map()
    });
    sales = sales.map(function (item) {
        return alternatives.find(function (alt) { return alt.id === item.id; }) || item;
    });
    var unpaidListSheet = XLSX.utils.json_to_sheet(finalUnpaidList);
    var salesSheet = XLSX.utils.json_to_sheet(sales.filter(Boolean).map(function (item) {
        delete item.custCode;
        return __assign(__assign({}, item), { '검증(결제액받을금액)': item['총금액'] - item['포인트차감'] - item['횟수권차감'] - item['기간권차감'] - item['결제액(받을금액)'], '검증(받은금액)': item['총금액'] -
                item['포인트차감'] -
                item['횟수권차감'] -
                item['기간권차감'] -
                item['선불충전액차감'] -
                item['현금'] -
                item['카드'] -
                item['계좌이체'] -
                item['할인'] -
                item['미수'] -
                item['기타1'] -
                item['기타2'] });
    }), {
        header: [
            '판매일시',
            '고객명',
            '휴대폰',
            '판매구분',
            '구분1차',
            '구분2차',
            '담당자',
            '담당자2',
            '단가',
            '%할인',
            '%추가',
            '정액할인',
            '정액추가',
            '적용금액',
            '개수',
            '총금액',
            '포인트차감',
            '횟수권차감',
            '기간권차감',
            '선불충전액차감',
            '결제액(받을금액)',
            '현금',
            '카드',
            '계좌이체',
            '할인',
            '미수',
            '기타1',
            '기타2',
            '포인트적립',
            '미등록고객구분',
            '메모'
        ]
    });
    var customerSheet = XLSX.utils.json_to_sheet(customers.filter(Boolean));
    var ticketSheet = XLSX.utils.json_to_sheet(tickets.filter(Boolean));
    var book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, customerSheet, '고객데이터');
    XLSX.utils.book_append_sheet(book, salesSheet, '매출데이터');
    XLSX.utils.book_append_sheet(book, ticketSheet, '횟수권데이터');
    XLSX.utils.book_append_sheet(book, unpaidListSheet, '처리못한미수');
    XLSX.writeFile(book, "converted_beauty_to_thinkofyou_".concat(dayjs().format(DATE_FORMAT_INCLUDE_TIME), ".xlsx"));
    console.log('끝!!');
    return { customers: customers, sales: sales, tickets: tickets };
};
